import menuData from "../datas/menuData.json";
import { Link as RouterLink } from "react-router-dom";
import DrawerMenuBody from "./DrawerMenuBody";
import Logo from "../static/logo-polyverse.png";
import { Box, Text, Flex, Image, Link, useMediaQuery } from "@chakra-ui/react";

function Footer(props) {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  return (
    <Box>
      <Flex
        w="100%"
        p={5}
        borderTopColor={"#333333"}
        borderTopWidth="1px"
        minH={"225px"}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Flex w="78%" justifyContent="space-between">
          {!isSmallerThan480 ? (
            <>
              <Box w="25%" d="flex" flexDirection="column" alignItems="center">
                <Image src={Logo} w={160} />
              </Box>
              {Object.keys(menuData).map((category, index) => (
                <Box
                  w="25%"
                  d="flex"
                  flexDirection="column"
                  alignItems="center"
                  key={index}
                >
                  <Text fontWeight="extrabold" fontSize="md">
                    {category}
                  </Text>
                  {menuData[category].map((item, i) => (
                    <Box key={i} mt={2}>
                      <Link
                        as={RouterLink}
                        to={item.path}
                        fontSize="sm"
                        sx={{
                          color: "#888888",
                          "&:hover": {
                            color: "white",
                            textDecoration: "none",
                          },
                        }}
                      >
                        {item.name}
                      </Link>
                    </Box>
                  ))}
                </Box>
              ))}
            </>
          ) : (
            <>
              <Box
                w="100%"
                d="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Image src={Logo} w={160} mb={5} ml={10} />
                <DrawerMenuBody />
              </Box>
            </>
          )}
        </Flex>

        <Flex w="78%" justifyContent="space-between" py={75}>
          <Box w="25%" d="flex" flexDirection="column" alignItems="center">
            <Text fontSize="sm">© 2024 PolyVerse</Text>
          </Box>
          <Box
            w="75%"
            d="flex"
            flexDirection="column"
            alignItems="center"
          ></Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Footer;
