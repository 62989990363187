import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ProjectInfoModal from "./ProjectInfoModal";
import ProjectConfigFileModal from "./ProjectConfigFileModal";
import ProjectMoveToTrashModal from "./ProjectMoveToTrashModal";

import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { MdOutlineMoreVert } from "react-icons/md";

function ProjectControlMenu({ project }) {
  const [isProjectInfoOpen, setProjectInfoOpen] = useState(false);
  const [isProjectConfigFileOpen, setProjectConfigFileOpen] = useState(false);
  const [isProjectMoveToTrashOpen, setProjectMoveToTrashOpen] = useState(false);

  const handleProjectInfoClick = () => {
    setProjectInfoOpen(true);
  };

  const closeProjectInfoModal = () => {
    setProjectInfoOpen(false);
  };

  const handleProjectConfigFileClick = () => {
    setProjectConfigFileOpen(true);
  };

  const closeProjectConfigFileModal = () => {
    setProjectConfigFileOpen(false);
  };

  const handleProjectMoveToTrashClick = () => {
    setProjectMoveToTrashOpen(true);
  };

  const closeProjectMoveToTrashModal = () => {
    setProjectMoveToTrashOpen(false);
  };

  return (
    <>
      <Box>
        <Menu variant={"pvActionMenu"}>
          <MenuButton
            as={IconButton}
            icon={<MdOutlineMoreVert />}
            variant="outline"
          />
          <MenuList fontSize={"sm"}>
            <MenuItem
              as={RouterLink}
              to={`/dashboard/explore/${project?.id}`}
              mt={0}
            >
              Deploy Contract
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to={`/dashboard/project/edit/${project?.id}`}
            >
              Edit Project
            </MenuItem>
            <MenuItem onClick={handleProjectInfoClick}>Project Info</MenuItem>
            <MenuItem onClick={handleProjectConfigFileClick}>
              Config File
            </MenuItem>
            <MenuItem onClick={handleProjectMoveToTrashClick}>Move To Trash</MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <ProjectInfoModal
        isOpen={isProjectInfoOpen}
        onClose={closeProjectInfoModal}
        project={project}
      />
      <ProjectConfigFileModal
        isOpen={isProjectConfigFileOpen}
        onClose={closeProjectConfigFileModal}
        project={project}
      />
      <ProjectMoveToTrashModal
        isOpen={isProjectMoveToTrashOpen}
        onClose={closeProjectMoveToTrashModal}
        project={project}
      />
    </>
  );
}

export default ProjectControlMenu;
