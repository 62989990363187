import { Text, Box, Flex } from "@chakra-ui/react";

function CardSlogan(props) {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" mt={10}>
        <Flex
          w="65%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <Text fontSize="sm" fontWeight="bold" textTransform="uppercase">
            {props.title}
          </Text>
          <Text
            fontSize="4xl"
            fontWeight="bold"
            fontStyle="normal"
            bgGradient="linear(to-b, white, gray.300)"
            bgClip="text"
          >
            {props.text}
          </Text>

          <Text
            fontSize="md"
            fontStyle="normal"
            color={"#888"}
            dangerouslySetInnerHTML={props.description}
          ></Text>
        </Flex>
      </Box>
    </>
  );
}

export default CardSlogan;
