
import menuData from "../datas/menuData.json";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Text,
  Flex,
  Image,
  VStack,
  LinkBox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

function DrawerMenuBody(props) {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Accordion w={"100%"} allowMultiple>
      {Object.keys(menuData).map((category, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {category}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack align="start">
              {menuData[category].map((item, i) => (
                <LinkBox
                  as={RouterLink}
                  to={item.path}
                  maxW="sm"
                  _hover={{ backgroundColor: "#000" }}
                  p="3"
                  rounded="md"
                  key={i}
                  onClick={handleLinkClick}
                >
                  <Flex align="center">
                    {item.icon && (
                      <Image
                        src={item.icon}
                        boxSize={6}
                        alt={`${item.name} Icon`}
                        filter="invert(100%)"
                      />
                    )}
                    <Text fontWeight="extrabold" ml={2}>
                      {item.name}
                    </Text>
                  </Flex>
                  {props.showText && (
                    <Text fontSize={"sm"} mt={2}>
                      {item.text}
                    </Text>
                  )}
                </LinkBox>
              ))}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default DrawerMenuBody;
