"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
const CryptoJS = __importStar(require("crypto-js"));
class Utils {
    // Convert number to string with decimal
    static fromWei(number, unit = 'ether') {
        const units = {
            noether: BigInt('0'),
            wei: BigInt('1'),
            kwei: BigInt('1000'),
            Kwei: BigInt('1000'),
            babbage: BigInt('1000'),
            femtoether: BigInt('1000'),
            mwei: BigInt('1000000'),
            Mwei: BigInt('1000000'),
            lovelace: BigInt('1000000'),
            picoether: BigInt('1000000'),
            gwei: BigInt('1000000000'),
            Gwei: BigInt('1000000000'),
            shannon: BigInt('1000000000'),
            nanoether: BigInt('1000000000'),
            nano: BigInt('1000000000'),
            szabo: BigInt('1000000000000'),
            microether: BigInt('1000000000000'),
            micro: BigInt('1000000000000'),
            finney: BigInt('1000000000000000'),
            milliether: BigInt('1000000000000000'),
            milli: BigInt('1000000000000000'),
            ether: BigInt('1000000000000000000'),
            kether: BigInt('1000000000000000000000'),
            grand: BigInt('1000000000000000000000'),
            mether: BigInt('1000000000000000000000000'),
            gether: BigInt('1000000000000000000000000000'),
            tether: BigInt('1000000000000000000000000000000'),
        };
        const value = BigInt(number);
        const divisor = units[unit.toLowerCase()];
        const quotient = value / divisor;
        const remainder = value % divisor;
        const formattedQuotient = quotient.toString();
        let formattedRemainder = remainder.toString().padStart(divisor.toString().length - 1, '0');
        // Remove trailing zeros
        while (formattedRemainder.endsWith('0')) {
            formattedRemainder = formattedRemainder.slice(0, -1);
        }
        if (formattedRemainder === '') {
            return formattedQuotient;
        }
        return `${formattedQuotient}.${formattedRemainder}`;
    }
    // Convert number to string with decimal
    static fromWeiWithDecimal(number, decimal) {
        const value = BigInt(number).toString();
        const quotient = value.slice(0, -decimal) || '0';
        const remainder = value.slice(-decimal).padStart(decimal, '0');
        if (remainder === '0'.repeat(decimal)) {
            return quotient;
        }
        const formattedRemainder = remainder.replace(/0+$/, '');
        return formattedRemainder.length > 0 ? `${quotient}.${formattedRemainder}` : quotient;
    }
    // Convert number to string without decimal
    static toWei(number, unit = 'ether') {
        const units = {
            noether: '0',
            wei: '1',
            kwei: '1000',
            Kwei: '1000',
            babbage: '1000',
            femtoether: '1000',
            mwei: '1000000',
            Mwei: '1000000',
            lovelace: '1000000',
            picoether: '1000000',
            gwei: '1000000000',
            Gwei: '1000000000',
            shannon: '1000000000',
            nanoether: '1000000000',
            nano: '1000000000',
            szabo: '1000000000000',
            microether: '1000000000000',
            micro: '1000000000000',
            finney: '1000000000000000',
            milliether: '1000000000000000',
            milli: '1000000000000000',
            ether: '1000000000000000000',
            kether: '1000000000000000000000',
            grand: '1000000000000000000000',
            mether: '1000000000000000000000000',
            gether: '1000000000000000000000000000',
            tether: '1000000000000000000000000000000',
        };
        const value = Number(number) * Number(units[unit.toLowerCase()]);
        return BigInt(value).toString();
    }
    // Convert number to string without decimal
    static toWeiWithDecimal(number, decimal) {
        // String olarak gelen sayıyı ikiye ayırıyoruz: Tam sayı ve ondalık kısım
        const [wholePart, fractionalPart] = number.toString().split(".");
        // Tam kısmı alıyoruz ve 10^decimal ile çarpıyoruz
        let weiValue = BigInt(wholePart) * BigInt(10 ** decimal);
        // Eğer ondalık kısmı varsa
        if (fractionalPart) {
            // Ondalık kısmı uygun bir faktör ile çarpıp tam sayıya çeviriyoruz
            const fractionalWei = BigInt(fractionalPart) * BigInt(10 ** (decimal - fractionalPart.length));
            // Tam kısım ile ondalık kısmı topluyoruz
            weiValue += fractionalWei;
        }
        return weiValue.toString();
    }
    // Pad key characters
    static padKeyCharacters(key, targetLength) {
        if (key.length > targetLength) {
            throw new Error(`Key length exceeds ${targetLength} characters.`);
        }
        while (key.length < targetLength) {
            key += '_';
        }
        return key;
    }
    // Encrypt using AES-256 encryption
    static encryptUsingAES256(text, key, iv) {
        const keyParsed = CryptoJS.enc.Utf8.parse(key);
        const ivParsed = CryptoJS.enc.Utf8.parse(iv);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), keyParsed, {
            keySize: 128 / 8,
            iv: ivParsed,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }
    // Decrypt using AES-256 encryption
    static decryptUsingAES256(cipherText, key, iv) {
        const keyParsed = CryptoJS.enc.Utf8.parse(key);
        const ivParsed = CryptoJS.enc.Utf8.parse(iv);
        const encrypted = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(cipherText)
        });
        const decrypted = CryptoJS.AES.decrypt(encrypted, keyParsed, {
            keySize: 128 / 8,
            iv: ivParsed,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
    // Generate UUID v4
    static generateUuid32() {
        const randomBytes = CryptoJS.lib.WordArray.random(16);
        return randomBytes.toString(CryptoJS.enc.Hex);
    }
}
exports.Utils = Utils;
