import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";
import FilesList from "./components/FilesList";
import ProjectHeader from "./components/ProjectHeader";

import { Project } from "polyverse-sdk/dist/api/project";

import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
  useToast,
  Spinner,
} from "@chakra-ui/react";

function Storage() {
  const toast = useToast();

  const fileUpload = async (files) => {
    try {
      await Project.storage.file.upload(parseInt(projectId), files[0]);

      fetchProjectFiles();

      toast({
        title: "Success",
        description: "File uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  const { projectId } = useParams();

  const [project, setProject] = useState(null);
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [textDD, setTextDD] = useState(
    "Drag & drop files here or click to browse"
  );

  const fetchProjectFiles = async () => {
    try {
      setTextDD("Uploading...");
      setFiles(null);

      const response = await Project.storage.files(parseInt(projectId));
      setFiles(response);

      setTextDD("Drag & drop files here or click to browse");
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);

      try {
        const response = await Project.project(parseInt(projectId));
        setProject(response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setIsLoading(false);
    };

    fetchProject();
    fetchProjectFiles();
  }, [projectId]);

  return (
    <>
      <Header />

      <Box pt={10} bgColor="#111111">
        <Flex
          w={"100%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bgColor={"#000000"}
        >
          <Box
            borderBottom="1px solid #333333"
            borderTop="1px solid #333333"
            w="100%"
            p={5}
          >
            <ProjectHeader
              project={project}
              projectId={projectId}
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Box>

      <Box p={5} bgColor="#111111">
        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          <Box w={"100%"} alignItems="left">
            <Heading as="h4" size="md" p={3}>
              Storage
            </Heading>
          </Box>
          <Box border="1px solid #333333" p={5} borderRadius="6px" w="100%">
            <Box
              p={5}
              bgColor="#000"
              border={"1px dashed #333333"}
              textAlign="center"
              onDragOver={(event) => {
                event.preventDefault();
                event.stopPropagation();
                event.dataTransfer.dropEffect = "copy";
                event.target.style.border = "1px dashed #fff";
              }}
              onDragLeave={(event) => {
                event.preventDefault();
                event.stopPropagation();
                event.target.style.border = "1px dashed #333333";
              }}
              onDrop={(event) => {
                event.preventDefault();
                event.stopPropagation();
                event.target.style.border = "1px dashed #333333";

                const files = event.dataTransfer.files;
                fileUpload(files);
              }}
              onClick={() => {
                const fileInput = document.createElement("input");
                fileInput.type = "file";
                fileInput.style.display = "none";
                fileInput.addEventListener("change", (event) => {
                  const selectedFile = event.target.files[0];
                  fileUpload([selectedFile]);
                });
                document.body.appendChild(fileInput);
                fileInput.click();
                document.body.removeChild(fileInput);
              }}
            >
              Drag & drop files here or click to browse
            </Box>
          </Box>
        </Flex>
      </Box>

      <Box p={5} bgColor="#111111">
        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          <Box w={"100%"} alignItems="left">
            <Heading as="h4" size="md" p={3}>
              Files
            </Heading>
          </Box>
          <Box border="1px solid #333333" borderRadius="6px" w="100%">
            {files ? (
              <FilesList files={files} project={project} />
            ) : (
              <Flex w="100%" justifyContent="center" alignItems="center" p={12}>
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
      <Footer />
    </>
  );
}

export default Storage;
