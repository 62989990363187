import { Box, useTheme, keyframes } from "@chakra-ui/react";

const useAnimation = () => {
  const theme = useTheme();

  const fadeInOut = keyframes`
    0% { background: ${theme.colors.blue[700]}; }
    50% { background: rgba(167, 36, 105, 0.8); }
    100% { background: ${theme.colors.blue[700]}; }
  `;

  return fadeInOut;
};

const AnimatedBox = (props) => {
  const fadeInOut = useAnimation();

  return (
    <Box
      width={"80%"}
      height={"300px"}
      background="#402479"
      filter="blur(97px)"
      borderRadius="50%"
      position="relative"
      zIndex={-1}
      top={{ base: -300, md: -250, lg: -200 }}
      animation={`${fadeInOut} 10s ease-in-out infinite`}
      {...props}
    />
  );
};

export default AnimatedBox;
