import React, { useState } from "react";

import * as Helpers from "../../../services/Helpers";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const ProjectConfigFileModal = ({ isOpen, onClose, project }) => {
  const [isDownloadingConfig, setIsDownloadingConfig] = useState(false);

  const downloadConfig = () => {
    setIsDownloadingConfig(true);

    fetch(
      Helpers.getBaseApiUrl() + "/Project/ConfigFile?projectId=" + project.id
    )
      .then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "config.json";
          alink.click();
        });
      })
      .finally(() => {
        setIsDownloadingConfig(false);
        onClose();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      variant={"pvBlackGray"}
      motionPreset="none"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Config File</ModalHeader>
        <ModalBody>
          <Text>
            With download you can get config file as json format. It includes
            all of your contracts addreses and their types.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant={"pvGray"}>
            Close
          </Button>
          <Button
            onClick={downloadConfig}
            variant={"pvBlackGray"}
            isLoading={isDownloadingConfig}
          >
            Download
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectConfigFileModal;
