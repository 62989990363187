import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";
import UsersList from "./components/UsersList";
import ProjectHeader from "./components/ProjectHeader";

import { Project } from "polyverse-sdk/dist/api/project";

import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
  useToast,
  Spinner,
} from "@chakra-ui/react";

function Users() {
  const toast = useToast();

  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  const { projectId } = useParams();

  const [project, setProject] = useState(null);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);

      try {
        const response = await Project.project(parseInt(projectId));
        setProject(response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setIsLoading(false);
    };

    const fetchProjectUsers = async () => {
      try {
        const response = await Project.users(parseInt(projectId));
        setUsers(response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchProject();
    fetchProjectUsers();
  }, [projectId]);

  return (
    <>
      <Header />

      <Box pt={10} bgColor="#111111">
        <Flex
          w={"100%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bgColor={"#000000"}
        >
          <Box
            borderBottom="1px solid #333333"
            borderTop="1px solid #333333"
            w="100%"
            p={5}
          >
            <ProjectHeader
              project={project}
              projectId={projectId}
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Box>

      <Box p={5} bgColor="#111111">
        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          <Box w={"100%"} alignItems="left">
            <Heading as="h4" size="md" p={3}>
              Users
            </Heading>
          </Box>
          <Box border="1px solid #333333" borderRadius="6px" w="100%">
            {users && project ? (
              <UsersList users={users} project={project} />
            ) : (
              <Flex w="100%" justifyContent="center" alignItems="center" p={12}>
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
      <Footer />
    </>
  );
}

export default Users;
