import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import {
  Table,
  Tr,
  Box,
  Thead,
  Th,
  Tbody,
  Td,
  IconButton,
} from "@chakra-ui/react";

import { RiWallet3Line } from "react-icons/ri";

const ActiveUsers = ({ project }) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await axios.get(
        `https://api.polyverse.life/Trade/Dashboard/ActiveUsers?projectId=${project.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setUsers(response.data.data);
    };
    fetchUsers();
  }, [project]);

  //make a function for format date
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Aylar 0 bazlı olduğu için +1 ekliyoruz
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const handleUserAssets = (user) => {
    navigate(`/dashboard/project/${project.id}/cex/user-assets?userId=${user.userId}`);
  };

  return (
    <>
      <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
        <Table variant="unstyled" p={0} borderRadius={6}>
          <Thead bg="black" h={14}>
            <Tr>
              <Th
                borderTopLeftRadius={6}
                px={4}
                py={2}
                color="#888"
                fontWeight="bold"
              >
                #ID
              </Th>
              <Th color="#888">Name</Th>
              <Th color="#888">Email</Th>
              <Th color="#888">Phone</Th>
              <Th color="#888">Last Trade</Th>
              <Th color="#888" textAlign={"right"}>
                Volume
              </Th>
              <Th color="#888" textAlign={"right"}>
                Cash
              </Th>
              <Th
                borderTopRightRadius={6}
                px={4}
                py={2}
                color="#888"
                fontWeight="bold"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.userId}>
                <Td>{user.userId}</Td>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>{user.phone}</Td>
                <Td>{formatDate(user.lastTradeDate)}</Td>
                <Td textAlign={"right"}>
                  {parseFloat(user.totalFilledTotalPrice).toFixed(2)}
                </Td>
                <Td textAlign={"right"}>{parseFloat(user.cash).toFixed(2)}</Td>
                <Td>
                  <IconButton
                    icon={<RiWallet3Line />}
                    variant="outline"
                    onClick={() => handleUserAssets(user)}
                  />
                </Td>
              </Tr>
            ))}

            <Tr>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td
                fontWeight={"700"}
                textAlign={"right"}
                borderTop={"solid 1px #fff"}
              >
                {users
                  .reduce(
                    (acc, user) => acc + parseFloat(user.totalFilledTotalPrice),
                    0
                  )
                  .toFixed(2)}
              </Td>
              <Td
                fontWeight={"700"}
                textAlign={"right"}
                borderTop={"solid 1px #fff"}
              >
                {users
                  .reduce((acc, user) => acc + parseFloat(user.cash), 0)
                  .toFixed(2)}
              </Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default ActiveUsers;
