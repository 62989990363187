import { Link as RouterLink, useLocation } from "react-router-dom";
import { Button, ButtonGroup } from "@chakra-ui/react";

const ProjectActionButtons = ({ project }) => {
  const location = useLocation();

  return (
    <ButtonGroup
      size="xs"
      colorScheme="gray"
      variant="outline"
      fontWeight={"normal"}
      flexWrap="wrap"
      isAttached
    >
      <Button
        as={RouterLink}
        to={`/dashboard/project/${project?.id}/contracts`}
        isActive={location.pathname.endsWith("contracts")}
      >
        {project?.contractCount} Contracts
      </Button>
      <Button
        as={RouterLink}
        to={`/dashboard/project/${project?.id}/users`}
        isActive={location.pathname.endsWith("users")}
      >
        {project?.userCount} Users
      </Button>
      <Button
        as={RouterLink}
        to={`/dashboard/project/${project?.id}/storage`}
        isActive={location.pathname.endsWith("storage")}
      >
        {project?.storageCount} Storage
      </Button>
      <Button
        as={RouterLink}
        to={`/dashboard/project/${project?.id}/cex/overview`}
        isActive={location.pathname.endsWith("cex")}
      >
        CEX
      </Button>
    </ButtonGroup>
  );
};

export default ProjectActionButtons;
