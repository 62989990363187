import { Link as RouterLink } from "react-router-dom";
import React from "react";

import ImgDBLeft from "../../static/db-left2.png";
import ImgDBRight from "../../static/db-right.png";
import menuData from "../../datas/menuData.json";
import kfData from "../../datas/keyFeaturesData.json";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import CardSlogan from "../../components/CardSlogan";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  return (
    <>
      <Header />

      <Box mt={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Flex flexDirection="column" alignItems="center">
              <Heading
                as="h2"
                size="3xl"
                noOfLines={1}
                bgGradient="linear(to-b, white, #888888)"
                bgClip="text"
              >
                {menuData["Products"][3].name}
              </Heading>

              <Heading as="h4" size="md" color={"#888888"} my={5}>
                {menuData["Products"][3].text}
              </Heading>

              <Box bg="#753CC5" p={2} borderRadius="full" boxSize={50}>
                <Image src={menuData["Products"][3].icon} />
              </Box>

              <Box mt={8}>
                {/*                 <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/cs"}
                >
                  Learn More
                </Button> */}
                <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/getearlyaccess"}
                >
                  Get Early Access
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mt={10}>
        <Flex
          w="80%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 2}
            spacing={10}
            px={{ base: 4, md: 8 }}
          >
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBLeft} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Shape Your Web3 Journey with Ease - Save Time and Effort with
                Customizable UI Components
              </Text>
            </Box>

            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBRight} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Speed Up Your Web3 Journey - Simplify Common Tasks and
                Fast-track Your Development Cycle with Our UI Components
              </Text>
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>

      <CardSlogan
        title=" ENHANCE YOUR WEB3 INTERFACE DESIGN"
        text="Harness the Power of Customization with our UI Components"
        description={{
          __html: `Our UI components offer an array of customizable options, making them the perfect choice for developers looking to build unique, user-friendly interfaces for their Web3 applications. These components can be tailored to fit your specific needs, providing a unique, interactive experience for users. Beyond their versatility, our UI components allow for easy integration, reducing development time and complexity. They are specifically optimized for the Web3 ecosystem, enhancing user interaction and engagement. Experience a comprehensive, user-centric, and adaptable solution with our UI Components.`,
        }}
      />

      <Box mt={35}>
        <Flex
          w="100%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={5}
          mb={20}
        >
          <Heading
            as="h4"
            size="xl"
            noOfLines={1}
            bgGradient="linear(to-b, #753cc5, #fff)"
            bgClip="text"
          >
            Key Features
          </Heading>

          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 3}
            spacing={10}
            px={{ base: 4, md: 8 }}
            mt={10}
          >
            {kfData.map((item, index) => (
              <React.Fragment key={index}>
                {item.uicomponents.map((text, i) => (
                  <Box
                    key={`${index}-${i}`}
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    borderColor={"#333333"}
                    borderRadius={6}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text key={i} mt={2} fontSize={"lg"} color={"#888888"}>
                      {text}
                    </Text>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </SimpleGrid>
        </Flex>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
