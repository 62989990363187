import React, { useContext } from "react";
import { NetworkBoxContext } from "../../../contexts/NetworkBoxContext";

import { Box, Flex, Text, Tag } from "@chakra-ui/react";

const ButtonNetwork = ({ network }) => {
  const { closePopover } = useContext(NetworkBoxContext);

  const handleClick = () => {
    localStorage.setItem("currentNetwork", JSON.stringify(network));
    closePopover(); // Close the Popover from NetworkBox.js
  };

  return (
    <Box
      textAlign="left"
      w={"100%"}
      display="flex"
      alignItems="center"
      p={2}
      fontSize={"sm"}
      border={"1px solid #242424"}
      borderRadius={6}
      cursor={"pointer"}
      _hover={{ borderColor: "#323235" }}
      onClick={handleClick}
    >
      <Box mr={2}>
        <img
          src={network.icon}
          alt={network.name}
          width={"20px"}
        />
      </Box>
      <Flex align="center" justify="space-between" w="100%">
        <Text>{network.name}</Text>
        <Tag fontSize={"xs"} textAlign="right">
          {network.isTestNet ? "Testnet" : "Mainnet"}
        </Tag>
      </Flex>
    </Box>
  );
};

export default ButtonNetwork;
