import React, { createContext, useState, useMemo, useCallback } from "react";

export const TokenOverviewContext = createContext();

export const TokenOverviewProvider = ({ children }) => {
  const [isRefreshTokenOverview, setIsRefreshTokenOverview] = useState(false);

  const refreshTokenOverview = useCallback(() => {
    setIsRefreshTokenOverview((prevValue) => !prevValue);
  }, []);

  const value = useMemo(
    () => ({ isRefreshTokenOverview, refreshTokenOverview }),
    [isRefreshTokenOverview, refreshTokenOverview]
  );

  return (
    <TokenOverviewContext.Provider value={value}>
      {children}
    </TokenOverviewContext.Provider>
  );
};

export function useTokenOverview() {
  const context = React.useContext(TokenOverviewContext);
  if (context === undefined) {
    throw new Error(
      "useTokenOverview must be used within a TokenOverviewProvider"
    );
  }
  return context;
}
