"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiHelper = void 0;
const Config_1 = require("../../Config");
const storageHelper_1 = require("./storageHelper"); // Doğru yolu kullanın
function getToken() {
    return storageHelper_1.Storage.getItem('token');
}
function isValidJSON(text) {
    try {
        JSON.parse(text);
        return true;
    }
    catch (_a) {
        return false;
    }
}
class ApiHelper {
    static apiPost(url, formData, projectId, projectSecret) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${this.config.baseApiUrl}${url}`, true);
                // Asenkron getToken çağrısını bekleyin
                const token = yield getToken();
                if (token) {
                    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                }
                if (projectId) {
                    xhr.setRequestHeader("projectId", projectId.toString());
                }
                if (projectSecret) {
                    xhr.setRequestHeader("projectSecret", projectSecret);
                }
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            const response = JSON.parse(xhr.responseText);
                            if (response.transaction) {
                                const transactionResponse = response;
                                const contractResponse = {
                                    data: transactionResponse,
                                    error: false,
                                    errorMessage: null,
                                };
                                resolve(contractResponse);
                            }
                            else {
                                resolve(response);
                            }
                        }
                        else {
                            try {
                                const apiError = JSON.parse(xhr.responseText);
                                console.error("API Error:", apiError.errorMessage);
                                reject(new Error(apiError.errorMessage));
                            }
                            catch (e) {
                                console.error("An unknown error occurred:", xhr.responseText);
                                console.log(xhr.responseText);
                                reject(new Error(xhr.responseText));
                            }
                        }
                    }
                };
                xhr.onerror = () => {
                    console.error("An unknown error occurred:", xhr.responseText);
                    reject(new Error(xhr.responseText));
                };
                // Send the request
                xhr.send(formData);
            }));
        });
    }
    static apiGet(url, projectId, projectSecret) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                const xhr = new XMLHttpRequest();
                xhr.open("GET", `${this.config.baseApiUrl}${url}`, true);
                // Asenkron getToken çağrısını bekleyin
                const token = yield getToken();
                if (token) {
                    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                }
                if (projectId && projectSecret) {
                    xhr.setRequestHeader("projectId", projectId.toString());
                    xhr.setRequestHeader("projectSecret", projectSecret);
                }
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            if (xhr.responseText === "Ok") {
                                resolve({ data: "Ok" }); // Bu satırı ekleyin.
                            }
                            else if (isValidJSON(xhr.responseText)) {
                                resolve(JSON.parse(xhr.responseText));
                            }
                            else {
                                //console.error("Received response is not valid JSON:", xhr.responseText);
                                //reject(new Error("Invalid JSON response"));
                                resolve(xhr.response);
                            }
                        }
                        else {
                            try {
                                const apiError = JSON.parse(xhr.responseText);
                                console.error("API Error:", apiError.errorMessage);
                                reject(new Error(apiError.errorMessage));
                            }
                            catch (e) {
                                console.error("An unknown error occurred:", xhr.responseText);
                                reject(new Error(xhr.responseText));
                            }
                        }
                    }
                };
                xhr.onerror = () => {
                    console.error("An unknown error occurred:", xhr.responseText);
                    reject(new Error(xhr.responseText));
                };
                // Send the request
                xhr.send();
            }));
        });
    }
    static apiPostJson(url, jsonPayload, projectId, projectSecret) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${this.config.baseApiUrl}${url}`, true);
                xhr.setRequestHeader("Content-Type", "application/json");
                // Asenkron getToken çağrısını bekleyin
                const token = yield getToken();
                if (token) {
                    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                }
                if (projectId) {
                    xhr.setRequestHeader("projectId", projectId.toString());
                }
                if (projectSecret) {
                    xhr.setRequestHeader("projectSecret", projectSecret);
                }
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            const response = JSON.parse(xhr.responseText);
                            if (response.transaction) {
                                const transactionResponse = response;
                                const contractResponse = {
                                    data: transactionResponse,
                                    error: false,
                                    errorMessage: null,
                                };
                                resolve(contractResponse);
                            }
                            else {
                                resolve(response);
                            }
                        }
                        else {
                            try {
                                const apiError = JSON.parse(xhr.responseText);
                                console.error("API Error:", apiError.errorMessage);
                                reject(new Error(apiError.errorMessage));
                            }
                            catch (e) {
                                console.error("An unknown error occurred:", xhr.responseText);
                                reject(new Error(xhr.responseText));
                            }
                        }
                    }
                };
                xhr.onerror = () => {
                    console.error("An unknown error occurred:", xhr.responseText);
                    reject(new Error(xhr.responseText));
                };
                // Send the JSON payload
                xhr.send(JSON.stringify(jsonPayload));
            }));
        });
    }
}
exports.ApiHelper = ApiHelper;
ApiHelper.config = Config_1.Config.getInstance();
