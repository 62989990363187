import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Table,
  Tr,
  Box,
  Thead,
  Th,
  Tbody,
  Td,
  IconButton,
} from "@chakra-ui/react";

import { RiWallet3Line } from "react-icons/ri";

const Assets = ({ project }) => {
  const navigate = useNavigate();

  const [assets, setAssets] = useState([]);
  const [prices, setPrices] = useState({});
  const [loadingPrices, setLoadingPrices] = useState(false);

  useEffect(() => {
    const fetchUserAssets = async () => {
      try {
        const response = await axios.get(
          `https://api.polyverse.life/Trade/Dashboard/UserAssetsTotal?projectId=${project.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setAssets(response.data.data);
      } catch (error) {
        console.error("Error fetching user assets:", error);
      }
    };
    fetchUserAssets();
  }, [project]);

  useEffect(() => {
    const fetchPrices = async () => {
      setLoadingPrices(true);
      try {
        const response = await axios.get(
          `https://api.binance.com/api/v3/ticker/price`
        );

        const priceData = response.data.reduce((acc, item) => {
          acc[item.symbol] = parseFloat(item.price);
          return acc;
        }, {});

        setPrices(priceData);
      } catch (error) {
        console.error("Error fetching prices:", error);
      } finally {
        setLoadingPrices(false);
      }
    };

    // İlk çağrı
    fetchPrices();

    // 1 dakikada bir fiyatları güncelle
    const intervalId = setInterval(fetchPrices, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const getPrice = (symbol) => {
    return prices[symbol] || 0;
  };

  const updatedAssets = assets
    .map((asset) => {
      const symbol = `${asset.currency}TRY`;
      const price = getPrice(symbol);
      return {
        ...asset,
        price: price,
        total: asset.amount * price,
      };
    })
    .sort((a, b) => b.total - a.total);

  const handleAssetsByUser = (asset) => {
    navigate(
      `/dashboard/project/${project.id}/cex/assets-by-user?asset=${asset}`
    );
  };

  return (
    <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
      <Table variant="unstyled" p={0} borderRadius={6}>
        <Thead bg="black" h={14}>
          <Tr>
            <Th
              borderTopLeftRadius={6}
              px={4}
              py={2}
              color="#888"
              fontWeight="bold"
            >
              Currency
            </Th>
            <Th color="#888">Amount</Th>
            <Th color="#888">Available Amount</Th>
            <Th color="#888">Price</Th>
            <Th color="#888">Total</Th>
            <Th
              borderTopRightRadius={6}
              px={4}
              py={2}
              color="#888"
              fontWeight="bold"
            />
          </Tr>
        </Thead>
        <Tbody>
          {updatedAssets.map((asset, index) => (
            <Tr key={index}>
              <Td>{asset.currency}</Td>
              <Td>{asset.amount}</Td>
              <Td>{asset.availableAmount}</Td>
              <Td>{loadingPrices ? "Loading..." : asset.price || "N/A"}</Td>
              <Td>
                {loadingPrices
                  ? "Loading..."
                  : parseFloat(asset.total).toFixed(2) || "N/A"}
              </Td>
              <Td>
                <IconButton
                  icon={<RiWallet3Line />}
                  variant="outline"
                  onClick={() => handleAssetsByUser(asset.currency)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Assets;
