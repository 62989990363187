import React, { useState, useEffect } from "react";

import { Link as RouterLink, useLocation, useParams } from "react-router-dom";

import Header from "../../layouts/Header";
import Footer from "../../../../layouts/Footer";

import { Project } from "polyverse-sdk/dist/api/project";
import ProjectHeader from "../components/ProjectHeader";

import ActiveUsers from "./ActiveUsers";
import UserAssets from "./UserAssets";
import Assets from "./Assets";
import AssetsByUser from "./AssetsByUser";

import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
  useToast,
  Spinner,
  Button,
  ButtonGroup,
  HStack,
  Text,
} from "@chakra-ui/react";

function Index() {
  const toast = useToast();
  const location = useLocation();

  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  const { projectId } = useParams();
  const { section } = useParams();

  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const renderContractPartial = () => {
    console.log(section);

    switch (section.toLocaleLowerCase()) {
      case "overview":
        return <Text>Overview</Text>;
      case "active-users":
        return <ActiveUsers project={project} />;
      case "user-assets":
        return <UserAssets project={project} />;
      case "assets":
        return <Assets project={project} />;
      case "assets-by-user":
        return <AssetsByUser project={project} />;
      default:
        return <Text>Overview</Text>;
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);

      try {
        const response = await Project.project(parseInt(projectId));
        setProject(response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setIsLoading(false);
    };

    fetchProject();
  }, [projectId]);

  return (
    <>
      <Header />

      <Box pt={10} bgColor="#111111">
        <Flex
          w={"100%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bgColor={"#000000"}
        >
          <Box
            borderBottom="1px solid #333333"
            borderTop="1px solid #333333"
            w="100%"
            p={5}
          >
            <ProjectHeader
              project={project}
              projectId={projectId}
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Box>

      <Box p={5} bgColor="#111111">
        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          <Box
            w={"100%"}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={5}
          >
            <Heading as="h4" size="md" p={3} alignSelf="flex-start">
              {" "}
            </Heading>

            <HStack spacing={2} overflowX="auto">
              <ButtonGroup size="md" isAttached variant="outline">
                <Button
                  as={RouterLink}
                  to={`/dashboard/project/${projectId}/cex/overview`}
                  bg={
                    location.pathname.includes("overview")
                      ? "#242424"
                      : "transparent"
                  }
                >
                  Overview
                </Button>
                <Button
                  as={RouterLink}
                  to={`/dashboard/project/${projectId}/cex/active-users`}
                  bg={
                    location.pathname.includes("active-users")
                      ? "#242424"
                      : "transparent"
                  }
                >
                  Active Users
                </Button>
                <Button
                  as={RouterLink}
                  to={`/dashboard/project/${projectId}/cex/assets`}
                  bg={
                    location.pathname.includes("assets")
                      ? "#242424"
                      : "transparent"
                  }
                >
                  Assets
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Flex>
      </Box>

      <Box px={5} bgColor="#111111">
        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          <Box border="1px solid #333333" borderRadius="6px" w="100%" p={5}>
            {renderContractPartial()}
          </Box>
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default Index;
