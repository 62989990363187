import React from "react";

import { Button, ButtonGroup, HStack, Spinner } from "@chakra-ui/react";

import { Link as RouterLink, useLocation } from "react-router-dom";

function ContractMenu({ project, contract, deployableContract }) {
  const location = useLocation();

  return (
    <HStack spacing={2} overflowX="auto">
      <ButtonGroup size="md" isAttached variant="outline">
        <Button
          as={RouterLink}
          to={`/dashboard/project/${project?.id}/contract/${contract?.contract}/overview`}
          bg={
            location.pathname.includes("overview") ? "#242424" : "transparent"
          }
        >
          Overview
        </Button>

        {contract?.config?.type !== "Imported" && (
          <Button
            as={RouterLink}
            to={`/dashboard/project/${project?.id}/contract/${
              contract?.contract
            }/${contract?.config?.type.toLowerCase()}`}
            bg={
              location.pathname.includes(contract?.config?.type.toLowerCase())
                ? "#242424"
                : "transparent"
            }
          >
            {deployableContract === null ? (
              <Spinner size="sm" color="#753CC5" />
            ) : (
              deployableContract?.name
            )}
          </Button>
        )}
        <Button
          as={RouterLink}
          to={`/dashboard/project/${project?.id}/contract/${contract?.contract}/explorer`}
          bg={
            location.pathname.includes("explorer") ? "#242424" : "transparent"
          }
        >
          Explorer
        </Button>
        <Button
          as={RouterLink}
          to={`/dashboard/project/${project?.id}/contract/${contract?.contract}/events`}
          bg={location.pathname.includes("events") ? "#242424" : "transparent"}
        >
          Events
        </Button>
        <Button
          as={RouterLink}
          to={`/dashboard/project/${project?.id}/contract/${contract?.contract}/analytics`}
          bg={
            location.pathname.includes("analytics") ? "#242424" : "transparent"
          }
        >
          Analytics
        </Button>
        <Button
          as={RouterLink}
          to={`/dashboard/project/${project?.id}/contract/${contract?.contract}/source`}
          bg={location.pathname.includes("source") ? "#242424" : "transparent"}
        >
          Source
        </Button>
      </ButtonGroup>
    </HStack>
  );
}

export default ContractMenu;
