import React, { useEffect, useState } from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import { System } from "polyverse-sdk/dist/api/system";

import { Formik, Form, Field } from "formik";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Flex,
  Heading,
  useToast,
  Link,
} from "@chakra-ui/react";

function GetEarlyAccess() {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);

  const tweetText = `🌐 PolyVerse is an innovative platform for developing Web3 applications.
  By joining our early access program, you'll have the opportunity to shape the future of Web3.
  https://platform.polyverse.life/getearlyaccess`;

  const initialValues = {
    email: "",
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email format";
    }

    return errors;
  };

  const onSubmit = async (values) => {
    try {
      var response = await System.getEarlyAccess(values.email);

      navigate("/getearlyaccess?success=true&appid=" + response);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Header />

      <Box my={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Heading as="h3" size="lg">
              Become a Pioneer in the Web3 Future!
            </Heading>
            {params.get("success") === "true" ? (
              <>
                <Heading as={"h4"} size={"md"} mt={2}>
                  You have been added to the early access program!
                </Heading>
                <Heading as={"h5"} size={"sm"} mt={5}>
                  We will be in touch soon. <br />
                  Your Waiting Number is {params.get("appid")}
                  <br />
                  <br />
                </Heading>
                <Link
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    tweetText
                  )}`}
                >
                  Gain priority in the queue by sharing on X (Twitter)
                </Link>
              </>
            ) : (
              <>
                <Heading as={"h4"} size={"md"} mt={5}>
                  PolyVerse is an innovative platform for developing Web3
                  applications.
                  <br /> By joining our early access program, you'll have the
                  opportunity to shape the future of Web3.
                </Heading>
                <Box mt={10}>
                  <Box maxW="400px" mx="auto">
                    <Formik
                      initialValues={initialValues}
                      validate={validateForm}
                      onSubmit={onSubmit}
                    >
                      {(props) => (
                        <Form>
                          <Field name="email">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.email && form.touched.email
                                }
                              >
                                <FormLabel>
                                  Enter your email address for early access 👇
                                </FormLabel>
                                <Input {...field} id="email" />
                                <FormErrorMessage color={"#753CC5"}>
                                  {form.errors.email}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Button
                            mt={4}
                            variant={"pvWhite"}
                            isLoading={props.isSubmitting}
                            type="submit"
                            w={"100%"}
                          >
                            Get Early Access
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default GetEarlyAccess;
