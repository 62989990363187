import React, { useEffect, useState } from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  useToast,
  Skeleton,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";
import { Utils } from "polyverse-sdk/dist/utils";
import * as Helpers from "../../../../../services/Helpers";
import { PvBox } from "../../../../../custom/components";

function RewarderOverview({ contract, project }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [dataRewarder, setDataRewarder] = useState({
    balance: 0,
    sent: 0,
    waiting: 0,
  });

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const rewardToken = await Contract.execute(
        contract.network,
        contract.contract,
        "REWARD_TOKEN"
      );

      const tokenBalance = await Contract.execute(
        contract.network,
        contract.contract,
        "getTokenBalance"
      );

      const rewards = await Contract.execute(
        contract.network,
        contract.contract,
        "getRewards"
      );

      const rewardTokenDecimals = await Contract.execute(
        contract.network,
        rewardToken.data,
        "decimals"
      );

      let sumSent = 0;
      let sumWaiting = 0;

      for (let index = 0; index < rewards.data.length; index++) {
        if (rewards.data[index][0]) {
          if (rewards.data[index][11].toString().indexOf("e+") === -1) {
            sumSent += parseInt(rewards.data[index][11]);
          }
        } else {
          if (rewards.data[index][11].toString().indexOf("e+") === -1) {
            sumWaiting += parseInt(rewards.data[index][11]);
          }
        }
      }

      sumSent = Utils.fromWeiWithDecimal(sumSent, rewardTokenDecimals.data);
      sumWaiting = Utils.fromWeiWithDecimal(
        sumWaiting,
        rewardTokenDecimals.data
      );

      setDataRewarder({
        balance: parseFloat(
          Utils.fromWeiWithDecimal(
            tokenBalance.data.toLocaleString("fullwide", {
              useGrouping: false,
            }),
            rewardTokenDecimals.data
          )
        ).toFixed(2),
        sent: parseFloat(sumSent).toFixed(2),
        waiting: parseFloat(sumWaiting).toFixed(2),
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [contract.contract]);

  return (
    <>
      <PvBox>
        <StatGroup>
          <Stat>
            <StatLabel>Balance</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                dataRewarder.balance
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Sent</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                dataRewarder.sent
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Waiting</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                dataRewarder.waiting
              )}
            </StatNumber>
          </Stat>
        </StatGroup>
      </PvBox>
    </>
  );
}

export default RewarderOverview;
