import React, { useState, useEffect } from "react";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import prism from "react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus";
import SettingForm from "../components/SettingForm";

import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";

import * as Helpers from "../../../../../services/Helpers";
import { Contract } from "polyverse-sdk/dist/api/contract";

import { FaRegCopy, FaCopy } from "react-icons/fa";

import {
  SimpleGrid,
  useMediaQuery,
  Flex,
  Box,
  Text,
  Heading,
  IconButton,
  AspectRatio,
  useClipboard,
} from "@chakra-ui/react";

import {
  PvBoxSub,
  PvBoxSubTransparent,
  PvBoxLeftMenu,
} from "../../../../../custom/components";

function General({ contractItem, projectItem, deployableContract }) {
  const navigate = useNavigate();

  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const { projectId } = useParams();
  const { contract } = useParams();
  const { menu } = useParams();
  let { section } = useParams();

  const [iframeSrc, setIframeSrc] = useState("");
  const { hasCopied, onCopy } = useClipboard(iframeSrc);

  if (section === undefined) {
    navigate(`${window.location.pathname}/settings`);
  }

  useEffect(() => {
    const updateIframeSrc = async () => {
      console.log("iframe:", deployableContract?.iFrame);

      const network = Helpers.getNetwork(contractItem.network);

      let iframeString = `https://nftstaking-frame.polyverse.life/?contract={function.erc721address}&network={network.key}&symbol={network.symbol}&decimals={network.decimals}&chainId={network.chainId}&chainName={network.name}&rpcUrls={network.url}&blockExplorerUrls={network.explorerUrl}&stakingcontract={contract}`;

      iframeString = iframeString.replace("{contract}", contractItem.contract);

      // Replace network. keys
      Object.keys(network).forEach((key) => {
        const regex = new RegExp(`{network.${key}}`, "g");
        iframeString = iframeString.replace(regex, network[key]);
      });

      // Replace function. keys with getFunction
      const functionRegex = /{function.(\w+)}/g;
      let match;
      while ((match = functionRegex.exec(iframeString)) !== null) {
        const functionName = match[1];

        const response = await Contract.execute(
          contractItem.network,
          contractItem.contract,
          functionName
        );

        let value = response.data;

        console.log(value);

        iframeString = iframeString.replace(match[0], value);
      }

      setIframeSrc(iframeString);
    };

    updateIframeSrc();
  }, [contractItem, deployableContract]);

  return (
    <>
      <SimpleGrid
        columns={2}
        spacing={0}
        w="100%"
        mt={5}
        templateColumns={isIphone ? "100%" : "20% 80%"}
      >
        {/* MENU */}
        <PvBoxSub w={isIphone ? "100%" : "98%"} px={2}>
          <Flex direction="column">
            {deployableContract && deployableContract.iFrame !== null && (
              <PvBoxLeftMenu
                as={RouterLink}
                to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/settings`}
                isActive={section === "settings"}
              >
                Settings
              </PvBoxLeftMenu>
            )}

            {deployableContract && deployableContract.iFrame !== null && (
              <PvBoxLeftMenu
                as={RouterLink}
                to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/embed`}
                isActive={section === "embed"}
              >
                Embed
              </PvBoxLeftMenu>
            )}
          </Flex>
        </PvBoxSub>

        {/* RIGHT */}
        <PvBoxSubTransparent p={0} pl={5} mt={isIphone ? 4 : 0}>
          <PvBoxSubTransparent p={0} isHidden={section !== "settings"}>
            {deployableContract?.settings.map((setting, index) => (
              <SettingForm
                key={index}
                inputs={setting.inputs}
                contract={contractItem}
                saveMethod={setting.saveMethod}
                caption={setting.caption}
                sectionName={"settings"}
                mb={5}
              />
            ))}
          </PvBoxSubTransparent>

          <PvBoxSub isHidden={section !== "embed"}>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Heading as="h4" size="md" display="inline">
                Embed
              </Heading>
            </Flex>
            <Text fontSize={"lg"} mb={4}>
              Embed Code
            </Text>
            <Box
              border="1px solid #888"
              bgColor="#1e1e1e"
              borderRadius={6}
              display="flex"
              justifyContent="space-between"
            >
              <SyntaxHighlighter language="solidity" style={prism}>
                {iframeSrc}
              </SyntaxHighlighter>
              <IconButton
                icon={hasCopied ? <FaCopy /> : <FaRegCopy />}
                size="sm"
                mr={1}
                mt={1}
                onClick={onCopy}
              />
            </Box>

            <Text fontSize={"lg"} my={4}>
              Preview
            </Text>
            <Box border="1px solid #888" bgColor="#1e1e1e" borderRadius={6}>
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src={iframeSrc}
                  frameBorder="0"
                  allowFullScreen
                  title="Mint Preview"
                ></iframe>
              </AspectRatio>
            </Box>
          </PvBoxSub>
        </PvBoxSubTransparent>
      </SimpleGrid>
    </>
  );
}

export default General;
