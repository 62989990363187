"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.System = void 0;
const apiHelper_1 = require("./utils/apiHelper");
const Config_1 = require("../Config");
class System {
    static statistics() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Statistics`);
            return response.data;
        });
    }
    static networks(key) {
        return __awaiter(this, void 0, void 0, function* () {
            let url = `/${this.CONTROLLER_NAME}/Networks`;
            if (key) {
                url += `?key=${encodeURIComponent(key)}`;
            }
            const response = yield apiHelper_1.ApiHelper.apiGet(url);
            return response.data;
        });
    }
    static deployStatus(jobId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/DeployStatus?jobId=${jobId}`);
            return response.data;
        });
    }
    static reDeploy(jobId) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("jobId", jobId.toString());
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/ReDeploy`, formData);
            return response.data;
        });
    }
    static deployableContracts() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/DeployableContracts`);
            return response.data;
        });
    }
    static deployableContractByType(type) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/DeployableContracts`);
            const contractItem = response.data.find((item) => item.type === type);
            if (contractItem === undefined || contractItem === null)
                throw new Error("Deployable contract not found");
            return contractItem;
        });
    }
    static deployableContract(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/DeployableContracts?id=${id}`);
            if (!response.data || response.data.length === 0) {
                throw new Error("Deployable contract not found");
            }
            return response.data[0];
        });
    }
    static deployContract(network, contractData) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            for (const key in contractData) {
                formData.append(key, contractData[key]);
            }
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/DeployContract`, formData);
            return response.data;
        });
    }
    static importContract(network, projectId, name, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("projectId", projectId.toString());
            formData.append("name", name);
            formData.append("contract", contract);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/ImportContract`, formData);
            return response.data;
        });
    }
    static getBaseApiUrl() {
        return this.config.baseApiUrl;
    }
    static abi(network, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/ABI`);
            return response.data;
        });
    }
    static sourceCode(network, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/SourceCode`);
            return response.data;
        });
    }
    static updateNFTCollections() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/UpdateNFTCollections`);
            return response.data;
        });
    }
    static deploy() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Deploy`);
                return response.data;
            }
            catch (error) {
                console.error("API call failed:", error);
                throw error; // Eğer hatayı üst seviyeye taşımak istiyorsanız bu satırı kullanın.
            }
        });
    }
    static afterDeploy(jobId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/AfterDeploy?=${jobId}`);
            return response.data;
        });
    }
    static time() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Time`);
            return response.data;
        });
    }
    static ping() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Ping`);
            return response.data;
        });
    }
    static price(symbols) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `/${this.CONTROLLER_NAME}/Price?symbols=${encodeURIComponent(symbols)}`;
            const response = yield apiHelper_1.ApiHelper.apiGet(url);
            return response.data;
        });
    }
    static getEarlyAccess(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("email", email);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/GetEarlyAccess`, formData);
            return response.data;
        });
    }
    static email(email, subject, body, lang) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("subject", subject);
            formData.append("body", body);
            if (lang)
                formData.append("lang", lang);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/Email`, formData);
            return response.data;
        });
    }
    static SMS(phone, message) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("phone", phone);
            formData.append("message", message);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/SMS`, formData);
            return response.data;
        });
    }
    static getPrice(symbols) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/GetPrice?symbols=${symbols}`);
            return response.data;
        });
    }
    static fiats() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Fiats`);
            return response.data;
        });
    }
}
exports.System = System;
System.config = Config_1.Config.getInstance();
System.CONTROLLER_NAME = 'System';
