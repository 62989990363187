import React, { createContext, useState, useMemo, useCallback } from "react";

export const ContractsContext = createContext();

export const ContractsProvider = ({ children }) => {
  const [isRefreshContractList, setIsRefreshContractList] = useState(false);

  const refreshContracts = useCallback(() => {
    setIsRefreshContractList((prevValue) => !prevValue);
  }, []);

  const value = useMemo(() => ({ isRefreshContractList, refreshContracts }), [
    isRefreshContractList,
    refreshContracts,
  ]);

  return (
    <ContractsContext.Provider value={value}>
      {children}
    </ContractsContext.Provider>
  );
};

export function useContracts() {
  const context = React.useContext(ContractsContext);
  if (context === undefined) {
    throw new Error(
      "useContracts must be used within a ContractsProvider"
    );
  }
  return context;
}
