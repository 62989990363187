import React, { useEffect, useState } from "react";
import axios from "axios";

import {
    Table,
    Tr,
    Box,
    Thead,
    Th,
    Tbody,
    Td,
  } from "@chakra-ui/react";

const AssetsByUser = ({ project }) => {
  const [userAssets, setUserAssets] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const asset = urlParams.get("asset");

  useEffect(() => {
    const fetchUserAssets = async () => {
      const response = await axios.get(
        `https://api.polyverse.life/Trade/Dashboard/AssetsByUser?projectId=${project.id}&asset=${asset}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setUserAssets(response.data.data);
    };
    fetchUserAssets();
  }, [project]);

  return (
    <>
      <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
        <Table variant="unstyled" p={0} borderRadius={6}>
          <Thead bg="black" h={14}>
            <Tr>
              <Th
                borderTopLeftRadius={6}
                px={4}
                py={2}
                color="#888"
                fontWeight="bold"
              >
                Currency
              </Th>
              <Th color="#888">Name</Th>
              <Th color="#888">Email</Th>
              <Th color="#888">Phone</Th>
              <Th
                borderTopRightRadius={6}
                px={4}
                py={2}
                color="#888"
                fontWeight="bold"
              >
                Amount
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {userAssets.map((asset, index) => (
              <Tr key={index}>
                <Td>{asset.currency}</Td>
                <Td>{asset.name}</Td>
                <Td>{asset.email}</Td>
                <Td>{asset.phone}</Td>
                <Td>{asset.amount}</Td>
              </Tr>
            ))}

            {/* row for totals */}
            <Tr fontWeight={"bold"}>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>Total</Td>
              <Td>{userAssets.reduce((acc, cur) => acc + cur.amount, 0)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default AssetsByUser;
