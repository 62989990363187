import React, { useEffect } from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import { Account } from "polyverse-sdk/dist/api/account";

import { Formik, Form, Field } from "formik";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Flex,
  Heading,
  useToast,
  Link,
} from "@chakra-ui/react";

function Login() {
  const toast = useToast();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }

    return errors;
  };

  const onSubmit = async (values) => {

    try {
      var response = await Account.login(values.email, values.password);

      console.log(response);

      localStorage.setItem("userId", response.id);
      localStorage.setItem("userAddress", response.address);
      
      navigate("/dashboard/home");
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard/projects");
    }
  }, []);

  return (
    <>
      <Header />

      <Box my={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Heading as="h3" size="lg">
              Log in to Dashboard
            </Heading>
            <Box mt={10}>
              <Box maxW="400px" mx="auto">
                <Formik
                  initialValues={initialValues}
                  validate={validateForm}
                  onSubmit={onSubmit}
                >
                  {(props) => (
                    <Form>
                      <Field name="email">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                          >
                            <FormLabel>Email</FormLabel>
                            <Input {...field} id="email" />
                            <FormErrorMessage color={"#753CC5"}>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="password">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                            mt={5}
                          >
                            <FormLabel>Password</FormLabel>
                            <Input {...field} type="password" id="password" />
                            <FormErrorMessage color={"#753CC5"}>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Button
                        mt={4}
                        variant={"pvWhite"}
                        isLoading={props.isSubmitting}
                        type="submit"
                        w={"100%"}
                      >
                        Login
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>

              <Box maxW={"400px"} mx={"auto"} mt={5}>
                <Link
                  mt={4}
                  variant={"pvBlue"}
                  as={RouterLink}
                  to={"/dashboard/signup"}
                >
                  Sign Up
                </Link>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default Login;
