import React, { useEffect, useState } from "react";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import solidity from "react-syntax-highlighter/dist/esm/languages/prism/solidity";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import prism from "react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus";
import beautify from "json-beautify";

import { FaRegCopy, FaCopy } from "react-icons/fa";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Spinner,
  Flex,
  IconButton,
  useClipboard,
} from "@chakra-ui/react";
import { PvBoxSub } from "../../../../custom/components";
import { System } from "polyverse-sdk/dist/api/system";

// Register Solidity language for syntax highlighting
SyntaxHighlighter.registerLanguage("solidity", solidity);
SyntaxHighlighter.registerLanguage("json", json);

function Source({ contract, project }) {
  const { hasCopied, onCopy } = useClipboard("output");

  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState({});

  const getSource = (source, contractName) => {
    if (source === undefined || source === null) {
      return "";
    }

    try {
      let objSource = JSON.parse(source.substring(1, source.length - 1));
      let retCode = "";
      for (const sourceItem in objSource.sources) {
        if (sourceItem.indexOf(contractName) !== -1) {
          retCode = objSource.sources[sourceItem].content;
        }
      }
      return retCode;
    } catch (error) {
      return source;
    }
  };

  const getABI = (abi) => {
    if (abi === undefined || abi === null) {
      return "";
    }
    const retunABI = beautify(JSON.parse(source?.abi), null, 2, 20);

    return retunABI;
  };

  const copyOutputSource = (source) => {
    navigator.clipboard.writeText(source);
    onCopy();
  };

  useEffect(() => {
    async function fetchSource() {
      setIsLoading(true);
      const result = await System.sourceCode(
        contract.network,
        contract.contract
      );
      setSource(result);
      setIsLoading(false);
    }
    fetchSource();
  }, [contract, project]);

  return (
    <PvBoxSub p={0} mt={5}>
      {isLoading && (
        <Flex w="100%" justifyContent="center" alignItems="center" my={10}>
          <Spinner size="xl" color="#753CC5" />
        </Flex>
      )}

      {!isLoading && (
        <Accordion allowMultiple>
          <AccordionItem borderTop={"0px"}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                  {source?.contractName}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box
                border="1px solid #888"
                bgColor="#1e1e1e"
                borderRadius={6}
                display="flex"
                justifyContent="space-between"
              >
                <SyntaxHighlighter language="solidity" style={prism}>
                  {getSource(source?.sourceCode, source?.contractName)}
                </SyntaxHighlighter>
                <IconButton
                  icon={hasCopied ? <FaCopy /> : <FaRegCopy />}
                  size="sm"
                  mr={1}
                  mt={1}
                  onClick={() =>
                    copyOutputSource(
                      getSource(source?.sourceCode, source?.contractName)
                    )
                  }
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                  ABI
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box
                border="1px solid #888"
                bgColor="#1e1e1e"
                borderRadius={6}
                display="flex"
                justifyContent="space-between"
              >
                <SyntaxHighlighter language="json" style={prism}>
                  {getABI(source?.abi)}
                </SyntaxHighlighter>
                <IconButton
                  icon={hasCopied ? <FaCopy /> : <FaRegCopy />}
                  size="sm"
                  mr={1}
                  mt={1}
                  onClick={() => copyOutputSource(getABI(source?.abi))}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </PvBoxSub>
  );
}

export default Source;
