import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Input,
  Tag,
  TagLabel,
  Avatar,
  useToast,
} from "@chakra-ui/react";

import { System } from "polyverse-sdk/dist/api/system";
import * as Helpers from "../../../../services/Helpers";

import { useContracts } from "../../../../contexts/ContractsContext";

function ImportContractModal({ isOpen, onClose, project }) {
  const { isRefreshContractList, refreshContracts } = useContracts();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [contractAddress, setContractAddress] = useState("");

  const [currentNetwork, setCurrentNetwork] = useState(null);

  const handleContractImport = async () => {
    setIsLoading(true);

    const responseSourceCode = await System.sourceCode(
      currentNetwork.key,
      contractAddress
    );

    if (responseSourceCode.contractName !== "") {
      await System.importContract(
        currentNetwork.key,
        project?.id,
        responseSourceCode.contractName,
        contractAddress
      );

      refreshContracts();
      onClose();
    } else {
      toast({
        title: "Error",
        description: "Contract can not imported.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const handleContractAddressChange = (event) => {
    setContractAddress(event.target.value);
  };

  useEffect(() => {
    const network = Helpers.getCurrentNetwork();
    setCurrentNetwork(network);
  }, [project]);

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      isCentered
      variant={"pvBlackGray"}
      motionPreset="none"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Contract</ModalHeader>
        <ModalBody>
          <Text fontSize={"sm"} color={"#888"}>
            Import an already deployed contract into your project by entering a
            contract address below. (If need to change network, close modal and
            change from right top menu)
          </Text>
          <Input
            placeholder="Contract Address"
            mt={4}
            onChange={handleContractAddressChange}
          />

          <Tag size="md" borderRadius="full" mt={4} px={4} py={2} w={"100%"}>
            <Avatar
              src={`data:image/svg+xml;base64,${currentNetwork?.icon}`}
              size="xs"
              name="Segun Adebayo"
              ml={-1}
              mr={2}
            />
            <TagLabel>{currentNetwork?.name}</TagLabel>
          </Tag>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant={"pvBlackGray"}>
            Close
          </Button>
          <Button
            variant={"pvBlackGray"}
            onClick={handleContractImport}
            isDisabled={contractAddress === ""}
            isLoading={isLoading}
          >
            Import
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ImportContractModal;
