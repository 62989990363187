"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Signer = void 0;
const web3_1 = require("web3");
const utils_1 = require("../utils");
const system_1 = require("../api/system");
const providers_1 = require("@ethersproject/providers");
class Signer {
    static estimateGas(networkKey, rawTransaction) {
        return __awaiter(this, void 0, void 0, function* () {
            var network = yield system_1.System.networks(networkKey);
            const provider = new providers_1.JsonRpcProvider(network[0].url);
            // Estimate the gas amount and gas price
            let estimatedGas = yield provider.estimateGas(rawTransaction);
            const gasPrice = yield provider.getGasPrice();
            // If the network is 'matic', increase the estimatedGas by 30%.
            if (networkKey === "matic") {
                estimatedGas = estimatedGas.mul(190).div(100);
            }
            // Return the estimated gas amount and transaction fee as a TransferResponse object
            const transferResponseItem = {
                gas: {
                    estimateGas: Number(estimatedGas),
                    estimateTransactionFee: Number(estimatedGas) * Number(utils_1.Utils.fromWei(gasPrice.toBigInt())),
                },
                transaction: undefined,
            };
            // Return the TransferResponse object as an array
            const transferResponses = [transferResponseItem];
            const apiResponse = {
                data: transferResponses,
                error: false,
                errorMessage: null,
            };
            return apiResponse;
        });
    }
    static signTransaction(wallet, rawTransaction) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            //check wallet or provider is null
            if (wallet.wallet === null) {
                throw new Error("Wallet or provider is null");
            }
            // Estimate the gas amount and gas price
            const feeData = yield wallet.provider.getFeeData();
            // Get the nonce value
            const nonce = yield ((_a = wallet.wallet) === null || _a === void 0 ? void 0 : _a.getNonce()); // Get the nonce
            // Update the transaction object
            if (rawTransaction.nonce !== undefined) {
                rawTransaction.nonce = nonce;
            }
            if (rawTransaction.maxPriorityFeePerGas !== undefined) {
                rawTransaction.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas;
                if (wallet.networkKey === "matic") {
                    rawTransaction.maxPriorityFeePerGas = rawTransaction.maxPriorityFeePerGas * BigInt(130) / BigInt(100);
                }
                if (wallet.networkKey === "bnb") {
                    rawTransaction.maxPriorityFeePerGas = web3_1.Web3.utils.toWei('1', 'gwei');
                }
            }
            if (rawTransaction.maxFeePerGas !== undefined) {
                rawTransaction.maxFeePerGas = feeData.maxFeePerGas;
                if (wallet.networkKey === "matic") {
                    rawTransaction.maxFeePerGas = rawTransaction.maxFeePerGas * BigInt(130) / BigInt(100);
                }
                if (wallet.networkKey === "bnb") {
                    rawTransaction.maxFeePerGas = web3_1.Web3.utils.toWei('2', 'gwei');
                }
            }
            if (rawTransaction.chainId !== undefined) {
                rawTransaction.chainId = (_b = wallet.network) === null || _b === void 0 ? void 0 : _b.chainId;
            }
            if (rawTransaction.gasLimit !== undefined) {
                if (rawTransaction.gasLimit === 0) {
                    let estimatedGas = yield wallet.provider.estimateGas(rawTransaction);
                    if (wallet.networkKey === "matic") {
                        estimatedGas = estimatedGas * BigInt(130) / BigInt(100);
                    }
                    rawTransaction.gasLimit = estimatedGas;
                }
            }
            // Sign and send the transaction
            const signedTx = yield ((_c = wallet.wallet) === null || _c === void 0 ? void 0 : _c.signTransaction(rawTransaction));
            return signedTx;
        });
    }
}
exports.Signer = Signer;
