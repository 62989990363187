import { Link as RouterLink } from "react-router-dom";
import React from "react";

import ImgDBLeft from "../../static/db-left2.png";
import ImgDBRight from "../../static/db-right.png";
import menuData from "../../datas/menuData.json";
import kfData from "../../datas/keyFeaturesData.json";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";
import CardSlogan from "../../components/CardSlogan";

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  return (
    <>
      <Header />

      <Box mt={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Flex flexDirection="column" alignItems="center">
              <Heading
                as="h2"
                size="3xl"
                noOfLines={1}
                bgGradient="linear(to-b, white, #888888)"
                bgClip="text"
              >
                {menuData["Products"][1].name}
              </Heading>

              <Heading as="h4" size="md" color={"#888888"} my={5}>
                {menuData["Products"][1].text}
              </Heading>

              <Box bg="#753CC5" p={2} borderRadius="full" boxSize={50}>
                <Image src={menuData["Products"][1].icon} />
              </Box>

              <Box mt={8}>
                {/*                 <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/cs"}
                >
                  Learn More
                </Button> */}
                <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/getearlyaccess"}
                >
                  Get Early Access
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mt={10}>
        <Flex
          w="80%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 2}
            spacing={10}
            px={{ base: 4, md: 8 }}
          >
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBLeft} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Compatible with All Languages Supporting REST APIs
              </Text>
            </Box>

            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBRight} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Secure communication over HTTPS with JWT authentication
              </Text>
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>

      <CardSlogan
        title="Simplified, Secure, and Fast Web3 Development"
        text="Accelerate Your Web3 Development with Our REST API Service"
        description={{
          __html: `Navigate the landscape of Web3 development with our powerful REST API service. Engineered to empower your Web3 experience, our innovative service provides a streamlined solution for building your Web3 projects faster and more affordably. It's securely over HTTPS with JWT authentication. Experience the ease of development and interaction with decentralized networks, ensuring efficient creation and complete control of your Web3 apps.`,
        }}
      />

      <Box mt={35}>
        <Flex
          w="100%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={5}
          mb={20}
        >
          <Heading
            as="h4"
            size="xl"
            noOfLines={1}
            bgGradient="linear(to-b, #753cc5, #fff)"
            bgClip="text"
          >
            Key Features
          </Heading>

          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 3}
            spacing={10}
            px={{ base: 4, md: 8 }}
            mt={10}
          >
            {kfData.map((item, index) => (
              <React.Fragment key={index}>
                {item.API.map((text, i) => (
                  <Box
                    key={i}
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    borderColor={"#333333"}
                    borderRadius={6}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text mt={2} fontSize={"lg"} color={"#888888"}>
                      {text}
                    </Text>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </SimpleGrid>
        </Flex>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
