import React, { useEffect, useState, useContext } from "react";
import { NetworkBoxContext } from "../../../contexts/NetworkBoxContext";
import { useWalletBalance } from "../../../contexts/WalletBalanceContext";

import { useNavigate } from "react-router-dom";

import { System } from "polyverse-sdk/dist/api/system";
import { Wallet } from "polyverse-sdk/dist/api/wallet";

import * as Helpers from "../../../services/Helpers";

import { FaRegCopy, FaCopy } from "react-icons/fa";
import { GoMoveToEnd } from "react-icons/go";

import ButtonNetwork from "./ButtonNetwork";

import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Img,
  IconButton,
  Tooltip,
  useClipboard,
  Spinner,
  Skeleton,
  VStack,
  Tag,
  Heading,
  useToast,
} from "@chakra-ui/react";

function NetworkBox() {
  const { isPopoverOpen, closePopover, openPopover } =
    useContext(NetworkBoxContext);

  const { isRefreshWalletBalance, refreshWallet } = useWalletBalance();

  const toast = useToast();

  const navigate = useNavigate();

  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const [showPopover, setShowPopover] = useState(false);

  const [networks, setNetworks] = useState([]);
  const [networksTestNet, setNetworksTestNet] = useState([]);
  const [networksMainNet, setNetworksMainNet] = useState([]);
  const [IsNetworksLoading, setIsNetworksLoading] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    const fetchNetworks = async () => {
      setIsNetworksLoading(true);

      try {
        const result = await System.networks();
        setNetworks(result);
        localStorage.setItem("networks", JSON.stringify(result));
        localStorage.setItem("networksExpiry", Date.now() + 6 * 60 * 60 * 1000); // 6 saat
      } catch (error) {
        console.error("Failed to fetch networks:", error);
      }

      setIsNetworksLoading(false);
    };

    const storedNetworks = JSON.parse(localStorage.getItem("networks"));
    const networksExpiry = parseInt(localStorage.getItem("networksExpiry"));

    if (storedNetworks && Date.now() < networksExpiry) {
      setNetworks(storedNetworks);
    } else {
      fetchNetworks();
    }
  }, [networks.length]);

  useEffect(() => {
    if (networks.length > 0) {
      // Set testnet and main networks
      const filteredNetworks = networks.filter((network) => network.isTestNet);
      setNetworksTestNet(filteredNetworks);

      const filteredNetworksMain = networks.filter(
        (network) => !network.isTestNet
      );
      setNetworksMainNet(filteredNetworksMain);

      // Set current network
      const storedCurrentNetwork = localStorage.getItem("currentNetwork");
      if (storedCurrentNetwork) {
        setCurrentNetwork(JSON.parse(storedCurrentNetwork));
      } else if (networks.length > 0) {
        setCurrentNetwork(networks[0]);
        localStorage.setItem("currentNetwork", JSON.stringify(networks[0]));
      }

      setShowPopover(true);
    }
  }, [networks]);

  useEffect(() => {
    try {
      if (value !== "") {
        navigator.clipboard.writeText(value);
      }
    } catch (error) {}
  }, [value]);

  useEffect(() => {
    fetchWalletBalance();
  }, [currentNetwork]);

  useEffect(() => {
    if (!isPopoverOpen) {
      const storedCurrentNetwork = localStorage.getItem("currentNetwork");
      if (storedCurrentNetwork) {
        setCurrentNetwork(JSON.parse(storedCurrentNetwork));
      }
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    fetchWalletBalance();
  }, [isRefreshWalletBalance]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/dashboard");
  };

  const fetchWalletBalance = async () => {
    if (currentNetwork) {
      setIsNetworksLoading(true);

      try {
        const response = await Wallet.balance(
          currentNetwork.key,
          undefined,
          false,
          false,
          false
        );
        setWallet(response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

      setIsNetworksLoading(false);
    }
  };

  const handleCopyAddress = () => {
    onCopy();
    setValue(wallet.address);
  };

  return (
    <>
      {showPopover && (
        <Popover isOpen={isPopoverOpen} onClose={closePopover}>
          <PopoverTrigger>
            <Box
              display="flex"
              alignItems="center"
              w={235}
              border="1px solid #242424"
              pt={1}
              px={3}
              borderRadius={6}
              bgColor="#0a0a0a"
              cursor="pointer"
              _hover={{ borderColor: "#323235" }}
              onClick={openPopover}
              minH={41}
            >
              {!IsNetworksLoading && currentNetwork !== null ? (
                <>
                  <Tooltip label={currentNetwork.name}>
                    <Img src={currentNetwork.icon} boxSize="32px" />
                  </Tooltip>
                </>
              ) : (
                <Spinner color="#753CC5" />
              )}

              <Box ml={4}>
                <Box fontSize="sm">
                  <Skeleton isLoaded={!IsNetworksLoading}>
                    {wallet && wallet.balance.balance.toFixed(4)}
                  </Skeleton>
                </Box>
                <Box fontSize="xs" color="#706f78">
                  <Skeleton isLoaded={!IsNetworksLoading} mt={1}>
                    {wallet && Helpers.shortenAddress(wallet.address)}
                  </Skeleton>
                </Box>
              </Box>

              <Box ml={4}>
                <Skeleton isLoaded={!IsNetworksLoading}>
                  <Tag>{currentNetwork.isTestNet ? "Testnet" : "Mainnet"}</Tag>
                </Skeleton>
              </Box>
            </Box>
          </PopoverTrigger>
          <PopoverContent bgColor="#000" mr={3}>
            <PopoverHeader>
              <Heading as="h6" size="xs" my={2} ml={2} color={"#888"}>
                Current Network
              </Heading>
              <Box
                display="flex"
                alignItems="center"
                w="100%"
                border="1px solid #242424"
                pt={1}
                px={3}
                borderRadius={6}
                bgColor="#0a0a0a"
                _hover={{ borderColor: "#323235" }}
              >
                <Box>
                  <Img src={currentNetwork.icon} boxSize="32px" />
                </Box>
                <Box ml={4}>
                  <Box display="flex" alignItems="center" fontSize="sm">
                    <Box>
                      {wallet && Helpers.shortenAddress(wallet.address)}
                    </Box>
                    <Tooltip label={!hasCopied ? "Copy Address" : "Copied!"}>
                      <Box
                        as="span"
                        cursor="pointer"
                        onClick={handleCopyAddress}
                        _hover={{ color: "#706f78" }}
                        ml={2}
                      >
                        {!hasCopied ? <FaRegCopy /> : <FaCopy />}
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box fontSize="xs" color="#706f78">
                    {wallet &&
                      wallet.balance.balance.toFixed(12) +
                        " " +
                        wallet.balance.symbol}
                  </Box>
                </Box>
                <Box ml="auto">
                  <Tag fontSize={"xs"} ml={1}>
                    {currentNetwork.isTestNet ? "Testnet" : "Mainnet"}
                  </Tag>
                </Box>

                <Box ml={1}>
                  <Tooltip label="Log out">
                    <IconButton
                      size={"sm"}
                      aria-label="Log out"
                      icon={<GoMoveToEnd />}
                      isRound
                      onClick={handleLogout}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </PopoverHeader>
            <PopoverBody>
              <Heading as="h6" size="xs" my={2} ml={2} color={"#888"}>
                Main Networks
              </Heading>
              <VStack spacing={1} align="flex-start">
                {networksMainNet.map((network) => (
                  <ButtonNetwork key={network.key} network={network} />
                ))}
              </VStack>
              <Heading as="h6" size="xs" my={2} ml={2} color={"#888"}>
                Test Networks
              </Heading>
              <VStack spacing={1} align="flex-start">
                {networksTestNet.map((network) => (
                  <ButtonNetwork key={network.key} network={network} />
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
}

export default NetworkBox;
