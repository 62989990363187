import React, { createContext, useState } from "react";

export const NetworkBoxContext = createContext();

export const NetworkBoxProvider = ({ children }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openPopover = () => {
    setIsPopoverOpen(true);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  return (
    <NetworkBoxContext.Provider
      value={{ isPopoverOpen, openPopover, closePopover }}
    >
      {children}
    </NetworkBoxContext.Provider>
  );
};
