import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";
import ProjectHeader from "./components/ProjectHeader";

import * as Helpers from "../../../services/Helpers";

import { PvBoxSub } from "../../../custom/components";

import { Project } from "polyverse-sdk/dist/api/project";
import { System } from "polyverse-sdk/dist/api/system";

import Editor from "@monaco-editor/react";
import { AiOutlineRobot } from "react-icons/ai";

import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
  useToast,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
  Text,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";

function CustomContract() {
  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const toast = useToast();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const nameInputRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [isAIWorking, setIsAIWorking] = useState(false);
  const [prompt, setPrompt] = useState("");

  const [code, setCode] = useState(
    "//Your contract is your rules, your adventure."
  );
  const [name, setName] = useState("");

  const options = {
    selectOnLineNumbers: true,
  };

  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleGenerate = async () => {
    if (prompt !== "") {
      setIsAIWorking(true);

      const response = await Project.ai.smartContract.generator(
        projectId,
        prompt
      );

      setCode(response);

      setShowModal(false);
      setIsAIWorking(false);
      setPrompt("");
    }
  };

  const handleCodeChange = (e) => {
    setCode(e);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDeploy = async () => {
    if (name === "") {
      nameInputRef.current.focus();
      toast({
        title: "Warning",
        description: "Please enter a contract name",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const currentNetwork = Helpers.getCurrentNetwork();

      const values = {
        name: name,
        type: "Custom",
        sourceCode: code,
        projectId: projectId,
      };

      await System.deployContract(currentNetwork.key, values);
      navigate(`/dashboard/project/${projectId}/contracts`);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);

      try {
        const response = await Project.project(parseInt(projectId));
        setProject(response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setIsLoading(false);
    };

    fetchProject();
  }, [projectId]);

  return (
    <>
      <Header />

      <Box pt={10} bgColor="#111111">
        <Flex
          w={"100%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bgColor={"#000000"}
        >
          <Box
            borderBottom="1px solid #333333"
            borderTop="1px solid #333333"
            w="100%"
            p={5}
          >
            <ProjectHeader
              project={project}
              projectId={projectId}
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Box>

      <Box p={5} bgColor="#111111">
        <Flex
          w={isIpad ? "95%" : isIphone ? "100%" : "65%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          {/* BUTTONS */}

          <Flex alignItems="center" justifyContent="center" w={"100%"}>
            <Heading as="h4" size="md" p={3} textAlign="left">
              Custom Contract
            </Heading>
            <Box ml="auto">
              <IconButton
                icon={<AiOutlineRobot />}
                variant="outline"
                onClick={() => setShowModal(true)}
              />
            </Box>
          </Flex>
          <PvBoxSub p={2}>
            <Editor
              height="400px"
              language="sol"
              value={code}
              theme="vs-dark"
              syntaxHighlighting={true}
              options={options}
              onChange={handleCodeChange}
            />
            <Box mt={2}>
              <Input
                placeholder="Enter your contract name"
                value={name}
                onChange={handleNameChange}
                ref={nameInputRef}
              />
            </Box>
            <Box my={4} textAlign={"center"}>
              <Button variant={"pvWhite"} w={200} onClick={handleDeploy}>
                Deploy
              </Button>
            </Box>
          </PvBoxSub>
        </Flex>
      </Box>

      <Footer />

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        isCentered
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent bg={"#222222"} borderColor={"orange.400"} color={"#fff"}>
          <ModalHeader>AI Smart-Contract Generator</ModalHeader>
          <ModalCloseButton hidden={isAIWorking} />
          <ModalBody minH={200}>
            {!isAIWorking && (
              <>
                <Text mb={4} fontSize={"sm"}>
                  Write the features and requirements of the smart contract you
                  want
                </Text>
              </>
            )}

            <Textarea
              placeholder="Type your prompt here"
              value={prompt}
              onChange={handlePromptChange}
            />

            <Box width={"100%"} display="flex" justifyContent="center">
              <Button
                colorScheme="blue"
                mt={4}
                onClick={handleGenerate}
                isLoading={isAIWorking}
              >
                Generate 🔥
              </Button>
            </Box>

            {!isAIWorking && (
              <Box>
                <Text fontSize={"sm"} fontWeight={500} mb={2}>
                  Some Examples:
                </Text>
                <Tooltip label="Use this prompt" bg={"orange.200"}>
                  <Text
                    sx={{ cursor: "pointer" }}
                    fontSize={"xs"}
                    mt={2}
                    onClick={() =>
                      setPrompt(
                        "Token called PolyAI, symbol PAI, total supply of 1,000,000 tokens."
                      )
                    }
                  >
                    💡Token called PolyAI, symbol PAI, total supply of 1,000,000
                    tokens.
                  </Text>
                </Tooltip>
                <Tooltip label="Use this prompt" bg={"orange.200"}>
                  <Text
                    sx={{ cursor: "pointer" }}
                    fontSize={"xs"}
                    mt={2}
                    onClick={() =>
                      setPrompt(
                        "Token called PolyAI, symbol PAI, total supply of 1,000,000 tokens, with blacklist feature."
                      )
                    }
                  >
                    💡Token called PolyAI, symbol PAI, total supply of 1,000,000
                    tokens, with blacklist feature.
                  </Text>
                </Tooltip>
                <Text mt={2} fontSize={"xs"}>
                  🧠 or use your brain for more.
                </Text>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CustomContract;
