import React, { useState, useEffect } from "react";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import prism from "react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus";
import beautify from "json-beautify";

import * as Helpers from "../../../../../services/Helpers";
import CopyAddressButton from "../../../components/CopyAddressButton";
import SettingForm from "../components/SettingForm";
import MintPhase from "../components/MintPhase";

import { CgRemove } from "react-icons/cg";
import { ArrowForwardIcon, RepeatIcon } from "@chakra-ui/icons";
import { FaRegCopy, FaCopy } from "react-icons/fa";

import { Link as RouterLink, useParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";

import { Contract } from "polyverse-sdk/dist/api/contract";
import { Project } from "polyverse-sdk/dist/api/project";
import { Storage } from "polyverse-sdk/dist/api/storage";
import { Utils } from "polyverse-sdk/dist/utils";

import {
  BiSolidPlusSquare,
  BiSolidAddToQueue,
  BiSolidNavigation,
} from "react-icons/bi";

import {
  SimpleGrid,
  useMediaQuery,
  Text,
  useToast,
  Flex,
  Spinner,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  VStack,
  HStack,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Link,
  Stack,
  TableContainer,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AspectRatio,
  useClipboard,
  FormErrorMessage,
} from "@chakra-ui/react";

import {
  PvBoxSub,
  PvBoxSubTransparent,
  PvBoxLeftMenu,
} from "../../../../../custom/components";

SyntaxHighlighter.registerLanguage("json", json);

function NFTs({ contractItem, projectItem }) {
  const toast = useToast();

  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const [nftTokens, setNftTokens] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const { projectId } = useParams();
  const { contract } = useParams();
  const { menu } = useParams();
  let { section } = useParams();

  const [contractNetwork, setContractNetwork] = useState({});
  const [iframeSrc, setIframeSrc] = useState("");
  const { hasCopied, onCopy } = useClipboard(iframeSrc);

  useEffect(() => {
    const network = Helpers.getNetwork(contractItem.network);

    setContractNetwork(network);

    setIframeSrc(
      `https://mint-frame.polyverse.life/?contract=${contractItem.contract}&network=${network.key}&symbol=${network.symbol}&decimals=${network.decimals}&chainId=${network.chainId}&chainName=${network.name}&rpcUrls=${network.url}&blockExplorerUrls=${network.explorerUrl}`
    );
  }, [contractItem]);

  if (section === undefined) {
    section = "nftdrop";
  }

  const [loadedSections, setLoadedSections] = useState({
    nfts: false,
    settings: false,
    phases: false,
    allowlist: false,
    embed: false,
  });

  const markSectionLoadedState = (section, state) => {
    setLoadedSections((prevSections) => ({
      ...prevSections,
      [section]: state,
    }));
  };

  const [tokenData, setTokenData] = useState({
    maxSupply: 0,
    totalSupply: 0,
    mintedSupply: 0,
    unmintedSupply: 0,
  });

  const fetchNFTsData = async () => {
    markSectionLoadedState("nfts", false);

    try {
      const totalLazyMintedTokens = await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "totalLazyMintedTokens"
      );

      const totalSupply = await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "totalSupply"
      );

      const getMaxSupply = await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "getMaxSupply"
      );

      setTokenData({
        maxSupply: getMaxSupply.data,
        totalSupply: totalLazyMintedTokens.data,
        mintedSupply: totalSupply.data,
        unmintedSupply: totalLazyMintedTokens.data - totalSupply.data,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    markSectionLoadedState("nfts", true);
  };

  useEffect(() => {
    if (section === "nftdrop" && loadedSections.nfts === false) {
      fetchNFTsData();
    }
  }, [contractItem.contract, section]);

  const fetchNFTTokens = async () => {
    if (section !== "nftdrop") {
      return;
    }

    setNftTokens([]);

    const totalLazyMintedTokens = await Contract.execute(
      contractItem.network,
      contractItem.contract,
      "totalLazyMintedTokens"
    );

    if (totalLazyMintedTokens.data > 0) {
      const nftTokensData = [];

      for (let i = 0; i < totalLazyMintedTokens.data; i++) {
        try {
          const methodParamsTokenURI = {
            tokenId: i,
          };

          const tokenURI = await Contract.execute(
            contractItem.network,
            contractItem.contract,
            "tokenURI",
            methodParamsTokenURI
          );

          const tokenData = await Storage.ipfs(
            tokenURI.data?.replace("ipfs://", "")
          );

          let owner = "0x0000000000000000000000000000000000000000";
          try {
            const ownerOf = await Contract.execute(
              contractItem.network,
              contractItem.contract,
              "ownerOf",
              methodParamsTokenURI
            );

            owner = ownerOf.data;
          } catch (error) {}

          nftTokensData.push({
            tokenStandard: "ERC721",
            owner: owner,
            tokenId: i,
            ...tokenData,
          });
        } catch (error) {
          console.error("Error fetching token data:", error);
        }
      }

      setNftTokens(nftTokensData);
    }
  };

  useEffect(() => {
    fetchNFTTokens();
  }, [tokenData.totalSupply]);

  /* Modal Single Upload start */

  const [mediaPreview, setMediaPreview] = useState(null);
  const [mediaFile, setMediaFile] = useState(null);
  const [isOpenSingleUpload, setIsOpenSingleUpload] = useState(false);

  const handleSingleUploadOpen = () => {
    setIsOpenSingleUpload(true);
    setMediaPreview(null);
  };

  const handleSingleUploadCancel = () => {
    setIsOpenSingleUpload(false);
  };

  const handleMediaChange = (event) => {
    try {
      const file = event.currentTarget.files[0];
      setMediaFile(file);
      setMediaPreview(URL.createObjectURL(file));
    } catch (error) {
      setMediaPreview(null);
    }
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);

      // Create an array of attributes
      const attributesArray = values.attributes.map((attribute) => ({
        trait_type: attribute.trait_type,
        value: attribute.value,
      }));

      // Call the SDK method using the formatted values
      const response = await Project.storage.nft.meta(
        values.projectId,
        values.name,
        attributesArray,
        values.description,
        mediaFile // Assuming media is already in binary format
      );

      //lazy mint
      const methodParamsLazymint = {
        _tokenURI: response,
      };

      await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "lazyMint",
        methodParamsLazymint
      );

      setIsOpenSingleUpload(false);

      await fetchNFTTokens();
    } catch (error) {
      console.error("Error submitting data via SDK:", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  /* Modal Single Upload end  */

  /* Modal Batch Upload start */

  const [isOpenBatchUpload, setIsOpenBatchUpload] = useState(false);
  const [countBatchLazyMint, setCountBatchLazyMint] = useState(0);
  const [selectedBatchFiles, setSelectedBatchFiles] = useState([]);

  const handleBatchFilesChange = async (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();

    reader.onload = (event) => {
      try {
        const json = JSON.parse(event.target.result);
        if (Array.isArray(json)) {
          setSelectedBatchFiles(json);
        } else {
          console.log("JSON object is not an array");
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Error parsing JSON file: " + error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    reader.onerror = (event) => {
      toast({
        title: "Error",
        description: "File could not be read! Code " + event.target.error.code,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const handleBatchUploadOpen = () => {
    setIsOpenBatchUpload(true);
  };

  const handleBatchUploadModalClose = () => {
    setIsOpenBatchUpload(false);
    setSelectedBatchFiles([]);
  };

  const handleBatchLazyMint = async () => {
    setIsLoading(true);

    let remaining = selectedBatchFiles.length;
    setCountBatchLazyMint(remaining);

    const responseMetaArray = await Promise.all(
      selectedBatchFiles.map(async (item) => {
        const responseMeta = await Project.storage.nft.meta(
          projectId,
          item.name,
          item.attributes,
          item.description,
          null,
          item.image
        );
        setCountBatchLazyMint(--remaining);
        return responseMeta;
      })
    );

    let metaTupple = JSON.stringify(responseMetaArray);

    await lazyMintBatch(metaTupple);

    handleBatchUploadModalClose();

    setIsLoading(false);
  };

  const lazyMintBatch = async (tokenURIs) => {
    const formDataLazyMint = new FormData();
    formDataLazyMint.append("format", "raw");
    formDataLazyMint.append("_tokenURIs", tokenURIs);

    //lazy mint
    const methodParamsLazymintBatch = {
      _tokenURIs: tokenURIs,
    };

    await Contract.execute(
      contractItem.network,
      contractItem.contract,
      "lazyMintMultiple",
      methodParamsLazymintBatch
    );
  };

  /* Modal Batch Upload end */

  /* Modal Mint start */

  const [isOpenMintModal, setIsOpenMintModal] = useState(false);
  const [mintPriceValue, setMintPriceValue] = useState(0);

  const handleMintOpen = async () => {
    setIsLoading(true);
    setMintPriceValue(0);

    const mintPrice = await Contract.execute(
      contractItem.network,
      contractItem.contract,
      "getMintPrice"
    );

    setMintPriceValue(
      Utils.fromWeiWithDecimal(mintPrice.data, contractNetwork.decimals)
    );

    setIsLoading(false);
  };

  useEffect(() => {
    if (mintPriceValue > 0) {
      setIsOpenMintModal(true);
    }
  }, [mintPriceValue]);

  const handleMintCancel = () => {
    setIsOpenMintModal(false);
  };

  const onSubmitMint = async (values) => {
    try {
      setIsLoading(true);

      const methodParamsMint = {
        value: values.value,
        recipient: values.recipient,
      };

      await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "mint",
        methodParamsMint
      );

      setIsOpenMintModal(false);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  /* Modal Mint end */

  /* Modal NFT Detail start */

  const [isOpenMintDetailModal, setIsOpenMintDetailModal] = useState(false);
  const [nftDetail, setNftDetail] = useState([]);

  const showNFTDetail = async (tokenId) => {
    const filteredTokens = nftTokens.find((token) => token.tokenId === tokenId);

    setNftDetail(filteredTokens);
    handleNFTDetailOpen();
  };

  const handleNFTDetailOpen = () => {
    setIsOpenMintDetailModal(true);
  };

  const handleNFTDetailCancel = () => {
    setIsOpenMintDetailModal(false);
  };

  /* Modal NFT Detail end */

  /* Modal Phase start */

  const [mintPhases, setMintPhases] = useState([]);
  const [phasesChanged, setPhasesChanged] = useState(0);

  const handlePhaseAdd = (option) => {
    const maxIndex = mintPhases.reduce((maxIndex, phase) => {
      return Math.max(maxIndex, phase.index);
    }, 0);

    const newPhase = {
      index: maxIndex + 1,
      startTime: 0,
      endTime: 0,
      maxMintsPerWallet: 0,
      option: option,
      saved: 0,
    };

    setMintPhases([...mintPhases, newPhase]);

    window.scrollTo(0, document.body.scrollHeight + 400);
  };

  const removeMintPhase = (index) => {
    const updatedMintPhases = [...mintPhases];
    updatedMintPhases.splice(index, 1);
    setMintPhases(updatedMintPhases);
  };

  const getMintPhases = async () => {
    setMintPhases([]);

    setIsLoading(true);

    try {
      const resultPhases = await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "getMintPhases"
      );

      if (resultPhases.data) {
        let index = 0;

        for (const item of resultPhases.data) {
          item.saved = 1;
          item.index = index++;
          setMintPhases((prevPhases) => [...prevPhases, item]);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (section === "phases" && loadedSections.phases === false) {
      getMintPhases();
    }
  }, [contractItem.contract, section]);

  /* Modal Phase end */

  return (
    <>
      <SimpleGrid
        columns={2}
        spacing={0}
        w="100%"
        mt={5}
        templateColumns={isIphone ? "100%" : "20% 80%"}
      >
        <PvBoxSub w={isIphone ? "100%" : "98%"} px={2}>
          <Flex direction="column">
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/nftdrop`}
              isActive={section === "nftdrop"}
            >
              NFTs
            </PvBoxLeftMenu>
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/settings`}
              isActive={section === "settings"}
            >
              Settings
            </PvBoxLeftMenu>
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/phases`}
              isActive={section === "phases"}
            >
              Phases
            </PvBoxLeftMenu>
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/allowlist`}
              isActive={section === "allowlist"}
            >
              Allowlist
            </PvBoxLeftMenu>
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/embed`}
              isActive={section === "embed"}
            >
              Embed
            </PvBoxLeftMenu>
          </Flex>
        </PvBoxSub>
        <PvBoxSubTransparent p={0} pl={isIphone ? 0 : 5} mt={isIphone ? 4 : 0}>
          <PvBoxSub isHidden={section !== "nftdrop"}>
            {!loadedSections.nfts && (
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                my={10}
              >
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}

            {loadedSections.nfts && (
              <>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Heading as="h4" size="md" display="inline">
                    Contract NFTs
                  </Heading>
                  <HStack spacing={2} overflowX="auto">
                    <ButtonGroup variant={"pvDarkGrayF"} direction="column">
                      <Button
                        leftIcon={<BiSolidPlusSquare color="#fff" />}
                        onClick={handleSingleUploadOpen}
                      >
                        Single Upload
                      </Button>
                      <Button
                        leftIcon={<BiSolidAddToQueue />}
                        onClick={handleBatchUploadOpen}
                      >
                        Batch Upload
                      </Button>
                      <Button
                        leftIcon={<BiSolidNavigation />}
                        hidden={tokenData.totalSupply === 0}
                        onClick={handleMintOpen}
                        isLoading={isLoading}
                        loadingText="Mint"
                      >
                        Mint
                      </Button>
                    </ButtonGroup>
                  </HStack>
                </Flex>
                {tokenData.totalSupply === 0 && (
                  <Text>
                    You do not have lazy minted NFTs. Start with upload
                  </Text>
                )}

                {tokenData.totalSupply > 0 && (
                  <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
                    <Table variant="unstyled" p={0}>
                      <Thead bg="black" h={11}>
                        <Tr>
                          <Th color="#888">Token ID</Th>
                          <Th color="#888">Image</Th>
                          <Th color="#888">Name</Th>
                          <Th color="#888">Description</Th>
                          <Th color="#888">Owner</Th>
                          <Th color="#888">
                            <IconButton
                              icon={<RepeatIcon />}
                              size={"xs"}
                              onClick={fetchNFTTokens}
                              hidden={nftTokens.length === 0}
                            />
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {nftTokens.length === 0 ? (
                          <Tr>
                            <Td colSpan={6} textAlign="center">
                              <Spinner size="sm" />
                            </Td>
                          </Tr>
                        ) : (
                          nftTokens.map((token, index) => (
                            <Tr
                              key={index}
                              _hover={{ bgColor: "#232323" }}
                              cursor="pointer"
                              onClick={() => showNFTDetail(token.tokenId)}
                            >
                              <Td>{token.tokenId}</Td>
                              <Td>
                                <Image
                                  src={`https://api.polyverse.life/storage/ipfs/${token.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`}
                                  maxBlockSize={110}
                                />
                              </Td>
                              <Td>{token.name}</Td>
                              <Td
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                maxW={150}
                              >
                                {token.description}
                              </Td>
                              <Td>
                                <CopyAddressButton address={token.owner} />
                              </Td>
                              <Td>
                                <ArrowForwardIcon />
                              </Td>
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </>
            )}
          </PvBoxSub>

          <PvBoxSubTransparent p={0} isHidden={section !== "settings"}>
            <SettingForm
              inputs={[
                {
                  type: "number",
                  display: "ether",
                  convert: "wei",
                  name: "newMintPrice",
                  label: "Mint Price",
                  description: "Set or change mint price.",
                  valueMethod: "getMintPrice",
                },
              ]}
              contract={contractItem}
              saveMethod="setMintPrice"
              caption="Update"
              mb={5}
              sectionName={"settings"}
            />

            <SettingForm
              inputs={[
                {
                  type: "datetime",
                  name: "revealTimestamp",
                  label: "Reveal Time",
                  description: "Set or change reveal time.",
                  valueMethod: "getRevealTime",
                },
              ]}
              contract={contractItem}
              saveMethod="setMintPrice"
              caption="Update"
              mb={5}
              sectionName={"settings"}
            />

            <SettingForm
              inputs={[
                {
                  type: "string",
                  name: "newURI",
                  label: "Unrevealed URI",
                  description: "Set or change unrevealed URI.",
                  valueMethod: "getUnrevealedURI",
                },
              ]}
              contract={contractItem}
              saveMethod="setUnrevealedURI"
              caption="Update"
              mb={5}
              sectionName={"settings"}
            />

            <SettingForm
              inputs={[
                {
                  type: "input'",
                  name: "recipient",
                  label: "Royalties",
                  description: "Set or change recipient address.",
                  valueMethod: "getRoyaltyInfo",
                  valueIndex: 0,
                },
                {
                  type: "input'",
                  name: "percentage",
                  label: "Percentage",
                  description: "Set or change royalty percentage.",
                  valueMethod: "getRoyaltyInfo",
                  valueIndex: 1,
                },
              ]}
              contract={contractItem}
              saveMethod="setRoyaltyInfo"
              caption="Update"
              mb={5}
              sectionName={"settings"}
            />
          </PvBoxSubTransparent>

          <PvBoxSub isHidden={section !== "phases"}>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading as="h4" size="md" display="inline">
                Phases
              </Heading>
              <HStack spacing={2} overflowX="auto">
                <Menu variant="pvFilterMenu">
                  <MenuButton
                    as={Button}
                    variant={"pvDarkGrayF"}
                    rightIcon={<BiSolidPlusSquare />}
                  >
                    Add
                  </MenuButton>
                  <MenuList fontSize={"sm"}>
                    <MenuItem onClick={() => handlePhaseAdd(0)}>
                      Public
                    </MenuItem>
                    <MenuItem onClick={() => handlePhaseAdd(1)}>
                      Allowlist Only
                    </MenuItem>
                    <MenuItem onClick={() => handlePhaseAdd(2)}>
                      Owner Only
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </Flex>
            {mintPhases.length === 0 && !isLoading && (
              <Text fontSize={"sm"} my={5}>
                You do not have mint phases. To start the claims process, you
                should add at least one phase.
              </Text>
            )}
            {mintPhases.length === 0 && isLoading && (
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                my={10}
              >
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}
          </PvBoxSub>
          <PvBoxSubTransparent p={0} isHidden={section !== "phases"}>
            {mintPhases.map((item, index) => (
              <MintPhase
                key={index}
                item={item}
                phasesChanged={phasesChanged}
                setPhasesChanged={setPhasesChanged}
                contract={contractItem}
                getMintPhases={getMintPhases}
                removeMintPhase={removeMintPhase}
              />
            ))}
          </PvBoxSubTransparent>

          <PvBoxSubTransparent p={0} isHidden={section !== "allowlist"}>
            <SettingForm
              inputs={[
                {
                  type: "tuple",
                  name: "users",
                  label: "Add Allowlist",
                  description: "Add wallets to allowlist.",
                  valueMethod: "getAllowlist",
                  solidityType: "address[]",
                },
              ]}
              contract={contractItem}
              saveMethod="addToAllowlist"
              caption="Update"
              mt={0}
              sectionName={"allowlist"}
            />

            <SettingForm
              inputs={[
                {
                  type: "tuple",
                  name: "users",
                  label: "Remove Allowlist",
                  description: "Remove wallets from allowlist.",
                  solidityType: "address[]",
                },
              ]}
              contract={contractItem}
              saveMethod="removeFromAllowlist"
              caption="Update"
              mt={5}
              sectionName={"allowlist"}
            />
          </PvBoxSubTransparent>

          <PvBoxSub isHidden={section !== "embed"}>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Heading as="h4" size="md" display="inline">
                Embed
              </Heading>
            </Flex>
            <Text fontSize={"lg"} mb={4}>
              Embed Code
            </Text>
            <Box
              border="1px solid #888"
              bgColor="#1e1e1e"
              borderRadius={6}
              display="flex"
              justifyContent="space-between"
            >
              <SyntaxHighlighter language="solidity" style={prism}>
                {iframeSrc}
              </SyntaxHighlighter>
              <IconButton
                icon={hasCopied ? <FaCopy /> : <FaRegCopy />}
                size="sm"
                mr={1}
                mt={1}
                onClick={onCopy}
              />
            </Box>

            <Text fontSize={"lg"} my={4}>
              Preview
            </Text>
            <Box border="1px solid #888" bgColor="#1e1e1e" borderRadius={6}>
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src={iframeSrc}
                  frameBorder="0"
                  allowFullScreen
                  title="Mint Preview"
                ></iframe>
              </AspectRatio>
            </Box>
          </PvBoxSub>
        </PvBoxSubTransparent>
      </SimpleGrid>

      {/* Lazy Mint NFT modal */}
      <Modal
        isOpen={isOpenSingleUpload}
        size="md"
        isCentered
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              projectId: projectId,
              name: "",
              media: null,
              description: "",
              attributes: [],
            }}
            validate={(values) => {
              const errors = {};
              if (!values.description) {
                errors.description = "Required";
              }
              if (!values.name) {
                errors.name = "Required";
              }
              return errors;
            }}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched }) => (
              <Form>
                <ModalHeader>Lazy Mint NFT</ModalHeader>
                <ModalBody>
                  <VStack spacing={4}>
                    <FormControl
                      isInvalid={errors.name && touched.name}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field name="name" as={Input} />
                      <FormErrorMessage>
                        {errors.name && touched.name && errors.name}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Media</FormLabel>
                      <input
                        type="file"
                        name="media"
                        onChange={handleMediaChange}
                      />
                      {mediaPreview && (
                        <Box>
                          <img
                            src={mediaPreview}
                            alt="Media Preview"
                            style={{ maxWidth: "100%", maxHeight: "200px" }}
                          />
                        </Box>
                      )}
                    </FormControl>
                    <FormControl
                      isInvalid={errors.description && touched.description}
                    >
                      <FormLabel>Description</FormLabel>
                      <Field name="description" as={Textarea} />
                      <FormErrorMessage>
                        {errors.description &&
                          touched.description &&
                          errors.description}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Attributes</FormLabel>
                      <FieldArray name="attributes">
                        {({ insert, remove, push }) => (
                          <>
                            {values.attributes.map((_, index) => (
                              <HStack spacing={2} key={index} mt={2}>
                                <Field
                                  name={`attributes[${index}].trait_type`}
                                  as={Input}
                                />
                                <Field
                                  name={`attributes[${index}].value`}
                                  as={Input}
                                />

                                <IconButton
                                  icon={<CgRemove />}
                                  onClick={() => remove(index)}
                                />
                              </HStack>
                            ))}
                            <Button
                              onClick={() =>
                                push({ trait_type: "", value: "" })
                              }
                              variant="pvBlackGray"
                              mt={2}
                            >
                              + Add Row
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </FormControl>
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={handleSingleUploadCancel}
                    variant="pvBlackGray"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="pvBlackGray"
                    isDisabled={mediaPreview === null}
                    isLoading={isLoading}
                  >
                    Lazy Mint
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      {/* Lazy Batch Mint NFT modal */}
      <Modal
        isOpen={isOpenBatchUpload}
        isCentered
        size="md"
        scrollBehavior="inside"
        overflowY="auto"
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Batch Lazy Mint NFTs</ModalHeader>
          <ModalBody pb={10}>
            {selectedBatchFiles.length === 0 ? (
              <>
                <Text>Requirements:</Text>
                <Text mt={2}>
                  Files must contain one .json file with metadata.{" "}
                  <Link
                    href="https://dashboard.polyverse.life/example.json"
                    color={"blue.200"}
                    download
                    isExternal
                  >
                    Download example.json
                  </Link>
                  (or use Save Link As with right click)
                </Text>
                <Box mt={5}>
                  <form>
                    <Stack spacing={3}>
                      <FormControl isRequired>
                        <label
                          htmlFor="files"
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            color: "#000",
                            padding: "0.5rem 1rem",
                            borderRadius: "0.25rem",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          Select File
                        </label>
                        <Input
                          id="files"
                          type="file"
                          accept=".json" // Only allow .json files
                          style={{ display: "none" }}
                          onChange={handleBatchFilesChange}
                        />
                      </FormControl>
                    </Stack>
                  </form>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <TableContainer>
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th>Id</Th>
                          <Th>Image</Th>
                          {/* <Th>Animation Url</Th> */}
                          <Th>Name</Th>
                          <Th>Description</Th>
                          <Th>Attributes</Th>
                          {/* <Th>External Url</Th> */}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {selectedBatchFiles.map((item, index) => (
                          <Tr key={index}>
                            <Td>{index}</Td>
                            <Td>
                              <Image
                                src={
                                  "https://api.polyverse.life/nft/image?url=" +
                                  item.image +
                                  "&width=200"
                                }
                              />
                            </Td>
                            <Td>
                              <Text isTruncated maxWidth="96px">
                                {item.name}
                              </Text>
                            </Td>
                            <Td>
                              <Textarea
                                value={item.description}
                                w={48}
                                h={100}
                                fontSize={"sm"}
                              />
                            </Td>
                            <Td>
                              <Box h={"100px"} overflowY={"auto"}>
                                <SyntaxHighlighter
                                  language="json"
                                  style={prism}
                                >
                                  {beautify(
                                    JSON.parse(JSON.stringify(item.attributes)),
                                    null,
                                    2,
                                    20
                                  )}
                                </SyntaxHighlighter>
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleBatchUploadModalClose} variant="pvBlackGray">
              Cancel
            </Button>
            <Button
              variant="pvBlackGray"
              ml={3}
              type="submit"
              isLoading={isLoading}
              onClick={handleBatchLazyMint}
              loadingText={`${selectedBatchFiles.length}/${countBatchLazyMint}`}
              isDisabled={selectedBatchFiles.length === 0}
            >
              Lazy Mint
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Mint NFT modal */}
      <Modal
        isOpen={isOpenMintModal}
        size="md"
        isCentered
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              value: mintPriceValue,
              recipient: Helpers.getUserAddress(),
            }}
            onSubmit={onSubmitMint}
          >
            {({ values }) => (
              <Form>
                <ModalHeader>Mint NFT</ModalHeader>
                <ModalBody>
                  <VStack spacing={4}>
                    <Tooltip label="The price of minting a single NFT">
                      <FormControl>
                        <FormLabel>
                          Amount to Pay (in {contractNetwork.symbol})
                        </FormLabel>
                        <Field
                          name="value"
                          as={Input}
                          isReadOnly
                          isDisabled={true}
                        />
                      </FormControl>
                    </Tooltip>
                    <FormControl>
                      <FormLabel>Recipient</FormLabel>
                      <Field name="recipient" as={Input} />
                    </FormControl>
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={handleMintCancel} variant="pvBlackGray">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="pvBlackGray"
                    isLoading={isLoading}
                  >
                    Mint
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      {/* NFT detail modal */}
      <Modal
        isOpen={isOpenMintDetailModal}
        size="md"
        isCentered
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>NFT Detail</ModalHeader>
          <ModalBody>
            <SimpleGrid
              columns={2}
              spacing={2}
              w="100%"
              templateColumns={isIphone ? "100%" : "40% 60%"}
            >
              <Box>
                <Image
                  src={`https://api.polyverse.life/storage/ipfs/${nftDetail.image?.replace(
                    "ipfs://",
                    ""
                  )}`}
                  maxBlockSize={110}
                />
              </Box>
              <Box>
                <Text fontWeight={"bold"} fontSize={"lg"}>
                  {nftDetail.name}
                </Text>
                <Text color={"#888"}>{nftDetail.description}</Text>
              </Box>
            </SimpleGrid>
            <Box mt={5}>
              <Text fontWeight={"bold"}>Properties</Text>
              <SimpleGrid columns={4} spacing={2} mt={2}>
                {nftDetail.attributes?.map((attribute, index) => (
                  <Box
                    border={"1px solid #888"}
                    borderRadius={6}
                    p={2}
                    key={index}
                    fontSize={"sm"}
                  >
                    <Text fontWeight="bold">{attribute.trait_type}</Text>
                    <Text color={"#888"}>{attribute.value}</Text>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleNFTDetailCancel} variant="pvBlackGray">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NFTs;
