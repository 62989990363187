"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Project = exports.AI = exports.SmartContract = exports.Storage = exports.NFT = exports.File = void 0;
const apiHelper_1 = require("./utils/apiHelper");
class File {
    static upload(projectId, file, type = 'Storage', encrypt = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('projectId', projectId.toString());
            formData.append('file', file);
            formData.append('type', type);
            formData.append('encrypt', encrypt.toString());
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/Upload`, formData);
            return response.data;
        });
    }
}
exports.File = File;
File.CONTROLLER_NAME = 'Project/Storage/File';
class NFT {
    static meta(projectId, name, attributes, description, media, image) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('projectId', projectId.toString());
            formData.append('name', name);
            if (media) { // Check if media is not null or undefined
                formData.append('media', media);
            }
            if (description) { // Check if description is not null or undefined
                formData.append('description', description);
            }
            if (image) { // Check if image is not null or undefined
                formData.append('image', image);
            }
            attributes.forEach((attribute, index) => {
                formData.append(`attributes[${index}].trait_type`, attribute.trait_type);
                formData.append(`attributes[${index}].value`, attribute.value.toString());
            });
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/meta`, formData);
            return response.data;
        });
    }
}
exports.NFT = NFT;
NFT.CONTROLLER_NAME = 'Project/Storage/NFT';
class Storage {
    static files(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Files?projectId=${projectId}`);
            return response.data;
        });
    }
}
exports.Storage = Storage;
Storage.CONTROLLER_NAME = 'Project/Storage';
Storage.nft = NFT;
Storage.file = File;
class SmartContract {
    static generator(projectId, prompt) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('projectId', projectId.toString());
            formData.append('prompt', prompt);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/Generator`, formData);
            return response.data;
        });
    }
}
exports.SmartContract = SmartContract;
SmartContract.CONTROLLER_NAME = 'Project/AI/SmartContract';
class AI {
}
exports.AI = AI;
AI.smartContract = SmartContract;
class Project {
    static statistics() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/statistics`);
            return response.data;
        });
    }
    static projects() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/projects`);
            return response.data;
        });
    }
    static create(name, description, template, additionalData) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('desc', description);
            if (template) {
                formData.append('template', template);
            }
            if (additionalData) {
                for (let [key, value] of additionalData.entries()) {
                    formData.append(key, value);
                }
            }
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/create`, formData);
            return response.data;
        });
    }
    static project(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/projects`);
            const project = response.data.find((project) => project.id === projectId);
            return project || null;
        });
    }
    static deployedContracts(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = new URL(`/${this.CONTROLLER_NAME}/DeployedContracts`, 'http://api.polyverse.life/wallet/fuji/balance');
            url.searchParams.append('projectId', projectId.toString());
            const response = yield apiHelper_1.ApiHelper.apiGet(url.pathname + url.search);
            return response.data;
        });
    }
    static deployedContract(projectId, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = new URL(`/${this.CONTROLLER_NAME}/DeployedContracts`, 'http://api.polyverse.life/wallet/fuji/balance');
            url.searchParams.append('projectId', projectId.toString());
            const response = yield apiHelper_1.ApiHelper.apiGet(url.pathname + url.search);
            const contractItem = response.data.find((item) => item.contract === contract);
            return contractItem || null;
        });
    }
    static edit(id, name, description) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('id', id.toString());
            formData.append('name', name);
            formData.append('desc', description);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/edit`, formData);
            return response.data;
        });
    }
    static trash(id, trash) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('id', id.toString());
            formData.append('trash', trash.toString());
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/trash`, formData);
            return response.data;
        });
    }
    static users(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Users?projectId=${projectId}`);
            return response.data;
        });
    }
    static storageFileDelete(projectId, hash) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Storage/File/Delete?projectId=${projectId}&hash=${hash}`);
                return response.data;
            }
            catch (error) {
                console.error("Storage file delete işleminde bir hata meydana geldi:", error);
                throw error; // Hata bilgisini dışarıya da fırlatabilirsiniz.
            }
        });
    }
    static configFile(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/ConfigFile?projectId=${projectId}`);
            return response.data;
        });
    }
    static verifySignature(projectId, projectSecret, signature, address) {
        return __awaiter(this, void 0, void 0, function* () {
            const formdata = new FormData();
            formdata.append("signature", signature);
            formdata.append("address", address);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/VerifySignature`, formdata, projectId, projectSecret);
            return response.data;
        });
    }
}
exports.Project = Project;
Project.CONTROLLER_NAME = 'Project';
Project.storage = Storage;
Project.ai = AI;
