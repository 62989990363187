import React from "react";

import { WalletBalanceProvider } from "./contexts/WalletBalanceContext";
import { ProjectsProvider } from "./contexts/ProjectsContext";
import { ContractsProvider } from "./contexts/ContractsContext";
import { TokenOverviewProvider } from "./contexts/TokenOverviewContext";

import { Route, Routes } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";

import theme from "./custom/theme";

import HomePage from "./pages/Home/Index";
import Dashboard from "./pages/Dashboard/Index";
import DashboardProjects from "./pages/Dashboard/Projects";
import DashboardLogin from "./pages/Dashboard/Login";
import DashboardSignUp from "./pages/Dashboard/SignUp";
import DashboardHome from "./pages/Dashboard/Home";
import DashboardExplore from "./pages/Dashboard/Explore/Index";
import DashboardTemplates from "./pages/Dashboard/Templates/Index";
import DashboardProjectCreate from "./pages/Dashboard/Project/Create";
import DashboardProjectCustomContract from "./pages/Dashboard/Project/CustomContract";
import DashboardProjectEdit from "./pages/Dashboard/Project/Edit";
import DashboardProject from "./pages/Dashboard/Project/Index";
import DashboardProjectUsers from "./pages/Dashboard/Project/Users";
import DashboardProjectStorage from "./pages/Dashboard/Project/Storage";
import DashboardExploreContractDeploy from "./pages/Dashboard/Explore/ContractDeploy";
import DashboardProjectContract from "./pages/Dashboard/Project/Contract/Index";
import DashboardProjectCEX from "./pages/Dashboard/Project/CEX/Index";
import GetEarlyAccess from "./pages/GetEarlyAccess";
import API from "./pages/API/Index";
import SDKs from "./pages/SDKs/Index";
import UIComponents from "./pages/UI-Components/Index";
import Storage from "./pages/Storage/Index";
import Gaming from "./pages/Solutions/Gaming";
import Business from "./pages/Solutions/Business";
import Minting from "./pages/Solutions/Minting";
import NoMatch from "./pages/NotMatch";
import ComingSoon from "./pages/ComingSoon";

function App() {
  localStorage.setItem("chakra-ui-color-mode", "dark");

  return (
    <ChakraProvider theme={theme}>
      <TokenOverviewProvider>
        <WalletBalanceProvider>
          <ProjectsProvider>
            <ContractsProvider>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/login" element={<DashboardLogin />} />
                <Route path="/dashboard/signup" element={<DashboardSignUp />} />
                <Route path="/dashboard/home" element={<DashboardHome />} />
                <Route
                  path="/dashboard/projects"
                  element={<DashboardProjects />}
                />
                <Route
                  path="/dashboard/project/create"
                  element={<DashboardProjectCreate />}
                />
                <Route
                  path="/dashboard/project/edit/:projectId"
                  element={<DashboardProjectEdit />}
                />
                <Route
                  path="/dashboard/project/:projectId/contracts"
                  element={<DashboardProject />}
                />
                <Route
                  path="/dashboard/project/:projectId/users"
                  element={<DashboardProjectUsers />}
                />
                <Route
                  path="/dashboard/project/:projectId/storage"
                  element={<DashboardProjectStorage />}
                />
                <Route
                  path="/dashboard/project/:projectId/customcontract"
                  element={<DashboardProjectCustomContract />}
                />
                <Route
                  path="/dashboard/explore/:projectId?"
                  element={<DashboardExplore />}
                />
                <Route
                  path="/dashboard/templates"
                  element={<DashboardTemplates />}
                />
                <Route path="/getearlyaccess" element={<GetEarlyAccess />} />
                <Route
                  path="/dashboard/explore/contract/:contractId/deploy/:projectId?"
                  element={<DashboardExploreContractDeploy />}
                />
                <Route
                  path="/dashboard/project/:projectId/contract/:contract/:menu/:section?"
                  element={<DashboardProjectContract />}
                />
                <Route
                  path="/dashboard/project/:projectId/cex/:section?"
                  element={<DashboardProjectCEX />}
                />
                <Route path="/api" element={<API />} />
                <Route path="/sdks" element={<SDKs />} />
                <Route path="/ui-components" element={<UIComponents />} />
                <Route path="/storage" element={<Storage />} />
                <Route path="/solutions/gaming" element={<Gaming />} />
                <Route path="/solutions/business" element={<Business />} />
                <Route path="/solutions/minting-nfts" element={<Minting />} />
                <Route path="/cs" element={<ComingSoon />} />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </ContractsProvider>
          </ProjectsProvider>
        </WalletBalanceProvider>
      </TokenOverviewProvider>
    </ChakraProvider>
  );
}

export default App;
