import React, { useEffect, useState } from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  useToast,
  Skeleton,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";
import { PvBox } from "../../../../../custom/components";

function NFTDropOverview({ contract, project }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [tokenData, setTokenData] = useState({
    maxSupply: 0,
    totalSupply: 0,
    mintedSupply: 0,
    unmintedSupply: 0,
  });

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const totalLazyMintedTokens = await Contract.execute(
        contract.network,
        contract.contract,
        "totalLazyMintedTokens"
      );

      const totalSupply = await Contract.execute(
        contract.network,
        contract.contract,
        "totalSupply"
      );

      const getMaxSupply = await Contract.execute(
        contract.network,
        contract.contract,
        "getMaxSupply"
      );

      setTokenData({
        maxSupply: getMaxSupply.data,
        totalSupply: totalLazyMintedTokens.data,
        mintedSupply: totalSupply.data,
        unmintedSupply: totalLazyMintedTokens.data - totalSupply.data,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [contract.contract]);

  return (
    <>
      <PvBox>
        <StatGroup>
          <Stat>
            <StatLabel>Max Supply</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                tokenData.maxSupply
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Total Supply</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                tokenData.totalSupply
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Minted Supply</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                tokenData.mintedSupply
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Unminted Supply</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                tokenData.unmintedSupply
              )}
            </StatNumber>
          </Stat>
        </StatGroup>
      </PvBox>
    </>
  );
}

export default NFTDropOverview;
