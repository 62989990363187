import React, { useState, useEffect } from "react";

import { System } from "polyverse-sdk/dist/api/system";

import menuData from "../../datas/menuData.json";
import simplifyData from "../../datas/simplifyData.json";
import { Link as RouterLink } from "react-router-dom";
import AnimatedBox from "./Components/AnimatedBox";
import CardSlogan from "../../components/CardSlogan";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import {
  Box,
  Text,
  Flex,
  Image,
  Button,
  LinkBox,
  LinkOverlay,
  Heading,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  useMediaQuery,
  createIcon,
  Stack,
  Icon,
} from "@chakra-ui/react";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";

const Arrow = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="white"
    />
  ),
});

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await System.statistics();
        setStatistics(response);
      } catch (error) {
        console.error("Failed to fetch system statistics:", error);
      }
    };

    fetchStatistics();
  }, []);

  return (
    <>
      <Header />

      <Box>
        <Flex
          pt={{ base: 5, md: 5, lg: 10, "2xl": 20 }}
          px={{ base: 5, md: 10, lg: 10, xl: "28", "2xl": 40 }}
          align={"center"}
          flexDirection="column"
        >
          <Box
            w={{ base: "100%", sm: "90%", md: "90%", lg: "80%", xl: "80%" }}
            justifyContent="center"
            textAlign="center"
            position={"relative"}
          >
            <Heading
              as="h2"
              size="2xl"
              mt={{ base: 5, sm: 5, md: 5, lg: 0 }}
              fontWeight={"bold"}
              bgGradient="linear(to-b, white, gray.200)"
              bgClip="text"
            >
              Build with the power of web3 — without writing any code
            </Heading>
            <Heading as="h3" size="lg" mt={10}>
              Web3 at your fingertips
            </Heading>
            {/*             <Button
              mt={50}
              fontSize={"2xl"}
              as={RouterLink}
              to={"/dashboard/login"}
            >
              Get Started 🚀
            </Button> */}
            <Button
              mt={50}
              fontSize={"2xl"}
              as={RouterLink}
              to={"/getearlyaccess"}
            >
              Get Started 🚀
            </Button>
          </Box>
          <AnimatedBox />
        </Flex>
      </Box>

      <Box>
        <Flex
          w="100%"
          p={5}
          backgroundColor={"#111111"}
          borderBottomColor={"#333333"}
          borderTopColor={"#333333"}
          borderBottomWidth="1px"
          borderTopWidth="1px"
          mt={-200}
          minH={"225px"}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <SimpleGrid
            spacing={4}
            templateColumns={isSmallerThan480 ? "1fr" : "repeat(3, 1fr)"}
            w={"80%"}
          >
            {simplifyData.map((item, index) => (
              <Card bg="transparent" key={index}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <Image src={item.icon} boxSize="32px" mr={3} />
                    <CardHeader p={0}>
                      <Text fontSize="2xl" fontWeight={"bold"} textAlign="left">
                        {item.title}
                      </Text>
                    </CardHeader>
                  </Box>
                  <CardBody pt={2} pl={0}>
                    <Text color={"#888888"} textAlign="left" fontSize={"md"}>
                      {item.text}
                    </Text>
                  </CardBody>
                </Box>
              </Card>
            ))}
          </SimpleGrid>
        </Flex>
      </Box>

      <Box mt={35}>
        <Flex
          w="100%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={10}
        >
          <CardSlogan
            title="Codeless blockchain experience"
            text="Simplifying Web3 for everyone!"
            description={{
              __html: `
      ERC721, ERC721A, ERC1155, ERC20... you read it over and over
      again... But what are these❓
      <br /> The only thing you want to do is a dApp, GameFi or NFT. Yes,
      we see you. 🙋‍♂️
    `,
            }}
          />

          <Stack
            direction={"column"}
            spacing={3}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
            mt={20}
          >
            <Button
              as={"a"}
              href="https://docs.polyverse.life/"
              target="_blank"
              variant={"pvWhite"}
              px={6}
              fontSize={"sm"}
            >
              Learn More
            </Button>
            <Box>
              <Icon
                as={Arrow}
                color="gray.800"
                w={71}
                position={"absolute"}
                right={-71}
                top={"10px"}
              />
              <Text
                fontSize={"lg"}
                fontFamily={"Caveat"}
                position={"absolute"}
                right={"-145px"}
                top={"-16px"}
                transform={"rotate(10deg)"}
                color={"#fff"}
              >
                Give us your hand 💕
              </Text>
            </Box>
          </Stack>
        </Flex>
      </Box>

      <Box>
        <Flex
          w="100%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={10}
        >
          {statistics && (
            <SimpleGrid
              columns={isSmallerThan480 ? 1 : 3}
              spacing={10}
              px={{ base: 4, md: 8 }}
            >
              <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                borderColor={"#333333"}
                borderRadius={6}
              >
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  bgGradient="linear(to-b, white, gray.300)"
                  bgClip="text"
                >
                  {statistics.projects}
                </Text>
                <Text mt={2} fontSize={"lg"} color={"#888888"}>
                  Web3 Projects created
                </Text>
              </Box>
              <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                borderColor={"#333333"}
                borderRadius={6}
              >
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  bgGradient="linear(to-b, white, gray.300)"
                  bgClip="text"
                >
                  {statistics.deployedContracts}
                </Text>
                <Text mt={2} fontSize={"lg"} color={"#888888"}>
                  Total Contracts Deployed
                </Text>
              </Box>

              <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                borderColor={"#333333"}
                borderRadius={6}
              >
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  bgGradient="linear(to-b, white, gray.300)"
                  bgClip="text"
                >
                  {statistics.users}
                </Text>
                <Text mt={2} fontSize={"lg"} color={"#888888"}>
                  Total Users/Wallets
                </Text>
              </Box>
            </SimpleGrid>
          )}
        </Flex>
      </Box>

      <Box mt={35}>
        <Flex
          w="100%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={5}
        >
          <Text
            fontSize="4xl"
            fontWeight="bold"
            fontStyle="normal"
            bgGradient="linear(to-b, white, gray.300)"
            bgClip="text"
          >
            All-in-one solutions
          </Text>

          <Text fontSize="md" fontStyle="normal" color={"#888"}>
            Complete Integrated
          </Text>

          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 3}
            spacing={10}
            px={{ base: 4, md: 8 }}
            mt={10}
          >
            {menuData["Products"].map((item, index) => (
              <Box
                key={index}
                p={5}
                shadow="md"
                borderWidth="1px"
                borderColor={"#333333"}
                borderRadius={6}
              >
                <LinkBox
                  as="article"
                  maxW="sm"
                  _hover={{ backgroundColor: "#121212" }}
                  p="3"
                  rounded="md"
                >
                  <LinkOverlay href="#">
                    <Flex align="center">
                      {item.icon && (
                        <Box
                          borderRadius="full"
                          boxSize={10}
                          bg="#753CC5"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Image
                            src={item.icon}
                            boxSize={6}
                            filter="invert(100%)"
                          />
                        </Box>
                      )}
                      <Text fontWeight="extrabold" ml={2} fontSize={"xl"}>
                        {item.name}
                      </Text>
                    </Flex>
                  </LinkOverlay>
                  <Text
                    fontSize={"md"}
                    mt={5}
                    textAlign={"left"}
                    color={"#888888"}
                  >
                    {item.text}
                  </Text>
                </LinkBox>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
