import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  components: {
    Button: {
      variants: {
        pvWhite: (props) => ({
          bg: "#ffffff",
          color: "#000000",
          _hover: {
            bg: "#cccccc",
          },
        }),
        pvOrangeGr: (props) => ({
          color: "#fff",
          bgGradient: "linear(to-l, #ee1f6a, #f05a28)",
          _hover: {
            bgGradient: "linear(to-l, #ee1f6a, #e04f84)",
          },
          _active: {
            bgGradient: "linear(to-l, #ee1f6a, #f05a28)",
          },
        }),
        pvMenu: (props) => ({
          bg: "transparent",
          color: "#fff",
          _hover: {
            bg: "#333333",
          },
        }),
        pvBlack: (props) => ({
          bgColor: "transparent",
          border: "1px solid #333333",
          _hover: { borderColor: "#c1c1c1" },
        }),
        pvBlackSmall: (props) => ({
          bgColor: "#000000",
          border: "1px solid #333333",
          _hover: { borderColor: "#c1c1c1" },
          p: 1,
          lineHeight: "1.2",
          fontSize: "0.9rem",
        }),
        pvDarkGray: (props) => ({
          bgColor: "#0a0a0a",
          border: "1px solid #9c9c9c",
          _hover: { borderColor: "#c1c1c1" },
          fontFamily:
            "Monaco, 'Andale Mono', 'Ubuntu Mono', monospace, Consolas",
        }),
        pvPopupMenuButton: (props) => ({
          color: "#888",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: "black",
            color: "#fff",
          },
          _expanded: {
            bg: "black",
          },
        }),
        pvGray: (props) => ({
          bg: "trasparent",
          border: "2px solid #333",
          color: "#888",
          _hover: {
            bg: "trasparent",
            border: "2px solid #888",
          },
        }),
        pvBlackGray: (props) => ({
          bg: "#000",
          border: "2px solid #333",
          color: "#888",
          _hover: {
            bg: "trasparent",
            border: "2px solid #888",
          },
        }),
        pvDarkGrayF: (props) => ({
          bgColor: "#0a0a0a",
          border: "1px solid #9c9c9c",
          _hover: { borderColor: "#c1c1c1" },
        }),
      },
    },
    Link: {
      variants: {
        pvBlue: (props) => ({
          color: "#3291ff",
        }),
        pvWhiteBold: (props) => ({
          color: "#fff",
          fontWeight: "bold",
        }),
      },
    },
    Menu: {
      variants: {
        pvFilterMenu: (props) => ({
          parts: ["list", "item"],
          button: {},
          list: {
            bg: "black",
          },
          item: {
            bg: "black",
            _hover: {
              bg: "#111111",
              color: "#fff",
            },
            _focus: {
              bg: "#111111",
            },
          },
        }),
        pvActionMenu: (props) => ({
          parts: ["list", "item"],
          button: {},
          list: {
            bg: "#000",
            border: "1px solid #333333",
            color: "#fff",
          },
          item: {
            bg: "#000",
            border: "1px solid #333333",
            w: "95%",
            ml: 1.5,
            borderRadius: 6,
            _hover: {
              bgColor: "#323235",
            },
            mt: 2,
          },
        }),
      },
    },
    Modal: {
      variants: {
        pvBlackGray: (props) => ({
          parts: ["dialog", "footer", "header", "overlay", "body"],
          dialog: {
            bg: "#000",
            border: "2px solid #333",
          },
          footer: {
            bg: "#111",
            borderTop: "2px solid #333",
            justifyContent: "space-between",
          },
          header: {
            textAlign: "center",
          },
          overlay: {
            bg: "blackAlpha.600",
            backdropFilter: "blur(2px)",
          },
          body: {
            pb: 5,
          },
        }),
      },
    },
    List: {
      variants: {
        pvMethodList: (props) => ({
          item: {
            color: "#888",
            fontWeight: "bold",
            pointer: "cursor",
            _hover: {
              color: "#ccc",
            },
            fontFamily:
              "Monaco, 'Andale Mono', 'Ubuntu Mono', monospace, Consolas",
          },
        }),
      },
    },
  },
  fonts: {
    heading: "Hanken Grotesk",
    body: "Hanken Grotesk",
  },
  styles: {
    global: (props) => ({
      body: {
        backgroundColor: mode("#000", "#000")(props),
      },
    }),
  },
});

export default theme;
