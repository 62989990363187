import React, { useState } from "react";
import {
  Button,
  HStack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  useToast,
} from "@chakra-ui/react";

import { PvBoxSub } from "../../../../../custom/components";

import { useTokenOverview } from "../../../../../contexts/TokenOverviewContext";
import * as Helpers from "../../../../../services/Helpers";

import { AiOutlineFire, AiOutlineSend } from "react-icons/ai";

import TokenOverview from "../components/TokenOverview";

import { Utils } from "polyverse-sdk/dist/utils";
import { Contract } from "polyverse-sdk/dist/api/contract";

function Token({ contract, project }) {
  const toast = useToast();

  const { isRefreshTokenOverview, refreshTokenOverview } = useTokenOverview();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isModalTransferOpen, setIsModalTransferOpen] = useState(false);

  const [additionalTokens, setAdditionalTokens] = useState(0);
  const [transferTokens, setTransferTokens] = useState(0);
  const [transferAdress, setTransferAddress] = useState("");

  const handleMintClick = () => {
    setIsModalOpen(true);
    setAdditionalTokens(0);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleMintTokens = async () => {
    setIsLoading(true);

    try {
      const methodParams = {
        to: Helpers.getUserAddress(),
        amount: Utils.toWeiWithDecimal(additionalTokens, 18),
      };
      await Contract.execute(
        contract.network,
        contract.contract,
        "mint",
        methodParams
      );

      toast({
        title: "Success",
        description: "Tokens minted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);

    handleCloseModal();
    refreshTokenOverview();
  };

  const handleTransferClick = () => {
    setIsModalTransferOpen(true);
    setTransferTokens(0);
    setTransferAddress("");
  };

  const handleCloseModalTransfer = () => {
    setIsModalTransferOpen(false);
  };

  const handleTransferTokens = async () => {
    setIsLoading(true);

    try {
      const methodParams = {
        to: transferAdress,
        amount: Utils.toWeiWithDecimal(transferTokens, 18),
      };

      await Contract.execute(
        contract.network,
        contract.contract,
        "transfer",
        methodParams
      );

      toast({
        title: "Success",
        description: "Tokens transferred",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);

    handleCloseModalTransfer();
    refreshTokenOverview();
  };

  return (
    <>
      <>
        <TokenOverview contract={contract} project={project} />

        <Heading as="h4" size="md" p={3} alignSelf="flex-start">
          Controls
        </Heading>
        <PvBoxSub>
          <HStack spacing={2}>
            <Button
              leftIcon={<AiOutlineSend />}
              variant={"pvDarkGrayF"}
              onClick={handleTransferClick}
            >
              Transfer
            </Button>
            <Button
              leftIcon={<AiOutlineFire />}
              variant={"pvDarkGrayF"}
              hidden={!contract.config.mintable}
              onClick={handleMintClick}
            >
              Mint
            </Button>
            <Button
              leftIcon={<AiOutlineFire />}
              variant={"pvDarkGrayF"}
              hidden={!contract.config.burnable}
            >
              Burn
            </Button>
          </HStack>
        </PvBoxSub>
      </>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        size="md"
        isCentered
        variant={"pvBlackGray"}
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mint Additional Tokens</ModalHeader>
          <ModalBody>
            <Input
              onChange={(e) => setAdditionalTokens(e.target.value)}
              placeholder="Number of additional tokens as ether unit"
              type="number"
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModal} variant={"pvGray"}>
              Cancel
            </Button>
            <Button
              variant={"pvBlackGray"}
              onClick={handleMintTokens}
              isDisabled={additionalTokens === 0 || additionalTokens === ""}
              isLoading={isLoading}
            >
              Mint
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isModalTransferOpen}
        onClose={handleCloseModalTransfer}
        size="md"
        isCentered
        variant={"pvBlackGray"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transfer Tokens</ModalHeader>
          <ModalBody>
            <Input
              onChange={(e) => setTransferTokens(e.target.value)}
              placeholder="Number of additional tokens as ether unit"
              type="number"
            />
            <Input
              onChange={(e) => setTransferAddress(e.target.value)}
              placeholder="To Address"
              type="input"
              mt={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModalTransfer} variant={"pvGray"}>
              Cancel
            </Button>
            <Button
              variant={"pvBlackGray"}
              onClick={handleTransferTokens}
              isDisabled={transferAdress === ""}
              isLoading={isLoading}
            >
              Transfer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Token;
