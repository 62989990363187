import DrawerMenuBody from "./DrawerMenuBody";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
} from "@chakra-ui/react";

function DrawerMenu(props) {
  return (
    <Drawer
      placement="right"
      onClose={props.onClose} // Değiştirilen prop ismi
      isOpen={props.isOpen} // Değiştirilen prop ismi
    >
      <DrawerOverlay />
      <DrawerContent bgColor={"#111111"}>
        <DrawerCloseButton />
        <DrawerHeader></DrawerHeader>
        <DrawerBody>
          <DrawerMenuBody showText={true} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerMenu;
