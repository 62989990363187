import React from "react";
import { Box, Heading, Text, useMediaQuery } from "@chakra-ui/react";

function Updates() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [isSmallerThan820] = useMediaQuery("(max-width: 820px)");

  return (
    <Box w={!isSmallerThan820 ? "20%" : "90%"} textAlign="left" pl={35} mt={isSmallerThan820 && 5}>
      <Box>
        <Heading as="h4" size="md" mb={5}>
          Updates
        </Heading>
        <Box display="flex">
          <Box borderRight="3px solid #2F6EEB" mr={2}>
            &nbsp;
          </Box>
          <Box mb={2}>
            <Text fontSize="md">Login with Email in Dashboard</Text>
            <Text fontSize="sm" color="#888888">
              about 12 hours ago
            </Text>
          </Box>
        </Box>
        <Box display="flex">
          <Box borderRight="3px solid #2F6EEB" mr={2}>
            &nbsp;
          </Box>
          <Box mb={2}>
            <Text fontSize="md">Login with Email in Dashboard</Text>
            <Text fontSize="sm" color="#888888">
              about 12 hours ago
            </Text>
          </Box>
        </Box>
      </Box>

      <Box mt={10}>
        <Heading as="h4" size="md" mb={5}>
          Upcoming Features
        </Heading>
        <Box display="flex">
          <Box borderRight="3px solid #B52519" mr={2}>
            &nbsp;
          </Box>
          <Box mb={2}>
            <Text fontSize="md">Login with Email in Dashboard</Text>
            <Text fontSize="sm" color="#888888">
              about 12 hours ago
            </Text>
          </Box>
        </Box>
        <Box display="flex">
          <Box borderRight="3px solid #B52519" mr={2}>
            &nbsp;
          </Box>
          <Box mb={2}>
            <Text fontSize="md">Login with Email in Dashboard</Text>
            <Text fontSize="sm" color="#888888">
              about 12 hours ago
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Updates;
