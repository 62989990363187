import React, { useState, useEffect } from "react";

import SettingForm from "../components/SettingForm";

import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";

import {
  SimpleGrid,
  useMediaQuery,
  Flex,
  Button,
  Box,
  VStack,
  Divider,
  Text,
  FormLabel,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import {
  PvBoxSub,
  PvBoxSubTransparent,
  PvBoxLeftMenu,
} from "../../../../../custom/components";

import { Prize } from "polyverse-sdk/dist/api/prize";

function Rewarder({ contractItem, projectItem }) {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [dataModalText, setDataModalText] = useState("");
  const [dataModalAction, setDataModalAction] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const { projectId } = useParams();
  const { contract } = useParams();
  const { menu } = useParams();
  let { section } = useParams();

  if (section === undefined) {
    navigate(`${window.location.pathname}/settings`);
  }

  const [loadedSections, setLoadedSections] = useState({
    settings: false,
    finalize: false,
  });

  const markSectionLoadedState = (section, state) => {
    setLoadedSections((prevSections) => ({
      ...prevSections,
      [section]: state,
    }));
  };

  const [isDataPrizesLoaded, setIsDataPrizesLoaded] = useState(false);
  const [dataPrizes, setDataPrizes] = useState({
    estimated: 0,
    finalized: 0,
    onchain: 0,
  });

  const loadPrizes = async () => {
    try {
      setIsDataPrizesLoaded(false);

      const [estimatedData, finalizedData, onchainData] = await Promise.all([
        Prize.offChainList(
          contractItem.network,
          contractItem.contract,
          "point",
          1
        ),
        Prize.offChainList(
          contractItem.network,
          contractItem.contract,
          "point",
          2
        ),
        Prize.offChainList(
          contractItem.network,
          contractItem.contract,
          "point",
          2
        ),
      ]);

      const estimatedTotal = estimatedData.reduce(
        (total, prize) => total + parseInt(prize.amount),
        0
      );
      const finalizedTotal = finalizedData.reduce(
        (total, prize) => total + parseInt(prize.amount),
        0
      );
      const onchainTotal = onchainData.reduce(
        (total, prize) => total + parseInt(prize.amount),
        0
      );

      setDataPrizes({
        ...dataPrizes,
        estimated: estimatedTotal,
        finalized: finalizedTotal,
        onchain: onchainTotal,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error loading prizes.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    } finally {
      setIsDataPrizesLoaded(true);
    }
  };

  useEffect(() => {
    if (section === "finalize") {
      loadPrizes();
    }
  }, [section]);

  const handleFinalize = async () => {
    setIsLoading(true);

    try {
      if (dataModalAction === "Finalize") {
        await Prize.finalizePrizes(
          contractItem.network,
          contractItem.contract,
          "point",
          1,
          "RTT",
          1
        );

        onClose();
        loadPrizes();
      }

      if (dataModalAction === "MoveToOnChain") {
        await Prize.finalizePrizes(
          contractItem.network,
          contractItem.contract,
          "point",
          2,
          "RTT",
          1
        );

        await Prize.finalizePrizes(
          contractItem.network,
          contractItem.contract,
          "point",
          3,
          "RTT",
          1
        );

        onClose();
        loadPrizes();
      }
    } catch (error) {
      let errMsg = "Error finalizing prizes.";
      if (error.message.includes("Attempted to divide by zero")) {
        errMsg =
          "Error finalizing prizes. Please check if you defined country ratios.";
      }

      toast({
        title: "Error",
        description: errMsg,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SimpleGrid
        columns={2}
        spacing={0}
        w="100%"
        mt={5}
        templateColumns={isIphone ? "100%" : "20% 80%"}
      >
        <PvBoxSub w={isIphone ? "100%" : "98%"} px={2}>
          <Flex direction="column">
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/settings`}
              isActive={section === "settings"}
            >
              Settings
            </PvBoxLeftMenu>
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/finalize`}
              isActive={section === "finalize"}
            >
              Finalize Prizes
            </PvBoxLeftMenu>
          </Flex>
        </PvBoxSub>
        <PvBoxSubTransparent p={0} pl={5} mt={isIphone ? 4 : 0}>
          <PvBoxSubTransparent p={0} isHidden={section !== "settings"}>
            <SettingForm
              inputs={[
                {
                  type: "string",
                  name: "addr",
                  label: "Reward Token",
                  description: "Set or change reward token contract address.",
                  valueMethod: "REWARD_TOKEN",
                },
              ]}
              contract={contractItem}
              saveMethod="setRewardToken"
              caption="Update"
              mb={5}
              sectionName={"settings"}
            />

            <SettingForm
              inputs={[
                {
                  type: "toggle",
                  name: "rewardingStatus",
                  label: "Is Rewarding Paused?",
                  valueMethod: "IsRewardingPaused",
                  toogleMethodTrue: "unpauseRewarding",
                  toogleMethodFalse: "pauseRewarding",
                },
              ]}
              contract={contractItem}
              caption="Update"
              mb={5}
              sectionName={"settings"}
            />

            <SettingForm
              inputs={[
                {
                  type: "tuple",
                  name: "_countries",
                  label: "Country Ratios",
                  description: "Change country Ratios",
                  valueMethod: "getCountries",
                  solidityType: "tuple[]",
                },
              ]}
              contract={contractItem}
              saveMethod="addCountries"
              caption="Update"
              mb={5}
              sectionName={"settings"}
              example={"TR,1\nEN,2\nUS,3"}
            />
          </PvBoxSubTransparent>

          <PvBoxSubTransparent p={0} isHidden={section !== "finalize"}>
            <Box
              w={"100%"}
              border={"1px solid #333"}
              borderRadius={6}
              bgColor={"#0a0a0a"}
              mt={0}
            >
              <VStack spacing={4}>
                <Box p={5} w={"100%"} fontWeight={"bold"}>
                  <FormControl>
                    <FormLabel fontSize={"lg"}>Estimated Prizes</FormLabel>
                    <Text mb={2} fontSize={"sm"} fontWeight={"normal"}>
                      You can finalize the estimated prizes by clicking
                      'Finalize Prizes' button.
                    </Text>
                  </FormControl>
                  <Text fontSize={"xl"}>{dataPrizes.estimated}</Text>
                </Box>
                <Divider />
                <Box
                  pb={4}
                  pr={5}
                  w={"100%"}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="pvBlackGray"
                    onClick={() => {
                      onOpen();
                      setDataModalText(
                        "Are you sure you want to finalize the prizes?"
                      );
                      setDataModalAction("Finalize");
                    }}
                    isLoading={!isDataPrizesLoaded}
                    isDisabled={dataPrizes.estimated === 0}
                  >
                    Finalize Prizes
                  </Button>
                </Box>
              </VStack>
            </Box>

            <Box
              w={"100%"}
              border={"1px solid #333"}
              borderRadius={6}
              bgColor={"#0a0a0a"}
              mt={0}
            >
              <VStack spacing={4}>
                <Box p={5} w={"100%"} fontWeight={"bold"}>
                  <FormControl>
                    <FormLabel fontSize={"lg"}>Finalized Prizes</FormLabel>
                    <Text mb={2} fontSize={"sm"} fontWeight={"normal"}>
                      You can move to on-chain the finalized prizes by clicking
                      'Finalize Prizes' button.
                    </Text>
                  </FormControl>
                  <Text fontSize={"xl"}>{dataPrizes.finalized}</Text>
                </Box>
                <Divider />
                <Box
                  pb={4}
                  pr={5}
                  w={"100%"}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="pvBlackGray"
                    onClick={() => {
                      onOpen();
                      setDataModalText(
                        "Are you sure you want to move to on-chain prizes?"
                      );
                      setDataModalAction("MoveToOnChain");
                    }}
                    isLoading={!isDataPrizesLoaded}
                    isDisabled={dataPrizes.finalized === 0}
                  >
                    Move to On-Chain
                  </Button>
                </Box>
              </VStack>
            </Box>
          </PvBoxSubTransparent>
        </PvBoxSubTransparent>
      </SimpleGrid>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        variant={"pvBlackGray"}
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>{dataModalText}</ModalBody>

          <ModalFooter>
            <Button variant={"pvBlackGray"} mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              variant={"pvGray"}
              onClick={handleFinalize}
              isLoading={isLoading}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Rewarder;
