import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";

import { System } from "polyverse-sdk/dist/api/system";
import { Project } from "polyverse-sdk/dist/api/project";

import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  useMediaQuery,
  Heading,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  Link,
} from "@chakra-ui/react";

function Index() {
  const { projectId } = useParams();

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const [deployableContracts, setDeployableContracts] = useState([]);
  const [isDeployableContractsLoading, setIsDeployableContractsLoading] =
    useState([]);
  const [projectData, setProjectData] = useState({});

  useEffect(() => {
    const fetchDeployableContracts = async () => {
      setIsDeployableContractsLoading(true);

      try {
        const result = await System.deployableContracts();
        setDeployableContracts(result);
      } catch (error) {
        console.error("Failed to fetch Deployable Contracts:", error);
      }

      setIsDeployableContractsLoading(false);
    };

    const fetchProject = async () => {
      try {
        const response = await Project.project(parseInt(projectId));
        setProjectData(response);
      } catch (error) {
        console.error("Failed to fetch system statistics:", error);
      }
    };

    fetchProject();
    fetchDeployableContracts();
  }, []);

  return (
    <>
      <Header />

      <Box p={10} bgColor={"#111111"}>
        <Flex
          direction="column"
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"100%"} alignItems="left">
            <Heading as="h2" size="xl">
              Explore
            </Heading>
            <Heading as="h5" size="sm" mt={3} color={"#888"}>
              Explore the PolyVerse's premium contracts - all available for
              deployment at a single click
            </Heading>
            {projectId && (
              <Alert
                status="info"
                width={isIpad ? "92%" : isIphone ? "100%" : "60%"}
                mx={"auto"}
                mt={4}
              >
                <AlertIcon />
                Choose a contract to deploy to your project{" "}
                {projectData?.name && (
                  <>
                    {" "}
                    named &nbsp;
                    <Link
                      textDecoration={"underline"}
                      as={RouterLink}
                      to={`/dashboard/project/${projectId}/contracts`}
                    >
                      {projectData?.name}
                    </Link>
                  </>
                )}
              </Alert>
            )}
          </Box>

          {isDeployableContractsLoading && (
            <Flex w="100%" justifyContent="center" alignItems="center" mt={10}>
              <Spinner size="xl" color="#753CC5" />
            </Flex>
          )}

          {!isDeployableContractsLoading && (
            <SimpleGrid
              columns={isIphone ? 1 : 3}
              spacing={10}
              px={{ base: 4, md: 8 }}
              mt={10}
            >
              {deployableContracts.map((item, index) => (
                <Box
                  key={index}
                  p={5}
                  shadow="md"
                  borderWidth="1px"
                  borderColor={"#333333"}
                  borderRadius={6}
                >
                  <LinkBox
                    as="article"
                    maxW="sm"
                    minH={140}
                    _hover={{ backgroundColor: "#121212" }}
                    p="3"
                    rounded="md"
                  >
                    <LinkOverlay href="#" cursor="default">
                      <Flex align="center">
                        <Box
                          borderRadius="full"
                          boxSize={10}
                          bg="#753CC5"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        ></Box>
                        <Text fontWeight="extrabold" ml={2} fontSize={"xl"}>
                          {item.name}
                        </Text>
                      </Flex>
                    </LinkOverlay>
                    <Text
                      fontSize="md"
                      textAlign="left"
                      color="#888888"
                      noOfLines={3}
                      minH={38}
                      mt={5}
                    >
                      {item.description}
                    </Text>
                  </LinkBox>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text ml={3} fontWeight="bold" fontSize="sm">
                      PolyVerse
                    </Text>
                    {projectId && (
                      <Button
                        variant={"pvWhite"}
                        px={30}
                        to={
                          projectId
                            ? `/dashboard/explore/contract/${item.id}/deploy/${projectId}`
                            : `/dashboard/explore/contract/${item.id}/deploy`
                        }
                        as={RouterLink}
                      >
                        Deploy
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default Index;
