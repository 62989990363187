import { Box as ChakraBox } from "@chakra-ui/react";

export const PvBox = (props) => (
  <ChakraBox
    border="1px solid #333333"
    borderRadius="6px"
    w="100%"
    p={5}
    my={5}
    {...props}
  />
);

export const PvBoxSub = ({ isHidden, ...props }) => (
  <ChakraBox
    border="1px solid #333333"
    borderRadius="6px"
    w="100%"
    p={5}
    {...(isHidden && {
      display: "none",
    })}
    {...props}
  />
);

export const PvBoxSubTransparent = ({ isHidden, ...props }) => (
  <ChakraBox
    borderRadius="6px"
    w="100%"
    p={5}
    {...(isHidden && {
      display: "none",
    })}
    {...props}
  />
);

export const PvBoxLeftMenu = ({ isActive, ...props }) => (
  <ChakraBox
    bg="transparent"
    w="100%"
    color="#888"
    fontWeight="700"
    cursor="pointer"
    _hover={{
      color: "#fff",
      backgroundColor: "#333",
    }}
    py={2}
    px={3}
    mt={2}
    borderRadius={6}
    textAlign="left"
    {...(isActive && {
      color: "#fff",
      backgroundColor: "#333",
    })}
    {...props}
  />
);
