import React, { useState } from "react";

import { Project } from "polyverse-sdk/dist/api/project";

import { useProjects } from "../../../contexts/ProjectsContext";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";

const ProjectMoveToTrashModal = ({ isOpen, onClose, project }) => {
  const { isRefreshProjectList, refreshProjects } = useProjects();
  const toast = useToast();

  const [isTrashing, setIsTrashing] = useState(false);

  const handleMoveToTrash = async () => {
    setIsTrashing(true);
    try {
      await Project.trash(project.id, true);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      refreshProjects();
      setIsTrashing(false);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      variant={"pvBlackGray"}
      motionPreset="none"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Move to Trash</ModalHeader>
        <ModalBody>
          <Text>
            Are you sure, You want move to trash your project? You can undo this
            action afterwards from trash.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant={"pvGray"}>
            Close
          </Button>
          <Button
            onClick={handleMoveToTrash}
            variant={"pvBlackGray"}
            isLoading={isTrashing}
          >
            Move to Trash
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectMoveToTrashModal;
