import React, { createContext, useState, useMemo, useCallback } from "react";

export const WalletBalanceContext = createContext();

export const WalletBalanceProvider = ({ children }) => {
  const [isRefreshWalletBalance, setIsRefreshWalletBalance] = useState(false);

  const refreshWallet = useCallback(() => {
    setIsRefreshWalletBalance((prevValue) => !prevValue);
  }, []);

  const value = useMemo(
    () => ({ isRefreshWalletBalance, refreshWallet }),
    [isRefreshWalletBalance, refreshWallet]
  );

  return (
    <WalletBalanceContext.Provider value={value}>
      {children}
    </WalletBalanceContext.Provider>
  );
};

export function useWalletBalance() {
  const context = React.useContext(WalletBalanceContext);
  if (context === undefined) {
    throw new Error(
      "useWalletBalance must be used within a WalletBalanceProvider"
    );
  }
  return context;
}
