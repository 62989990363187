import { Link as RouterLink } from "react-router-dom";
import React from "react";

import ImgDBLeft from "../../static/db-left.png";
import ImgDBRight from "../../static/db-right.png";
import menuData from "../../datas/menuData.json";
import kfData from "../../datas/keyFeaturesData.json";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import CardSlogan from "../../components/CardSlogan";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  return (
    <>
      <Header />

      <Box mt={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Flex flexDirection="column" alignItems="center">
              <Heading
                as="h2"
                size="3xl"
                noOfLines={1}
                bgGradient="linear(to-b, white, #888888)"
                bgClip="text"
              >
                {menuData["Products"][4].name}
              </Heading>

              <Heading as="h4" size="md" color={"#888888"} my={5}>
                {menuData["Products"][4].text}
              </Heading>

              <Box bg="#753CC5" p={2} borderRadius="full" boxSize={50}>
                <Image src={menuData["Products"][4].icon} />
              </Box>

              <Box mt={8}>
                {/*                 <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/cs"}
                >
                  Learn More
                </Button> */}
                <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/getearlyaccess"}
                >
                  Get Early Access
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mt={10}>
        <Flex
          w="80%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 2}
            spacing={10}
            px={{ base: 4, md: 8 }}
          >
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBLeft} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Experience Unparalleled Speed: Optimize Your Decentralized
                Storage
              </Text>
            </Box>

            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBRight} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Seamless Storage Solutions: Unify Your Decentralized Storage
                Access Swiftly and Securely
              </Text>
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>

      <CardSlogan
        title="REVOLUTIONIZE YOUR DATA INTERACTIONS"
        text="Transform Your Workflow with our Decentralized Storage Solution"
        description={{
          __html: ` Our decentralized storage solution integrates a rich array of features, making it a top choice for developers aiming for swift, secure data interactions. It ensures a high-speed data transfer and retrieval by harmonizing with your chosen storage provider through a unified API. In addition to its high-speed capabilities, our solution provides secure data transactions within the blockchain ecosystem. Embrace a future where seamless, secure, and fast decentralized storage becomes your competitive edge, and complex data handling processes are a thing of the past.`,
        }}
      />

      <Box mt={35}>
        <Flex
          w="100%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={5}
          mb={20}
        >
          <Heading
            as="h4"
            size="xl"
            noOfLines={1}
            bgGradient="linear(to-b, #753cc5, #fff)"
            bgClip="text"
          >
            Key Features
          </Heading>

          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 3}
            spacing={10}
            px={{ base: 4, md: 8 }}
            mt={10}
          >
            {kfData.map((item, index) => (
              <React.Fragment key={index}>
                {item.storage.map((text, i) => (
                  <Box
                    key={`${index}-${i}`}
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    borderColor={"#333333"}
                    borderRadius={6}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text key={i} mt={2} fontSize={"lg"} color={"#888888"}>
                      {text}
                    </Text>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </SimpleGrid>
        </Flex>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
