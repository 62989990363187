import React, { useState, useEffect } from "react";

import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";

import { useWalletBalance } from "../../../../../contexts/WalletBalanceContext";

import { Formik, Field, Form, ErrorMessage } from "formik";

import {
  Text,
  SimpleGrid,
  Flex,
  useMediaQuery,
  Heading,
  HStack,
  ButtonGroup,
  Button,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  IconButton,
  Box,
  Tooltip,
  TableContainer,
} from "@chakra-ui/react";

import { BiSolidPlusSquare } from "react-icons/bi";
import {
  ArrowForwardIcon,
  RepeatIcon,
  EditIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

import { Contract } from "polyverse-sdk/dist/api/contract";
import { Utils } from "polyverse-sdk/dist/utils";

import {
  PvBoxSub,
  PvBoxSubTransparent,
  PvBoxLeftMenu,
} from "../../../../../custom/components";

import CopyAddressButton from "../../../components/CopyAddressButton";

function ProductStore({ contractItem, projectItem }) {
  const { isRefreshWalletBalance, refreshWallet } = useWalletBalance();

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setRefreshing] = useState(false);
  const [storeData, setStoreData] = useState({
    purchasedProducts: [],
    products: [],
    purchasedProductsDetails: [],
    contents: [],
  });

  const [selectedVariants, setSelectedVariants] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedId, setSelectedId] = useState(-1);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContent, setIsOpenContent] = useState(false);
  const [isOpenVariants, setIsOpenVariants] = useState(false);
  const [isAddVariantOpen, setIsAddVariantOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpenVariants = () => {
    setIsOpenVariants(true);
  };

  const onCloseVariants = () => {
    setIsOpenVariants(false);
  };

  const onAddVariantOpen = () => {
    setIsAddVariantOpen(true);
  };

  const onAddVariantClose = () => {
    setIsAddVariantOpen(false);
  };

  const onOpenContent = () => {
    setIsOpenContent(true);
  };

  const onCloseContent = () => {
    setIsOpenContent(false);
  };

  const navigate = useNavigate();
  const toast = useToast();

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const { projectId } = useParams();
  const { contract } = useParams();
  const { menu } = useParams();
  let { section } = useParams();

  const loadData = async () => {
    setIsLoading(true);

    await fetchData();

    setIsLoading(false);
  };

  const fetchData = async () => {
    setRefreshing(true);

    try {
      const products = await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "getProducts",
        null,
        "json"
      );

      const purchasedProducts = await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "getPurchasedProducts",
        null,
        "json"
      );

      const contents = await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "getContents",
        null,
        "json"
      );

      const purchasedProductsDetails = purchasedProducts.data.map(
        (purchasedProduct) => {
          const productDetails = products.data.find(
            (product) => product.id === purchasedProduct.productId
          );
          return {
            ...purchasedProduct,
            productDetails,
          };
        }
      );

      setStoreData({
        products: products.data,
        purchasedProducts: purchasedProducts.data,
        purchasedProductsDetails: purchasedProductsDetails,
        contents: contents.data,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setRefreshing(false);
  };

  useEffect(() => {
    loadData();
  }, [contractItem.contract]);

  if (section === undefined) {
    navigate(`${window.location.pathname}/products`);
  }

  const showVariants = async (productId) => {
    const selectedProduct = storeData.products.find(
      (product) => product.id === productId
    );

    setSelectedVariants(selectedProduct.variants || []);
    setSelectedId(productId);

    onOpenVariants();
  };

  const editProduct = async (productId) => {
    const foundProduct = storeData.products.find(
      (product) => product.id === productId
    );

    setSelectedProduct(foundProduct);

    onOpen();
  };

  const editContent = async (content) => {
    setSelectedContent(content);
    onOpenContent();
  };

  const deleteContent = async (contentId) => {
    try {
      await Contract.execute(
        contractItem.network,
        contractItem.contract,
        "deleteContent",
        {
          _contentId: contentId,
        }
      );

      await fetchData();

      refreshWallet();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /* const editVariant = (variant) => () => {
    setSelectedVariant(variant);
    onAddVariantOpen();
  };
 */
  return (
    <>
      <SimpleGrid
        columns={2}
        spacing={0}
        w="100%"
        mt={5}
        templateColumns={isIphone ? "100%" : "20% 80%"}
      >
        <PvBoxSub w={isIphone ? "100%" : "98%"} px={2}>
          <Flex direction="column">
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/products`}
              isActive={section === "products"}
            >
              Products
            </PvBoxLeftMenu>
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/purchased-products`}
              isActive={section === "purchased-products"}
            >
              Purchased Products
            </PvBoxLeftMenu>
            <PvBoxLeftMenu
              as={RouterLink}
              to={`/dashboard/project/${projectId}/contract/${contract}/${menu}/contents`}
              isActive={section === "contents"}
            >
              Contents
            </PvBoxLeftMenu>
          </Flex>
        </PvBoxSub>
        <PvBoxSubTransparent p={0} pl={isIphone ? 0 : 5} mt={isIphone ? 4 : 0}>
          <PvBoxSub isHidden={section !== "products"}>
            {isLoading && (
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                my={10}
              >
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}

            {!isLoading && (
              <>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Heading as="h4" size="md" display="inline">
                    Products
                  </Heading>
                  <HStack spacing={2} overflowX="auto">
                    <ButtonGroup variant={"pvDarkGrayF"} direction="column">
                      <Button
                        leftIcon={<BiSolidPlusSquare color="#fff" />}
                        onClick={onOpen}
                      >
                        Add Product
                      </Button>
                    </ButtonGroup>
                  </HStack>
                </Flex>

                {storeData.products.length === 0 && (
                  <Text>You haven't added any products yet.</Text>
                )}

                {storeData.products.length > 0 && (
                  <>
                    <Box
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                    >
                      <Table variant="unstyled" p={0}>
                        <Thead bg="black" h={11}>
                          <Tr>
                            <Th color="#888">ID</Th>
                            <Th color="#888">Name</Th>
                            <Th color="#888">Image</Th>
                            <Th color="#888">Category</Th>
                            <Th color="#888">Description</Th>
                            <Th color="#888" textAlign={"center"}>
                              <IconButton
                                icon={<RepeatIcon />}
                                size={"xs"}
                                onClick={fetchData}
                                hidden={isRefreshing}
                              />
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {isRefreshing ? (
                            <Tr>
                              <Td colSpan={6} textAlign="center">
                                <Spinner size="sm" />
                              </Td>
                            </Tr>
                          ) : (
                            storeData.products.map((product, index) => (
                              <Tr
                                key={index}
                                _hover={{ bgColor: "#232323" }}
                                cursor="pointer"
                              >
                                <Td>{product.id}</Td>
                                <Td>{product.name}</Td>
                                <Td>
                                  <Image
                                    src={product.image}
                                    maxBlockSize={50}
                                  />
                                </Td>
                                <Td>{product.category}</Td>
                                <Td
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                                  maxW={150}
                                >
                                  {product.description}
                                </Td>
                                <Td>
                                  <HStack spacing={2}>
                                    <Tooltip label="Edit">
                                      <IconButton
                                        size={"xs"}
                                        icon={<EditIcon />}
                                        onClick={() => editProduct(product.id)}
                                      />
                                    </Tooltip>
                                    <Tooltip label="Variants">
                                      <IconButton
                                        size={"xs"}
                                        icon={<ArrowForwardIcon />}
                                        onClick={() => showVariants(product.id)}
                                      />
                                    </Tooltip>
                                  </HStack>
                                </Td>
                              </Tr>
                            ))
                          )}
                        </Tbody>
                      </Table>
                    </Box>
                  </>
                )}
              </>
            )}
          </PvBoxSub>

          <PvBoxSub isHidden={section !== "purchased-products"}>
            {isLoading && (
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                my={10}
              >
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}

            {!isLoading && (
              <>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Heading as="h4" size="md" display="inline">
                    Purchased Products
                  </Heading>
                </Flex>

                {storeData.purchasedProducts.length === 0 && (
                  <Text>You have not purchased any products yet.</Text>
                )}

                {storeData.purchasedProducts.length > 0 && (
                  <>
                    <Box
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                    >
                      <Table variant="unstyled" p={0}>
                        <Thead bg="black" h={11}>
                          <Tr>
                            <Th color="#888">ID</Th>
                            <Th color="#888">Name</Th>
                            <Th color="#888">Image</Th>
                            <Th color="#888">Category</Th>
                            <Th color="#888">Variant</Th>
                            <Th color="#888">Buyer</Th>
                            <Th color="#888" textAlign={"center"}>
                              <IconButton
                                icon={<RepeatIcon />}
                                size={"xs"}
                                onClick={fetchData}
                                hidden={isRefreshing}
                              />
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {isRefreshing ? (
                            <Tr>
                              <Td colSpan={6} textAlign="center">
                                <Spinner size="sm" />
                              </Td>
                            </Tr>
                          ) : (
                            storeData.purchasedProductsDetails.map(
                              (product, index) => (
                                <Tr
                                  key={index}
                                  _hover={{ bgColor: "#232323" }}
                                  cursor="pointer"
                                >
                                  <Td>{product.productDetails.id}</Td>
                                  <Td>{product.productDetails.name}</Td>
                                  <Td>
                                    <Image
                                      src={product.productDetails.image}
                                      maxBlockSize={50}
                                    />
                                  </Td>
                                  <Td>{product.productDetails.category}</Td>
                                  <Td>{product.variantId}</Td>
                                  <Td>
                                    <CopyAddressButton
                                      address={product.buyer}
                                    />
                                  </Td>
                                  <Td></Td>
                                </Tr>
                              )
                            )
                          )}
                        </Tbody>
                      </Table>
                    </Box>
                  </>
                )}
              </>
            )}
          </PvBoxSub>

          <PvBoxSub isHidden={section !== "contents"}>
            {isLoading && (
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                my={10}
              >
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}

            {!isLoading && (
              <>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Heading as="h4" size="md" display="inline">
                    Contents
                  </Heading>
                  <HStack spacing={2} overflowX="auto">
                    <ButtonGroup variant={"pvDarkGrayF"} direction="column">
                      <Button
                        leftIcon={<BiSolidPlusSquare color="#fff" />}
                        onClick={onOpenContent}
                      >
                        Add Content
                      </Button>
                    </ButtonGroup>
                  </HStack>
                </Flex>

                {storeData.contents.length === 0 && (
                  <Text>You have no content added yet.</Text>
                )}

                {storeData.contents.length > 0 && (
                  <>
                    <Box
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                    >
                      <Table variant="unstyled" p={0}>
                        <Thead bg="black" h={11}>
                          <Tr>
                            <Th color="#888">Category</Th>
                            <Th color="#888">Title</Th>
                            <Th color="#888">Text</Th>
                            <Th></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {isRefreshing ? (
                            <Tr>
                              <Td colSpan={3} textAlign="center">
                                <Spinner size="sm" />
                              </Td>
                            </Tr>
                          ) : (
                            storeData.contents.map((content, index) => (
                              <Tr
                                key={index}
                                _hover={{ bgColor: "#232323" }}
                                cursor="pointer"
                              >
                                <Td>{content.category}</Td>
                                <Td>{content.title}</Td>
                                <Td
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                                  maxW={150}
                                >
                                  {content.text}
                                </Td>
                                <Td>
                                  <HStack spacing={2}>
                                    <Tooltip label="Edit">
                                      <IconButton
                                        size={"xs"}
                                        icon={<EditIcon />}
                                        onClick={() => editContent(content)}
                                      />
                                    </Tooltip>

                                    <Tooltip label="Delete">
                                      <IconButton
                                        size={"xs"}
                                        icon={<DeleteIcon />}
                                        onClick={() =>
                                          deleteContent(content.id)
                                        }
                                      />
                                    </Tooltip>
                                  </HStack>
                                </Td>
                              </Tr>
                            ))
                          )}
                        </Tbody>
                      </Table>
                    </Box>
                  </>
                )}
              </>
            )}
          </PvBoxSub>
        </PvBoxSubTransparent>
      </SimpleGrid>
      {/* Modal for add/edit product */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        isCentered
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              _name: selectedProduct ? selectedProduct.name : "",
              _category: selectedProduct ? selectedProduct.category : "",
              _image: selectedProduct ? selectedProduct.image : "",
              _note: selectedProduct ? selectedProduct.note : "",
              _description: selectedProduct ? selectedProduct.description : "",
              _country: selectedProduct ? selectedProduct.country : "",
              _redeem: selectedProduct ? selectedProduct.redeem : "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values._name) {
                errors._name = "Required";
              }
              if (!values._category) {
                errors._category = "Required";
              }
              if (!values._image) {
                errors._image = "Required";
              }
              if (!values._note) {
                errors._note = "Required";
              }
              if (!values._description) {
                errors._description = "Required";
              }
              if (!values._country) {
                errors._country = "Required";
              }
              if (!values._redeem) {
                errors._redeem = "Required";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const methodParamsAddProduct = {};

                if (selectedProduct != null) {
                  methodParamsAddProduct["_id"] = selectedProduct.id;
                }

                for (const [key, value] of Object.entries(values)) {
                  methodParamsAddProduct[key] = value;
                }

                let methodName = "addProduct";
                if (selectedProduct != null) {
                  methodName = "editProduct";
                }

                await Contract.execute(
                  contractItem.network,
                  contractItem.contract,
                  methodName,
                  methodParamsAddProduct
                );
                setSubmitting(false);
                onClose();

                setSelectedProduct(null);

                refreshWallet();

                await fetchData();
              } catch (error) {
                toast({
                  title: "Error",
                  description: error.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
                setSubmitting(false);
              }
            }}
          >
            {(props) => (
              <Form>
                <ModalHeader>
                  {selectedProduct == null ? "Add" : "Edit"} Product
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl
                    mt={4}
                    isInvalid={props.errors._name && props.touched._name}
                  >
                    <FormLabel>Name</FormLabel>
                    <Field name="_name" as={Input} />
                    <ErrorMessage
                      name="_name"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={
                      props.errors._category && props.touched._category
                    }
                  >
                    <FormLabel>Category</FormLabel>
                    <Field name="_category" as={Input} />
                    <ErrorMessage
                      name="_category"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={props.errors._image && props.touched._image}
                  >
                    <FormLabel>Image URL</FormLabel>
                    <Field name="_image" as={Input} />
                    <ErrorMessage
                      name="_image"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={props.errors._note && props.touched._note}
                  >
                    <FormLabel>Note</FormLabel>
                    <Field name="_note" as={Textarea} />
                    <ErrorMessage
                      name="_note"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={
                      props.errors._description && props.touched._description
                    }
                  >
                    <FormLabel>Description</FormLabel>
                    <Field name="_description" as={Textarea} />
                    <ErrorMessage
                      name="_description"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={props.errors._country && props.touched._country}
                  >
                    <FormLabel>Country</FormLabel>
                    <Field name="_country" as={Input} />
                    <ErrorMessage
                      name="_country"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={props.errors._redeem && props.touched._redeem}
                  >
                    <FormLabel>Redeem</FormLabel>
                    <Field name="_redeem" as={Textarea} />
                    <ErrorMessage
                      name="_redeem"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button variant="pvGray" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="pvBlackGray"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    {selectedProduct == null ? "Add" : "Edit"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      {/* Modal for adding product */}
      <Modal
        isOpen={isOpenVariants}
        onClose={onCloseVariants}
        size="md"
        isCentered
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Variants</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedVariants.length === 0 ? (
              <>
                <Box display="flex" justifyContent="center">
                  <Text fontSize={"md"} my={5}>
                    You haven't added any variants yet.
                  </Text>
                </Box>
              </>
            ) : (
              <>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th isNumeric>Price</Th>
                        <Th>Currency</Th>
                        <Th isNumeric>Stock</Th>
                        {/*                        <Th></Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedVariants.map((variant, index) => (
                        <Tr key={index}>
                          <Td isNumeric>{Utils.fromWei(variant.price)}</Td>
                          <Td>{variant.curr}</Td>
                          <Td isNumeric>{variant.stock}</Td>
                          {/*                             <Td>
                              <Tooltip label="Edit Variant">
                                <IconButton
                                  size={"xs"}
                                  icon={<EditIcon />}
                                  onClick={editVariant(variant)}
                                />
                              </Tooltip>
                            </Td> */}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="pvGray" mr={3} onClick={onCloseVariants}>
              Close
            </Button>
            <Button
              variant="pvBlackGray"
              type="submit"
              onClick={onAddVariantOpen}
            >
              Add Variant
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add Variant Modal */}
      <Modal
        isOpen={isAddVariantOpen}
        onClose={onAddVariantClose}
        size="md"
        isCentered
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              price: selectedVariant ? selectedVariant.price : "",
              currency: selectedVariant ? selectedVariant.curr : "",
              stock: selectedVariant ? selectedVariant.stock : "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const methodParamsAddVariant = {
                  productId: selectedId,
                  price: values.price,
                  currency: values.currency,
                  stock: values.stock,
                };

                if (selectedVariant != null) {
                  methodParamsAddVariant["variantId"] = 0;
                }

                let methodName = "addVariant";
                if (selectedVariant != null) {
                  methodName = "editVariant";
                }

                await Contract.execute(
                  contractItem.network,
                  contractItem.contract,
                  methodName,
                  methodParamsAddVariant
                );

                refreshWallet();

                await fetchData();

                setSelectedVariants([]);

                onCloseVariants();
                onAddVariantClose();

                setSubmitting(false);
              } catch (error) {
                toast({
                  title: "Error",
                  description: error.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            }}
          >
            {(props) => (
              <Form>
                <ModalHeader>
                  {selectedVariant === null ? "Add" : "Edit"} Variant
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Price (wei)</FormLabel>
                    <Field name="price" as={Input} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Currency</FormLabel>
                    <Field name="currency" as={Input} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Stock</FormLabel>
                    <Field name="stock" as={Input} type="number" />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button variant="pvGray" mr={3} onClick={onAddVariantClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="pvBlackGray"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    {selectedVariant === null ? "Add" : "Edit"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      {/* Add Content Modal */}
      <Modal
        isOpen={isOpenContent}
        onClose={onCloseContent}
        size="md"
        isCentered
        variant="pvBlackGray"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              category: selectedContent ? selectedContent.category : "",
              title: selectedContent ? selectedContent.title : "",
              text: selectedContent ? selectedContent.text : "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                let methodParamsAddContent = {};

                if (selectedContent != null) {
                  methodParamsAddContent["_contentId"] = selectedContent.id;
                }

                methodParamsAddContent["_category"] = values.category;
                methodParamsAddContent["_title"] = values.title;
                methodParamsAddContent["_text"] = values.text;

                let methodName = "addContent";
                if (selectedContent != null) {
                  methodName = "editContent";
                }

                await Contract.execute(
                  contractItem.network,
                  contractItem.contract,
                  methodName,
                  methodParamsAddContent
                );

                refreshWallet();

                await fetchData();

                setSelectedContent(null);

                onCloseContent();

                setSubmitting(false);
              } catch (error) {
                toast({
                  title: "Error",
                  description: error.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            }}
          >
            {(props) => (
              <Form>
                <ModalHeader>
                  {selectedContent === null ? "Add" : "Edit"} Content
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Category</FormLabel>
                    <Field name="category" as={Input} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Field name="title" as={Input} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Text</FormLabel>
                    <Field name="text" as={Input} />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button variant="pvGray" mr={3} onClick={onCloseContent}>
                    Cancel
                  </Button>
                  <Button
                    variant="pvBlackGray"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    {selectedContent === null ? "Add" : "Edit"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProductStore;
