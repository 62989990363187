import React, { useEffect, useState } from "react";

import {
  Flex,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";

import { PvBoxSub } from "../../../../custom/components";

import CopyAddressButton from "../../components/CopyAddressButton";

function Events({ contract, project }) {
  const [eventsContent, setEventsContent] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(false);

  function getTable(inputParameters) {
    if (!Array.isArray(inputParameters)) {
      return null;
    }

    return (
      <Table variant="unstyled">
        <Thead>
          <Tr>
            <Th>Parameter</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {inputParameters.map((param, index) => (
            <Tr key={index}>
              <Td>{param.name}</Td>
              <Td>{param.value}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  const fetchEvents = async () => {
    try {
      setIsEventsLoading(true);

      const response = await Contract.events(
        contract.network,
        contract.contract
      );

      setEventsContent(response);

      setIsEventsLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      // Retry after 5 seconds if the request fails
      setTimeout(() => fetchEvents(), 3000);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [contract, project]);

  return (
    <>
      <PvBoxSub p={0} mt={6}>
        {isEventsLoading && (
          <Flex w="100%" justifyContent="center" alignItems="center" my={10}>
            <Spinner size="xl" color="#753CC5" />
          </Flex>
        )}

        {!isEventsLoading && (
          <>
            <Box
              w={"100%"}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={4}
              bgColor={"#000"}
              borderTopLeftRadius={6}
              borderTopRightRadius={6}
              fontSize={"xs"}
              fontWeight={"bold"}
              color={"#888"}
            >
              <Box flex="1" textAlign="left">
                TRANSACTIONS
              </Box>
              <Box flex="1" textAlign="left">
                EVENTS
              </Box>
              <Box flex="1" textAlign="left">
                BLOCK NUMBER
              </Box>
            </Box>
            <Accordion w={"100%"} allowMultiple overflowX="auto">
              {eventsContent.map((event, index) => (
                <AccordionItem key={index}>
                  <h2>
                    <AccordionButton>
                      <Box
                        w={"100%"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box flex="1" textAlign="left">
                          <CopyAddressButton address={event.transactionHash} />
                        </Box>
                        <Box flex="1" textAlign="left" pl={5}>
                          {event.eventItems.map((eventItem, itemIndex) => (
                            <Tag key={itemIndex} mr={2}>
                              {eventItem.decoded?.name ?? event.methodName}
                            </Tag>
                          ))}
                        </Box>
                        <Box
                          flex="1"
                          textAlign="left"
                          pl={10}
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          {event.blockNumber}
                        </Box>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={"sm"}>
                    <Box
                      border="1px solid #888"
                      bgColor="#1e1e1e"
                      borderRadius={6}
                      p={5}
                    >
                      <Box w={"100%"} display="flex">
                        <Box
                          textAlign="left"
                          fontWeight={"bold"}
                          width={200}
                          fontSize={"lg"}
                        >
                          Transaction
                        </Box>
                        <Box textAlign="left" w={400}></Box>
                      </Box>
                      <Divider mt={4} />
                      <Box w={"100%"} display="flex" mt={4}>
                        <Box textAlign="left" fontWeight={"bold"} width={200}>
                          Transaction Hash
                        </Box>
                        <Box
                          textAlign="left"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          {event.transactionHash}
                        </Box>
                      </Box>
                      <Box w={"100%"} display="flex" mt={4}>
                        <Box textAlign="left" fontWeight={"bold"} width={200}>
                          Block Number
                        </Box>
                        <Box textAlign="left">{event.blockNumber}</Box>
                      </Box>
                      <Box w={"100%"} display="flex" mt={4}>
                        <Box textAlign="left" fontWeight={"bold"} width={200}>
                          Method
                        </Box>
                        <Box textAlign="left">{event.methodName}</Box>
                      </Box>
                      <Box w={"100%"} display="flex" mt={4}>
                        <Box textAlign="left" fontWeight={"bold"} width={200}>
                          Parameters
                        </Box>
                        <Box
                          textAlign="left"
                          border="1px solid #888"
                          borderRadius={6}
                          w={400}
                          overflowX="auto"
                        >
                          {getTable(event.inputParameters)}
                        </Box>
                      </Box>
                      <Divider mt={4} />
                      <Box w={"100%"} display="flex" mt={6}>
                        <Box
                          textAlign="left"
                          fontWeight={"bold"}
                          width={200}
                          fontSize={"lg"}
                        >
                          Event
                        </Box>
                        <Box textAlign="left" w={400}></Box>
                      </Box>
                      <Divider mt={4} />
                      {event.eventItems.map((eventItem, itemIndex) => (
                        <React.Fragment key={itemIndex}>
                          <Box w={"100%"} display="flex" mt={4} key={itemIndex}>
                            <Box
                              textAlign="left"
                              fontWeight={"bold"}
                              width={200}
                            >
                              {eventItem.decoded?.name}
                            </Box>
                            <Box
                              textAlign="left"
                              border="1px solid #888"
                              borderRadius={6}
                              w={400}
                              overflowX="auto"
                            >
                              {getTable(eventItem.decoded?.params)}
                            </Box>
                          </Box>
                          <Divider mt={4} />
                        </React.Fragment>
                      ))}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        )}
      </PvBoxSub>
    </>
  );
}

export default Events;
