import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Table,
  Tr,
  Box,
  Thead,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";

const UserAssets = ({ project }) => {
  const [userAssets, setUserAssets] = useState([]);

  //take userId from URL
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");

  useEffect(() => {
    const fetchUserAssets = async () => {
      const response = await axios.get(
        `https://api.polyverse.life/Trade/Dashboard/UserAssets?projectId=${project.id}&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setUserAssets(response.data.data);
    };
    fetchUserAssets();
  }, [userId, project]);

  return (
    <>
      <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
        <Table variant="unstyled" p={0} borderRadius={6}>
          <Thead bg="black" h={14}>
            <Tr>
              <Th
                borderTopLeftRadius={6}
                px={4}
                py={2}
                color="#888"
                fontWeight="bold"
              >
                Currency
              </Th>
              <Th color="#888">Amount</Th>
              <Th
                borderTopRightRadius={6}
                px={4}
                py={2}
                color="#888"
                fontWeight="bold"
              >
                Available Amount
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {userAssets.map((asset, index) => (
              <Tr key={index}>
                <Td>{asset.currency}</Td>
                <Td>{asset.amount}</Td>
                <Td>{asset.availableAmount}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default UserAssets;
