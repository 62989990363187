import { FaRegCopy, FaCopy } from "react-icons/fa";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
  Tooltip,
  IconButton,
  FormLabel,
  Flex,
} from "@chakra-ui/react";

function ProjectInfoModal({ isOpen, onClose, project }) {
  const { hasCopied: idCopied, onCopy: copyId } = useClipboard(project.id);
  const { hasCopied: secretCopied, onCopy: copySecret } = useClipboard(
    project.secret
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered variant={"pvBlackGray"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Project Info</ModalHeader>
        <ModalBody>
          <Flex direction="column" mb={4}>
            <FormLabel>Project ID</FormLabel>
            <InputGroup>
              <Input value={project.id} isReadOnly pr="4.5rem" type={"text"} />
              <InputRightElement>
                <Tooltip label={secretCopied ? "Copied" : "Copy Id"}>
                  <IconButton
                    onClick={copyId}
                    icon={idCopied ? <FaCopy /> : <FaRegCopy />}
                    size={"sm"}
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel>Secret Key</FormLabel>
            <InputGroup>
              <Input
                value={project.secret}
                isReadOnly
                pr="4.5rem"
                type={"text"}
              />
              <InputRightElement>
                <Tooltip label={secretCopied ? "Copied" : "Copy Secret"}>
                  <IconButton
                    onClick={copySecret}
                    icon={secretCopied ? <FaCopy /> : <FaRegCopy />}
                    size={"sm"}
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant={"pvBlackGray"}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectInfoModal;
