import React, { useEffect, useState } from "react";
import {
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tag,
  Box,
  Flex,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";

import { PvBoxSub } from "../../../../../custom/components";
import CopyAddressButton from "../../../components/CopyAddressButton";

function Permissions({ contract, project }) {

  const toast = useToast();

  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRoles = async () => {
      if (contract.config.roles && contract.id > 1272) {
        setIsLoading(true);
        setPermissions([]);

        try {
          //admin role
          const adminRole = await Contract.execute(
            contract.network,
            contract.contract,
            "DEFAULT_ADMIN_ROLE"
          );

          const methodParamsAdminRole = {
            role: adminRole.data,
          };
          const adminRoleCount = await Contract.execute(
            contract.network,
            contract.contract,
            "getRoleMemberCount",
            methodParamsAdminRole
          );
          for (let index = 0; index < adminRoleCount.data; index++) {
            const methodParamsCheckAdminRole = {
              role: adminRole.data,
              index: index,
            };
            const adminRoleCheck = await Contract.execute(
              contract.network,
              contract.contract,
              "getRoleMember",
              methodParamsCheckAdminRole
            );

            addPermission(adminRoleCheck.data, { admin: true });
          }

          //minter role
          const minterRole = await Contract.execute(
            contract.network,
            contract.contract,
            "MINTER_ROLE"
          );

          const methodParamsMinterRole = {
            role: minterRole.data,
          };
          const minterRoleCount = await Contract.execute(
            contract.network,
            contract.contract,
            "getRoleMemberCount",
            methodParamsMinterRole
          );
          for (let index = 0; index < minterRoleCount.data; index++) {
            const methodParamsCheckMinterRole = {
              role: minterRole.data,
              index: index,
            };
            const minterRoleCheck = await Contract.execute(
              contract.network,
              contract.contract,
              "getRoleMember",
              methodParamsCheckMinterRole
            );

            addPermission(minterRoleCheck.data, { minter: true });
          }
        } catch (error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        setIsLoading(false);
      }

      if (!contract.config.roles) {
        setIsLoading(true);
        setPermissions([]);

        try {
          const owner = await Contract.execute(
            contract.network,
            contract.contract,
            "owner"
          );

          addPermission(owner.data, { owner: true });
        } catch (error) {}

        setIsLoading(false);
      }

      if (contract.id < 1272) {
        setIsLoading(false);
      }
    };

    fetchRoles();
  }, [contract]);

  const addPermission = (member, role) => {
    setPermissions((prevPermissions) => {
      const existingPermissionIndex = prevPermissions.findIndex(
        (permission) => permission.member === member
      );

      if (existingPermissionIndex > -1) {
        const existingPermission = prevPermissions[existingPermissionIndex];
        const updatedRoles = [...existingPermission.roles, role];
        const updatedPermission = { member, roles: updatedRoles };
        const updatedPermissions = [
          ...prevPermissions.slice(0, existingPermissionIndex),
          updatedPermission,
          ...prevPermissions.slice(existingPermissionIndex + 1),
        ];
        return updatedPermissions;
      } else {
        const newPermission = { member, roles: [role] };
        return [...prevPermissions, newPermission];
      }
    });
  };

  return (
    <>
      {permissions.length > 0 && (
        <>
          <Heading as="h4" size="md" p={3} alignSelf="flex-start">
            Permissions
          </Heading>
          <PvBoxSub p={0}>
            <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
              {!isLoading && (
                <Table variant="unstyled" p={0} borderRadius={6}>
                  <Thead bg="black" h={14}>
                    <Tr>
                      <Th
                        borderTopLeftRadius={6}
                        px={4}
                        py={2}
                        color="#888"
                        fontWeight="bold"
                      >
                        Member
                      </Th>
                      <Th color="#888">Roles</Th>
                      <Th></Th>
                      <Th></Th>
                      <Th
                        borderTopRightRadius={6}
                        px={4}
                        py={2}
                        color="#888"
                        fontWeight="bold"
                      ></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {permissions.map((permission, index) => (
                      <Tr key={index}>
                        <Td>
                          <CopyAddressButton address={permission.member} />
                        </Td>
                        <Td>
                          {permission.roles.map((role, roleIndex) => (
                            <React.Fragment key={roleIndex}>
                              {Object.keys(role).map(
                                (key, keyIndex) =>
                                  role[key] && (
                                    <Tag key={keyIndex} ml={1}>
                                      {key}
                                    </Tag>
                                  )
                              )}
                            </React.Fragment>
                          ))}
                        </Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}

              {isLoading && (
                <Flex
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                  my={5}
                >
                  <Spinner size="xl" color="#753CC5" />
                </Flex>
              )}
            </Box>
          </PvBoxSub>
        </>
      )}
    </>
  );
}

export default Permissions;
