import { Link as RouterLink } from "react-router-dom";
import React from "react";

import ImgDBLeft from "../../static/db-left2.png";
import ImgDBRight from "../../static/db-right.png";
import menuData from "../../datas/menuData.json";
import kfData from "../../datas/keyFeaturesData.json";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import CardSlogan from "../../components/CardSlogan";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  return (
    <>
      <Header />

      <Box mt={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Flex flexDirection="column" alignItems="center">
              <Heading
                as="h2"
                size="3xl"
                noOfLines={1}
                bgGradient="linear(to-b, white, #888888)"
                bgClip="text"
              >
                {menuData["Products"][2].name}
              </Heading>

              <Heading as="h4" size="md" color={"#888888"} my={5}>
                {menuData["Products"][2].text}
              </Heading>

              <Box bg="#753CC5" p={2} borderRadius="full" boxSize={50}>
                <Image src={menuData["Products"][2].icon} />
              </Box>

              <Box mt={8}>
                {/*                 <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/cs"}
                >
                  Learn More
                </Button> */}
                <Button
                  variant={"pvWhite"}
                  width={200}
                  as={RouterLink}
                  to={"/getearlyaccess"}
                >
                  Get Early Access
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mt={10}>
        <Flex
          w="80%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 2}
            spacing={10}
            px={{ base: 4, md: 8 }}
          >
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBLeft} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Empower Your Stack with our SDKs
              </Text>
            </Box>

            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={ImgDBRight} boxSize={16} />
              <Text mt={2} fontSize={"lg"} color={"#888888"}>
                Seamlessly Interact with Smart Contracts
              </Text>
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>

      <CardSlogan
        title="Empower Your Development Journey"
        text="Unleash Your Potential with our SDKs"
        description={{
          __html: `Our SDKs features an array of capabilities making it the prime choice for developers aiming for seamless tokenization. With its compatibility extending to popular game engines like Unity, Unreal Engine, and prevalent frameworks such as Node.js and TypeScript, it ensures versatile application. Beyond this, Our SDKs guarantees secure communication and authentication, and enables chain-agnostic integration on EVM chains, presenting a comprehensive, robust, and adaptable solution.`,
        }}
      />

      <Box mt={35}>
        <Flex
          w="100%"
          p={5}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={5}
          mb={20}
        >
          <Heading
            as="h4"
            size="xl"
            noOfLines={1}
            bgGradient="linear(to-b, #753cc5, #fff)"
            bgClip="text"
          >
            Key Features
          </Heading>

          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 3}
            spacing={10}
            px={{ base: 4, md: 8 }}
            mt={10}
          >
            {kfData.map((item, outerIndex) => (
              <React.Fragment key={outerIndex}>
                {item.SDKs.map((text, innerIndex) => (
                  <Box
                    key={`${outerIndex}-${innerIndex}`} // burada indeksleri birleştiriyoruz
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    borderColor={"#333333"}
                    borderRadius={6}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text mt={2} fontSize={"lg"} color={"#888888"}>
                      {text}
                    </Text>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </SimpleGrid>
        </Flex>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
