"use strict";
//import * as secrets from "secrets.js-grempe";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
const Shamir = __importStar(require("../../shamir"));
class Utils {
    // Split a Private Key into shares using Shamir's Secret Sharing, removing the '0x' prefix
    static splitPrivateKey(key, totalShares, threshold) {
        // Remove the '0x' prefix
        key = key.startsWith("0x") ? key.substring(2) : key;
        return Shamir.default.generateShares(key, totalShares, threshold);
    }
    // Reassemble the pieces to recover the original private key
    static reconstructPrivateKey(shares) {
        const combinedKey = "0x" + Shamir.default.deriveSecret(shares.slice(0, 2)); // Combining the first two shares to reconstruct the key
        return combinedKey;
    }
}
exports.Utils = Utils;
