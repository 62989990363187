import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import prism from "react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus";
import beautify from "json-beautify";
import { FaRegCopy, FaCopy } from "react-icons/fa";
import { MdPayment } from "react-icons/md";

import { useWalletBalance } from "../../../../../contexts/WalletBalanceContext";

import * as Helpers from "../../../../../services/Helpers";

import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Box,
  IconButton,
  useClipboard,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";

function ExplorerFormGenerator({ jsonSchema, onSubmit, contract }) {

  const { isRefreshWalletBalance, refreshWallet } = useWalletBalance();

  const toast = useToast();

  SyntaxHighlighter.registerLanguage("json", json);

  const formikRef = useRef();

  const [contractNetwork, setContractNetwork] = useState(null);
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { hasCopied, onCopy } = useClipboard(result);
  const formKey = Date.now().toString();

  const validateField = (field, value) => {
    const { name, type } = field;

    switch (type) {
      case "payable":
        if (!/^\d+(\.\d+)?$/.test(value)) {
          return "Please enter a valid floating-point or integer value. (ex: 0.01 to send 0.01 native currency)";
        }
        break;
      case "uint256":
        if (!/^\d+$/.test(value)) {
          return "Please enter a valid uint256 value";
        }
        break;
      case "address":
        if (!/^0x[a-fA-F0-9]{40}$/.test(value)) {
          return "Please enter a valid address or ENS name";
        }
        break;
      case "bool":
        if (typeof value !== "boolean") {
          return "Please enter a valid boolean value";
        }
        break;
      case "tuple[]":
        if (value === undefined || value === null || value === "") {
          return "Please enter a valid tuple[] value";
        }
        break;
      case "bytes":
        if (typeof value !== "string") {
          return "Please enter a valid byte array";
        }
        break;
      case "bytes32":
        if (!/^0x[a-fA-F0-9]{64}$/.test(value)) {
          return "Please enter a valid bytes32 value";
        }
        break;
      case "string":
        if (typeof value !== "string") {
          return "Please enter a valid string value";
        }
        break;
      case "uint8":
      case "uint16":
      case "uint32":
      case "uint64":
      case "uint128":
      case "uint160":
        if (!Number.isInteger(value) || value < 0) {
          return "Please enter a valid uint value";
        }
        break;
      case "int8":
      case "int16":
      case "int32":
      case "int64":
      case "int128":
      case "int160":
      case "int256":
        if (!Number.isInteger(value)) {
          return "Please enter a valid integer value";
        }
        break;
      default:
        break;
    }

    return undefined;
  };

  useEffect(() => {
    jsonSchema?.inputs?.forEach((input) => {
      if (input.name === "") {
        input.name = "input";
      }
    });

    setContractNetwork(Helpers.getNetwork(contract.network));

    if (jsonSchema?.inputs?.length === 0) {
      formikRef.current.submitForm();
    }
    setResult("");
  }, [jsonSchema]);

  return (
    <Formik
      key={formKey}
      initialValues={{}}
      innerRef={formikRef}
      onSubmit={async (values, { resetForm }) => {
        setIsLoading(true);

        try {
          if (values.length === 0) {
            const methodResponse = await Contract.execute(
              contract.network,
              contract.contract,
              jsonSchema.name
            );

            setResult(methodResponse.data);
          } else {
            const methodParams = {};

            for (const key in values) {
              if (values.hasOwnProperty(key)) {
                methodParams[key] = values[key];
              }
            }

            const methodResponse = await Contract.execute(
              contract.network,
              contract.contract,
              jsonSchema.name,
              methodParams
            );

            setResult(methodResponse.data);
          }
        } catch (error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        onSubmit(values);
        //resetForm();
        setIsLoading(false);

        refreshWallet();
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={4}>
            {jsonSchema?.stateMutability === "payable" && (
              <FormControl>
                <FormLabel>
                  Amount to Pay (in {contractNetwork.symbol})
                </FormLabel>
                <Field
                  name="value"
                  validate={(value) =>
                    validateField({ name: "value", type: "payable" }, value)
                  }
                >
                  {({ field, meta }) => (
                    <>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<MdPayment />}
                        />
                        <Input {...field} />
                      </InputGroup>
                      {meta.touched && meta.error && (
                        <Text color="#753cc5">{meta.error}</Text>
                      )}
                    </>
                  )}
                </Field>
              </FormControl>
            )}

            {jsonSchema?.inputs?.map((field, index) => (
              <FormControl key={index}>
                <FormLabel>
                  {field.name === "" ? "input" : field.name} ({field.type})
                </FormLabel>
                <Field
                  name={field.name}
                  validate={(value) => validateField(field, value)}
                >
                  {({ field, meta }) => (
                    <>
                      <Input {...field} />
                      {meta.touched && meta.error && typeof meta.error === "string" && (
                        <Text color="#753cc5">{meta.error}</Text>
                      )}
                    </>
                  )}
                </Field>
              </FormControl>
            ))}
            <Box hidden={result === ""}>
              <Box
                border="1px solid #888"
                bgColor="#1e1e1e"
                borderRadius={6}
                display="flex"
                justifyContent="space-between"
              >
                <SyntaxHighlighter language="json" style={prism}>
                  {beautify(JSON.parse(JSON.stringify(result)), null, 2, 20)}
                </SyntaxHighlighter>
                <IconButton
                  icon={hasCopied ? <FaCopy /> : <FaRegCopy />}
                  size="sm"
                  onClick={onCopy}
                  mr={1}
                  mt={1}
                />
              </Box>
            </Box>
            <Button
              variant={"pvBlackGray"}
              type="submit"
              w={200}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Execute
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default ExplorerFormGenerator;
