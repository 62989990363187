import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";

import { Project } from "polyverse-sdk/dist/api/project";

import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useMediaQuery,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
} from "@chakra-ui/react";

function Edit() {
  const { projectId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const [isProjectDataLoading, setIsProjectDataLoading] = useState(false);
  const [projectData, setProjectData] = useState({});

  useEffect(() => {
    const fetchProject = async () => {
      setIsProjectDataLoading(true);

      try {
        const response = await Project.project(parseInt(projectId));
        setProjectData(response);
      } catch (error) {
        console.error("Failed to fetch system statistics:", error);
      }

      setIsProjectDataLoading(false);
    };

    fetchProject();
  }, [projectId]);

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      description: "",
    },
    onSubmit: async (values) => {

      try {
        await Project.edit(values.id, values.name, values.description);
        navigate(`/dashboard/project/${projectId}/contracts`);
      } catch (error) {
        toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      }

    },
  });

  useEffect(() => {
    if (!isProjectDataLoading) {

      formik.setValues((prevValues) => ({
        ...prevValues,
        id: projectData.id,
        name: projectData.name,
        description: projectData.desc,
      }));
    }
  }, [projectData]);

  return (
    <>
      <Header />

      <Box my={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={isIpad ? "60%" : isIphone ? "92%" : "40%"}>
            <Box
              bgColor="#111111"
              border="1px solid #333333"
              borderRadius={6}
              p={5}
            >
              <Heading as="h3" size="lg">
                Edit Project
              </Heading>
              <Text fontSize="sx" color="#888">
                Modify your Project's Name and Description
              </Text>
              <Box mt={10}>
                <Box maxW="400px" mx="auto">
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl
                      isInvalid={formik.errors.name && formik.touched.name}
                    >
                      <FormLabel>Name</FormLabel>
                      <Input
                        id="name"
                        type="text"
                        {...formik.getFieldProps("name")}
                      />
                      <FormErrorMessage color="#753CC5">
                        {formik.errors.name}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        formik.errors.description && formik.touched.description
                      }
                      mt={4}
                    >
                      <FormLabel>Description</FormLabel>
                      <Textarea
                        id="description"
                        {...formik.getFieldProps("description")}
                      />
                      <FormErrorMessage color="#753CC5">
                        {formik.errors.description}
                      </FormErrorMessage>
                    </FormControl>

                    <Button my={4} variant="pvWhite" type="submit" w="100%">
                      Save Changes
                    </Button>
                  </form>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default Edit;
