"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contract = void 0;
const apiHelper_1 = require("./utils/apiHelper");
class Contract {
    static execute(network, contract, methodName, methodParams = {}, format) {
        return __awaiter(this, void 0, void 0, function* () {
            const finalFormat = format || "raw";
            const formData = new FormData();
            formData.append("format", finalFormat);
            for (const key in methodParams) {
                formData.append(key, methodParams[key]);
            }
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/${contract}/${methodName}`, formData);
            return response;
        });
    }
    static events(network, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/events`);
            return response.data;
        });
    }
    static transactions(network, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/transactions`);
            return response.data;
        });
    }
    static abi(network, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/ABI`);
            return response.data;
        });
    }
}
exports.Contract = Contract;
Contract.CONTROLLER_NAME = 'Contract';
