import React, { useState } from "react";

import menuData from "../../datas/menuData.json";
import solutionsData from "../../datas/solutionsData.json";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import VideoCoverImage from "../../static/roll-earn-video-cover.jpg";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";

import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
  AspectRatio,
} from "@chakra-ui/react";

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [showVideo, setShowVideo] = useState(false);

  const YoutubeEmbed = ({ embedId }) => (
    <AspectRatio
      w="90%"
      ratio={16 / 9}
      p={10}
      borderRadius={{ base: 20, md: 40 }}
      overflow={"hidden"}
    >
      <iframe
        width="100%"
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1`}
        title="Roll and Earn Gameplay - Polyverse x Funika Games (Play & Earn Game)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </AspectRatio>
  );

  return (
    <>
      <Header />

      <Box mt={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Flex flexDirection="column" alignItems="center">
              <Heading
                as="h2"
                size="3xl"
                noOfLines={1}
                bgGradient="linear(to-b, white, #888888)"
                bgClip="text"
              >
                {menuData["Solutions"][0].name}
              </Heading>

              <Heading as="h4" size="md" color={"#888888"} my={5}>
                {menuData["Solutions"][0].text}
              </Heading>

              <Box bg="#753CC5" p={2} borderRadius="full" boxSize={50}>
                <Image src={menuData["Solutions"][0].icon} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box
        mt={10}
        bgGradient="linear(to-b, #222222, #000000)"
        textAlign="center"
      >
        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 3}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
          mt={10}
        >
          {solutionsData[0].Gaming.features1.map((feature, index) => (
            <Box
              key={index}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              color={"white"}
            >
              <Heading as="h4" size="md">
                {feature.title}
              </Heading>
              <Text mt={2} fontSize={"md"}>
                {feature.text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>

        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 2}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
          w={isSmallerThan480 ? "100%" : "60%"}
          mx="auto"
        >
          <Box textAlign={"left"}>
            <Box>
              <Heading as="h3" size="lg">
                Empower Your Games with Blockchain Across All Platform
              </Heading>
              <Text mt={2} fontSize={"md"} color={"#888888"}>
                Incorporate Web3 functionalities into your gaming experiences
                irrespective of platform – be it Native, Mobile, Console,
                Browser, or VR.
              </Text>
            </Box>
            <Box mt={10}>
              <Heading as="h3" size="lg">
                Success Story - Funika Games
              </Heading>
              <Text mt={2} fontSize={"md"} color={"#888888"}>
                Roll and Earn: A Real-World Tokenization Triumph Play and Earn
                Crypto with our Revolutionary Game!
              </Text>

              <Text
                mt={2}
                fontSize={"md"}
                fontWeight={"bold"}
                color={"#888888"}
              >
                Roll and Earn serves as a prime example of the power and
                simplicity of our SDKs.
              </Text>
              <Text mt={2} fontSize={"md"} color={"#888888"}>
                The game was tokenized using PolySDK without the need for a
                blockchain developer, showcasing the ease and speed of
                integration that can be achieved with this revolutionary
                toolkit.
              </Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" p={5}>
            {!showVideo ? (
              <img
                src={VideoCoverImage}
                alt="Video Cover"
                cursor="pointer"
                onClick={() => {
                  setShowVideo(true);
                }}
                width={"100%"}
              />
            ) : (
              <YoutubeEmbed embedId="7TN-0DkAIo8" />
            )}
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 3}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
        >
          {solutionsData[0].Gaming.features2.map((feature, index) => (
            <Box
              key={index}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              color={"white"}
              boxShadow="0px 2px 6px rgba(255, 255, 255, 0.2)"
            >
              <Heading as="h4" size="md">
                {feature.title}
              </Heading>
              <Text mt={2} fontSize={"md"}>
                {feature.text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
