import React, { useState, useEffect } from "react";

import { Link as RouterLink, useParams } from "react-router-dom";

import { FaExternalLinkAlt } from "react-icons/fa";
import { RiArrowGoBackFill } from "react-icons/ri";

import Header from "../../layouts/Header";
import Footer from "../../../../layouts/Footer";

import Overview from "./Overview";
import Explorer from "./Explorer";
import Events from "./Events";
import Analytics from "./Analytics";
import Source from "./Source";
import Token from "./Contracts/Token";
import NFTs from "./Contracts/NFTs";
import Rewarder from "./Contracts/Rewarder";
import ProductStore from "./Contracts/ProductStore";
import General from "./Contracts/General";

import ContractMenu from "../components/ContractMenu";
import CopyAddressButton from "../../components/CopyAddressButton";

import { Project } from "polyverse-sdk/dist/api/project";
import { System } from "polyverse-sdk/dist/api/system";

import * as Helpers from "../../../../services/Helpers";

import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
  HStack,
  Button,
  useToast,
  Skeleton,
  Tooltip,
  IconButton,
  Tag,
  TagLabel,
  Avatar,
} from "@chakra-ui/react";

function Index() {
  const toast = useToast();

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const { projectId } = useParams();
  const { contract } = useParams();
  const { menu } = useParams();

  const [projectItem, setProjectItem] = useState(null);
  const [contractItem, setContractItem] = useState(null);
  const [deployableContractItem, setDeployableContractItem] = useState(null);
  const [tokenNetwork, setTokenNetwork] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProjectContract = async () => {
      setIsLoading(true);

      try {
        const response = await Project.deployedContract(
          parseInt(projectId),
          contract
        );

        setContractItem(response);

        if (response.config.type !== "Imported") {
          const responseDeployableContract =
            await System.deployableContractByType(response.config.type);

          setDeployableContractItem(responseDeployableContract);
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    };

    const fetchProject = async () => {
      try {
        const response = await Project.project(parseInt(projectId));
        setProjectItem(response);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchProjectContract();
    fetchProject();
  }, [projectId]);

  useEffect(() => {
    setTokenNetwork(Helpers.getNetwork(contractItem?.network));
  }, [contractItem]);

  const renderContractPartial = () => {
    if (contractItem !== null && projectItem !== null) {
      switch (menu.toLocaleLowerCase()) {
        case "overview":
          return (
            <Overview
              contract={contractItem}
              project={projectItem}
              deployableContract={deployableContractItem}
            />
          );
        case "explorer":
          return <Explorer contract={contractItem} project={projectItem} />;
        case "events":
          return <Events contract={contractItem} project={projectItem} />;
        case "analytics":
          return <Analytics contract={contractItem} project={projectItem} />;
        case "source":
          return <Source contract={contractItem} project={projectItem} />;
        case "token":
          return <Token contract={contractItem} project={projectItem} />;
        case "nftdrop":
          return <NFTs contractItem={contractItem} project={projectItem} />;
        case "rewarder":
          return <Rewarder contractItem={contractItem} project={projectItem} />;
        case "productstore":
          return (
            <ProductStore contractItem={contractItem} project={projectItem} />
          );
        default:
          return (
            <General
              contractItem={contractItem}
              projectItem={projectItem}
              deployableContract={deployableContractItem}
            />
          );
      }
    }
  };

  return (
    <>
      <Header />
      {/* TOP */}
      <Box pt={10} bgColor="#111111">
        <Flex
          w={"100%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bgColor={"#000000"}
        >
          <Box
            borderBottom="1px solid #333333"
            borderTop="1px solid #333333"
            w="100%"
            p={5}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent={isIphone ? "center" : "space-between"}
              flexWrap="wrap"
              w={isIpad ? "95%" : isIphone ? "100%" : "65%"}
              mx="auto"
            >
              <Box p={3} flexGrow={1} maxW={380}>
                {!isLoading && contractItem ? (
                  <>
                    <Box display="flex" alignItems="center">
                      <Heading as="h2" size="lg" mr={2}>
                        {contractItem.config.name}
                      </Heading>
                      <Tooltip
                        label={`Go back to project: ${projectItem?.name}`}
                      >
                        <IconButton
                          icon={<RiArrowGoBackFill />}
                          variant={"outline"}
                          as={RouterLink}
                          to={`/dashboard/project/${projectItem?.id}/contracts`}
                        />
                      </Tooltip>
                    </Box>
                    <Tag size="md" borderRadius="full" mt={4} px={2} py={1}>
                      <Avatar
                        src={`data:image/svg+xml;base64,${contractItem?.chain?.icon}`}
                        size="xs"
                        name="Segun Adebayo"
                        ml={-1}
                        mr={2}
                      />
                      <TagLabel>{contractItem?.chain?.name}</TagLabel>
                    </Tag>
                  </>
                ) : (
                  <>
                    <Box display="flex" alignItems="center">
                      <Heading as="h2" size="lg" mr={2}>
                        <Skeleton>aaa bbb</Skeleton>
                      </Heading>
                    </Box>
                    <Heading as="h5" size="md" noOfLines={2} mt={4}>
                      <Skeleton>aaa bbb</Skeleton>
                    </Heading>
                  </>
                )}
              </Box>
              <HStack
                overflowX="auto"
                overflowY="hidden"
                whiteSpace="nowrap"
                flexGrow={isIphone ? 0 : 1}
                justifyContent={isIphone ? "center" : "flex-end"}
              >
                <Skeleton isLoaded={!isLoading}>
                  <CopyAddressButton address={contractItem?.contract} />
                </Skeleton>

                <Skeleton isLoaded={!isLoading} w={300}>
                  <Button
                    leftIcon={<FaExternalLinkAlt />}
                    variant="pvBlack"
                    minW={160}
                    onClick={() =>
                      window.open(
                        `${tokenNetwork?.explorerUrl}/address/${contractItem?.contract}`,
                        "_blank"
                      )
                    }
                  >
                    {isIphone ? (
                      "View"
                    ) : (
                      <>
                        View on{" "}
                        {Helpers.getSubdomainOrDomain(
                          tokenNetwork?.explorerUrl
                        )}
                      </>
                    )}
                  </Button>
                </Skeleton>
              </HStack>
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* CONTENT */}
      <Box p={5} bgColor="#111111">
        <Flex
          w={isIpad ? "95%" : isIphone ? "100%" : "65%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          {/* BUTTONS */}
          <Box
            w={"100%"}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading as="h4" size="md" p={3} alignSelf="flex-start">
              {deployableContractItem === null
                ? Helpers.formatMenuText(menu)
                : deployableContractItem?.name}
            </Heading>

            <ContractMenu
              contract={contractItem}
              project={projectItem}
              deployableContract={deployableContractItem}
            />
          </Box>
          {/* PANEL */}
          {renderContractPartial()}
        </Flex>
      </Box>
      <Footer />
    </>
  );
}

export default Index;
