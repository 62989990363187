import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import Header from "./layouts/Header";
import Footer from "../../layouts/Footer";

import ProjectControlMenu from "./components/ProjectControlMenu";
import ProjectActionButtons from "./components/ProjectActionButtons";

import { useProjects } from "../../contexts/ProjectsContext";

import { Project } from "polyverse-sdk/dist/api/project";

import { MdViewModule, MdViewList, MdRestoreFromTrash } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";

import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
  SimpleGrid,
  VStack,
  Text,
  Button,
  Link,
  Avatar,
  IconButton,
  InputGroup,
  Input,
  InputLeftElement,
  Skeleton,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";

function Projects() {
  const { isRefreshProjectList, refreshProjects } = useProjects();

  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  const [searchTerm, setSearchTerm] = useState("");

  const [listProjects, setListProjects] = useState([]);
  const [trashedProjects, setTrashedProjects] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);

  const [projectStatistics, setProjectStatistics] = useState([]);

  const [projectView, setProjectView] = useState(3);
  const [showTrashedProjects, setShowTrashedProjects] = useState(false);

  const fetchProjects = async () => {
    setIsLoadingProjects(true);

    try {
      const response = await Project.projects();

      const trashedProjects = response.filter(
        (project) => project.trash === true
      );
      const activeProjects = response.filter(
        (project) => project.trash === false
      );

      setTrashedProjects(trashedProjects);
      setActiveProjects(activeProjects);

      setListProjects(activeProjects);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    }

    setIsLoadingProjects(false);
  };

  const fetchProjectStatistics = async () => {
    try {
      const response = await Project.statistics();
      setProjectStatistics(response);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    }
  };

  useEffect(() => {
    fetchProjectStatistics();
    fetchProjects();
  }, []);

  const handleShowTrashedProjects = () => {
    setShowTrashedProjects(!showTrashedProjects);
  };

  useEffect(() => {
    if (showTrashedProjects) {
      setListProjects(trashedProjects);
    } else {
      setListProjects(activeProjects);
    }
  }, [showTrashedProjects]);

  useEffect(() => {
    const filteredProjects = activeProjects.filter((project) =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setListProjects(filteredProjects);
  }, [searchTerm, activeProjects]);

  useEffect(() => {
    fetchProjects();
  }, [isRefreshProjectList]);

  return (
    <>
      <Header />

      <Box p={5} bgColor="#111111">
        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
        >
          <Box w={"100%"} alignItems="left">
            <Heading as="h2" size="lg" p={3}>
              Overview
            </Heading>
          </Box>
          <Box border="1px solid #333333" borderRadius="6px" w="100%" p={5}>
            <SimpleGrid columns={3} spacing={4} textAlign="center">
              <VStack>
                <Text fontSize="xl" fontWeight="bold" color={"#B6BFCB"}>
                  Total Projects
                </Text>
                <Skeleton isLoaded={projectStatistics.length !== 0}>
                  <Text fontSize="2xl">{projectStatistics.projects}</Text>
                </Skeleton>
              </VStack>

              <VStack>
                <Text fontSize="xl" fontWeight="bold" color={"#B6BFCB"}>
                  Total Contracts
                </Text>
                <Skeleton isLoaded={projectStatistics.length !== 0}>
                  <Text fontSize="2xl">{projectStatistics.contracts}</Text>
                </Skeleton>
              </VStack>

              <VStack>
                <Text fontSize="xl" fontWeight="bold" color={"#B6BFCB"}>
                  Total Users
                </Text>
                <Skeleton isLoaded={projectStatistics.length !== 0}>
                  <Text fontSize="2xl">{projectStatistics.users}</Text>
                </Skeleton>
              </VStack>
            </SimpleGrid>
          </Box>
        </Flex>

        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
          mt={10}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w={"100%"}
          >
            <InputGroup>
              <InputLeftElement>
                <SearchIcon />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                bgColor="transparent"
                border="1px solid #323235"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            {!isSmallerThan480 && (
              <Box ml={5} border="1px solid #333" borderRadius={6}>
                <Flex alignItems="center">
                  <IconButton
                    icon={<MdViewModule />}
                    variant={projectView === 3 ? "solid" : "ghost"}
                    onClick={() => setProjectView(3)}
                  />
                  <IconButton
                    icon={<MdViewList />}
                    variant={projectView === 1 ? "solid" : "ghost"}
                    onClick={() => setProjectView(1)}
                  />
                </Flex>
              </Box>
            )}
            <Button
              variant={"pvWhite"}
              ml={5}
              px={30}
              to={"/dashboard/project/create"}
              as={RouterLink}
            >
              Create Project
            </Button>
          </Box>
        </Flex>

        <Flex
          w={isSmallerThan480 ? "100%" : "90%"}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mx={"auto"}
          my={10}
        >
          <Box w={"100%"} alignItems="left">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              w={"100%"}
            >
              <Heading as="h2" size="lg" p={3}>
                Projects
              </Heading>
              <Box ml={5} border="1px solid #333" borderRadius={6}>
                <Flex alignItems="center">
                  <Tooltip
                    label={
                      !showTrashedProjects
                        ? "Show Trashed Projects"
                        : "Show Active Projects"
                    }
                  >
                    <IconButton
                      icon={<MdRestoreFromTrash />}
                      variant={!showTrashedProjects ? "ghost" : "solid"}
                      onClick={handleShowTrashedProjects}
                    />
                  </Tooltip>
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box border="1px solid #333333" borderRadius="6px" w="100%" p={5}>
            {listProjects.length === 0 ? (
              <Box
                textAlign="center"
                p={4}
                sx={{ display: listProjects.length === 0 ? "none" : "" }}
              >
                <Text fontSize="xl" fontWeight="bold">
                  No projects, yet!
                </Text>
                <Text color={"#999999"}>
                  Create a project or create project with using a template.
                </Text>
                <Box mt={5}>
                  <Button
                    variant={"pvWhite"}
                    to={"/dashboard/project/create"}
                    as={RouterLink}
                  >
                    Create New Project
                  </Button>
                  <Button variant={"pvWhite"} ml={5}>
                    Browse Templates
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                {isLoadingProjects && (
                  <Flex w="100%" justifyContent="center" alignItems="center">
                    <Spinner size="xl" color="#753CC5" />
                  </Flex>
                )}

                {!isLoadingProjects && (
                  <SimpleGrid
                    columns={!isSmallerThan480 ? projectView : 1}
                    spacing={4}
                  >
                    {listProjects.map((project) => (
                      <Box
                        key={project?.id}
                        p={4}
                        border={"1px solid #333333"}
                        bgColor="#000"
                        borderRadius={6}
                        minH={160}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <Avatar size="md" mr={3} name={project.name} />
                            <Box>
                              <Text fontSize="xl" fontWeight="bold">
                                {project.name}
                              </Text>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <ProjectActionButtons project={project} />
                              </Box>
                            </Box>
                          </Box>
                          <ProjectControlMenu project={project} />
                        </Box>

                        <Text fontSize="md" noOfLines={3} mt={4}>
                          {project.desc}
                        </Text>
                      </Box>
                    ))}
                  </SimpleGrid>
                )}
              </>
            )}
          </Box>
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default Projects;
