"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Storage = void 0;
const apiHelper_1 = require("./utils/apiHelper");
class Storage {
    static ipfs(hash) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/ipfs/${hash}`);
            return response;
        });
    }
    static files(projectId, projectSecret, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Files?type=${type}`, projectId, projectSecret);
            return response.data;
        });
    }
    static delete(projectId, projectSecret, hash) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Delete?hash=${hash}`, projectId, projectSecret);
            return response.data;
        });
    }
    static upload(projectId, file, type = 'Storage', encrypt = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('projectId', projectId.toString());
            formData.append('file', file);
            formData.append('type', type);
            formData.append('encrypt', encrypt.toString());
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/Upload`, formData);
            return response.data;
        });
    }
}
exports.Storage = Storage;
Storage.CONTROLLER_NAME = 'Storage';
