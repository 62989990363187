import React from "react";

import { Link as RouterLink } from "react-router-dom";

import Header from "./layouts/Header";
import Footer from "../../layouts/Footer";

import Updates from "./components/Updates";

import {
  GoProjectSymlink,
  GoStack,
  GoProjectTemplate,
  GoLightBulb,
} from "react-icons/go";

import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  useMediaQuery,
  IconButton,
  Link,
  Heading,
} from "@chakra-ui/react";

function Home() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [isSmallerThan820] = useMediaQuery("(max-width: 820px)");

  return (
    <>
      <Header />

      <Box
        px={!isSmallerThan820 ? 10 : 2}
        py={!isSmallerThan480 ? 10 : 5}
        bgColor={"#111111"}
      >
        <Flex justifyContent="center" alignItems="center" textAlign="center">
          <Box w={!isSmallerThan820 ? "70%" : "100%"}>
            <Flex>
              <Box w={!isSmallerThan820 ? "80%" : "100%"}>
                <Heading
                  as="h4"
                  size="md"
                  mb={5}
                  textAlign={!isSmallerThan820 && "left"}
                >
                  Jump Right In 👇
                </Heading>
                <SimpleGrid columns={!isSmallerThan820 ? 2 : 1} spacing={10}>
                  <Box
                    p={5}
                    bgColor="#000"
                    border="1px solid #333"
                    borderRadius={6}
                  >
                    <Text fontSize="xl" fontWeight="bold">
                      Projects
                    </Text>
                    <IconButton
                      icon={<GoProjectSymlink />}
                      size="md"
                      color={"#fff"}
                      bgColor={"#753CC5"}
                      isRound
                      mr={2}
                      mt={5}
                      _hover={{ bgColor: "#753CC5" }}
                    />
                    <Text mt={5} color={"#888888"}>
                      View, edit, and effectively manage your projects for
                      seamless project management.
                    </Text>
                    <Box mt={5}>
                      <Link as={RouterLink} to={"/dashboard/projects"}>
                        View Projects →
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    p={5}
                    bgColor="#000"
                    border="1px solid #333"
                    borderRadius={6}
                  >
                    <Text fontSize="xl" fontWeight="bold">
                      Contracts
                    </Text>
                    <IconButton
                      size="md"
                      icon={<GoStack />}
                      isRound
                      mr={2}
                      color={"#fff"}
                      bgColor={"#753CC5"}
                      _hover={{ bgColor: "#753CC5" }}
                      mt={5}
                    />

                    <Text mt={5} color={"#888888"}>
                      Start the web3 world right away with our rich collection
                      of smart contracts that you can deploy with just one
                      click.
                    </Text>
                    <Box mt={5}>
                      <Link as={RouterLink} to={"/dashboard/explore"}>
                        View Contracts →
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    p={5}
                    bgColor="#000"
                    border="1px solid #333"
                    borderRadius={6}
                  >
                    <Text fontSize="xl" fontWeight="bold">
                      Templates
                    </Text>
                    <IconButton
                      size="md"
                      icon={<GoProjectTemplate />}
                      isRound
                      mr={2}
                      color={"#fff"}
                      bgColor={"#753CC5"}
                      _hover={{ bgColor: "#753CC5" }}
                      mt={5}
                    />

                    <Text mt={5} color={"#888888"}>
                      Draw inspiration and kickstart your journey in developing
                      your own web3 applications using our ready-to-use
                      templates.
                    </Text>
                    <Box mt={5}>
                      <Link as={RouterLink} to={"/dashboard/templates"}>
                        View Templates →
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    p={5}
                    bgColor="#000"
                    border="1px solid #333"
                    borderRadius={6}
                  >
                    <Text fontSize="xl" fontWeight="bold">
                      Use-Cases
                    </Text>
                    <IconButton
                      size="md"
                      icon={<GoLightBulb />}
                      isRound
                      mr={2}
                      color={"#fff"}
                      bgColor={"#753CC5"}
                      _hover={{ bgColor: "#753CC5" }}
                      mt={5}
                    />

                    <Text mt={5} color={"#888888"}>
                      Draw Inspiration from Use-Case Examples We've prepared a
                      variety of distinct use-case examples to fuel your
                      inspiration.
                    </Text>
                    <Box mt={5}>
                      <Link>View Use-Cases →</Link>
                    </Box>
                  </Box>
                </SimpleGrid>
                {isSmallerThan820 && <Updates />}
              </Box>
              {!isSmallerThan820 && <Updates />}
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default Home;
