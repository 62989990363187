import React from "react";

import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react";

import CopyAddressButton from "../../components/CopyAddressButton";

function UsersList({ users, project }) {
  return (
    <Box>
      {users.length > 0 ? (
        <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
          <Table variant="unstyled" p={0} borderRadius={6}>
            <Thead bg="black" h={14}>
              <Tr>
              <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  #ID
                </Th>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  Email
                </Th>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  Phone
                </Th>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  Name
                </Th>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  Address
                </Th>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  NCW Address
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user, index) => (
                <Tr key={index}>
                  <Td>{user.id}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.phone}</Td>
                  <Td>{user.name}</Td>
                  <Td>{<CopyAddressButton address={user.address} />}</Td>
                  <Td>{<CopyAddressButton address={user.ncwAddress} />}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Box textAlign="center" p={4}>
          <Text>
            Your project does not have any registered users yet.
            <br />
            If You Build It, They Will Come 💪
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default UsersList;
