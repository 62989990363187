"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wallet = void 0;
const ethers_1 = require("ethers");
const apiHelper_1 = require("../../api/utils/apiHelper");
const system_1 = require("../../api/system");
const storageHelper_1 = require("../../api/utils/storageHelper");
const utils_1 = require("../../utils");
const utils_2 = require("./utils");
const baseWallet_1 = require("../baseWallet");
class Wallet extends baseWallet_1.BaseWallet {
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            let decryptedShare1 = undefined;
            let projectSecret = undefined;
            let projectId = undefined;
            let userId = undefined;
            if (typeof process.env.JEST_WORKER_ID !== 'undefined') {
                projectSecret = "wxcguoTzCmuuBdezpAVElaCeZd";
                projectId = "2251";
                userId = "3797";
                decryptedShare1 = utils_1.Utils.decryptUsingAES256("NZCxKUJimTlSjL6wV5+y8OVAJbrPhse7d5KYtlSq0G/f3n3Z1IFd02B+JjrlzNBO2/7WnFWQfUUMO14jH991nxL4+xWuq25Bs9D6J3d/xb65pLsEs9pVkBW0ihqSD2t4mlyoGuOzignJmUd3e+Bo3A==", utils_1.Utils.padKeyCharacters(projectSecret, 32), utils_1.Utils.padKeyCharacters(projectId + userId, 16));
            }
            else {
                const deviceId = yield storageHelper_1.Storage.getItem("_deviceId");
                if (deviceId === null) {
                    throw new Error("Device ID is not found in the local storage");
                }
                const encryptedProjectSecret = yield storageHelper_1.Storage.getItem("_projectSecret");
                if (encryptedProjectSecret === null) {
                    throw new Error("Project Secret is not found in the local storage");
                }
                projectSecret = utils_1.Utils.decryptUsingAES256(encryptedProjectSecret, deviceId, utils_1.Utils.padKeyCharacters('123456789', 16));
                projectId = yield storageHelper_1.Storage.getItem("_projectId");
                if (projectId === null) {
                    throw new Error("Project ID is not found in the local storage");
                }
                userId = yield storageHelper_1.Storage.getItem("_userId");
                if (userId === null) {
                    throw new Error("User ID is not found in the local storage");
                }
                const encryptedShare1 = yield storageHelper_1.Storage.getItem("_share1");
                if (encryptedShare1 === null) {
                    throw new Error("Share1 is not found in the local storage");
                }
                decryptedShare1 = utils_1.Utils.decryptUsingAES256(encryptedShare1, projectSecret, utils_1.Utils.padKeyCharacters(projectId + userId, 16));
            }
            //get share2 from API
            const encryptedShare2 = yield apiHelper_1.ApiHelper.apiGet("/Wallet/NCWShare", parseInt(projectId), projectSecret.replace(/_/g, ""));
            if (encryptedShare2.data === null) {
                throw new Error("Share2 is not found in the API");
            }
            const decryptedShare2 = utils_1.Utils.decryptUsingAES256(encryptedShare2.data, utils_1.Utils.padKeyCharacters(projectSecret, 32), utils_1.Utils.padKeyCharacters(projectId + userId, 16));
            // TODO: Local storage'dan paylaşım anahtarlarını al
            const shares = [
                decryptedShare1,
                decryptedShare2
            ];
            const privateKey = utils_2.Utils.reconstructPrivateKey(shares);
            try {
                const networkResponse = yield system_1.System.networks(this.networkKey);
                this.network = networkResponse[0];
                this.provider = new ethers_1.ethers.JsonRpcProvider(this.network.url);
                this.wallet = new ethers_1.ethers.Wallet(privateKey, this.provider);
            }
            catch (error) {
                console.error("Failed to connect the Wallet:", error);
                throw error; // Connection failed, throw to prevent usage of an uninitialized instance
            }
        });
    }
}
exports.Wallet = Wallet;
