"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Prize = void 0;
const apiHelper_1 = require("./utils/apiHelper");
class Prize {
    /*public static async setPrize(network:string, contract:string, projectId:number,projectSecret:string, amount:number, prizeType:number, userId:number ,country:string):Promise<SetPrizeResponse>
    {
       
      
      const formData = new FormData();
  
      formData.append('amount', amount.toString());
      formData.append('prizeType',prizeType.toString());
      formData.append('userId', userId.toString());
      formData.append('country', country);
      
  
        const response = await ApiHelper.apiPost<ApiResponse<SetPrizeResponse>>(`/${this.CONTROLLER_NAME}/${network}/${contract}/SetPrize`, formData,projectId,projectSecret);
        return response.data;
  
    }
  
    public static async sendPrize(network:string, contract:string, projectId:number,projectSecret:string, amount:number, prizeType:number, userId:number ,country:string):Promise<SendPrizeResponse>
    {
       
      
      const formData = new FormData();
  
      formData.append('amount', amount.toString());
      formData.append('prizeType',prizeType.toString());
      formData.append('userId', userId.toString());
      formData.append('country', country);
      
  
        const response = await ApiHelper.apiPost<ApiResponse<SendPrizeResponse>>(`/${this.CONTROLLER_NAME}/${network}/${contract}/SendPrize`, formData,projectId,projectSecret);
        return response.data;
  
    }
    */
    static claimPrize(network, contract, projectId, projectSecret, address) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/${contract}/ClaimPrize?address=${address}`, formData, projectId, projectSecret);
            return response.data;
        });
    }
    static offChainSetPrize(network, contract, projectId, projectSecret, type, userId, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("type", type);
            formData.append("userId", userId.toString());
            formData.append("amount", amount.toString());
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/${contract}/OffChainSetPrize`, formData, projectId, projectSecret);
            return response.data;
        });
    }
    static offChainList(network, contract, type, prizeType) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/OffChainList?type=${type}&prizeType=${prizeType}`);
            return response.data;
        });
    }
    static offChain(projectId, projectSecret, network, contract, type, userId, prizeType, symbol) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/OffChain?type=${type}&userId=${userId}&prizeType=${prizeType}&symbol=${symbol}`, projectId, projectSecret);
            return response.data;
        });
    }
    static nextRewardPeriod(projectId, projectSecret, network, contract) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/${network}/${contract}/NextRewardPeriod`, projectId, projectSecret);
            return response.data;
        });
    }
    static prizeTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/PrizeTypes`);
            return response.data;
        });
    }
}
exports.Prize = Prize;
Prize.CONTROLLER_NAME = 'Prize';
