import React from "react";

import TokenOverview from "./components/TokenOverview";
import NFTDropOverview from "./components/NFTDropOverview";
import Permissions from "./components/Permissions";
import RewarderOverview from "./components/RewarderOverview";
import ProductStoreOverview from "./components/ProductStoreOverview";
import GeneralOverview from "./components/GeneralOverview";

function Overview({ contract, project, deployableContract }) {
  const contractType = contract?.config?.type;

  const ContractComponentMap = {
    Token: TokenOverview,
    NFTDrop: NFTDropOverview,
    Rewarder: RewarderOverview,
    ProductStore: ProductStoreOverview,
  };

  const ContractComponent = ContractComponentMap[contractType];

  return (
    <>
      {ContractComponent ? (
        <ContractComponent contract={contract} project={project} />
      ) : (
        <GeneralOverview
          contract={contract}
          project={project}
          deployableContract={deployableContract}
        />
      )}
      <Permissions contract={contract} project={project} />
    </>
  );
}

export default Overview;
