import React, { createContext, useState, useMemo, useCallback } from "react";

export const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const [isRefreshProjectList, setIsRefreshProjectList] = useState(false);


  const refreshProjects = useCallback(() => {
    setIsRefreshProjectList((prevValue) => !prevValue);
  }, []);

  const value = useMemo(() => ({ isRefreshProjectList, refreshProjects }), [isRefreshProjectList, refreshProjects]);

  return (
    <ProjectsContext.Provider
      value={value}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export function useProjects() {
  const context = React.useContext(ProjectsContext);
  if (context === undefined) {
    throw new Error("useProjects must be used within a ProjectsProvider");
  }
  return context;
}