import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link as RouterLink } from "react-router-dom";
import { Box, Text, Heading, Button } from "@chakra-ui/react";

function Index() {
  return (
    <>
      <Header />

      <Box
        py={75}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        w={"60%"}
        mx="auto"
      >
        <Heading as="h3" size="lg">
          Oops,
        </Heading>

        <Text mt={5}>we can't seem to find the page you're looking for...</Text>

        <Button
          mt={5}
          colorScheme="teal"
          variant="pvWhite"
          as={RouterLink}
          to={"/"}
        >
          Back to Home
        </Button>
      </Box>

      <Footer />
    </>
  );
}

export default Index;
