import React, { useState } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import { useNavigate } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";

import * as Helpers from "../../../services/Helpers";
import { Project } from "polyverse-sdk/dist/api/project";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Heading,
  FormErrorMessage,
  Text,
  SimpleGrid,
  useMediaQuery,
  Textarea,
  useToast,
} from "@chakra-ui/react";

function Create() {
  const navigate = useNavigate();
  const toast = useToast();

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const initialValues = {
    name: "",
    description: "",
    additionalFields: [],
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.description) {
      errors.description = "Description is required";
    }

    values.additionalFields.forEach((field, index) => {
      if (field.required && !field.value) {
        if (!errors.additionalFields) {
          errors.additionalFields = [];
        }
        errors.additionalFields[index] = {
          name: "This field is required",
        };
      }
    });

    return errors;
  };

  const onSubmit = async (values) => {
    const formData = new FormData();

    if (selectedTemplate) {
      formData.append("selectedTemplate", selectedTemplate);

      const currentNetwork = Helpers.getCurrentNetwork();
      formData.append("network", currentNetwork.key);
    }

    values.additionalFields.forEach((field) => {
      formData.append(field.name, field.value);
    });

    try {
      var response = await Project.create(
        values.name,
        values.description,
        selectedTemplate,
        formData
      );

      navigate(`/dashboard/project/${response}/contracts`);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const applyTemplate = (templateType, values, setFieldValue) => {
    setSelectedTemplate(templateType);

    if (templateType === "casualGame") {
      const templateFields = [
        {
          type: "text",
          name: "tokenname",
          label: "Token Name",
          maxlength: "255",
          required: true,
        },
        {
          type: "text",
          name: "tokensymbol",
          label: "Token Symbol",
          maxlength: "255",
          required: true,
        },
        {
          type: "text",
          name: "rewardername",
          label: "Rewarder Name",
          maxlength: "255",
          required: true,
        },
      ];

      const additionalFields = templateFields.map((field) => {
        return {
          ...field,
          value: null,
        };
      });

      setFieldValue("additionalFields", additionalFields);
    }
  };

  return (
    <>
      <Header />
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form>
            <Box my={10}>
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Box w={isIpad ? "80%" : isIphone ? "96%" : "60%"}>
                  <SimpleGrid columns={isIphone ? 1 : 2} spacing={10}>
                    <Box
                      bgColor="#111111"
                      border="1px solid #333333"
                      borderRadius={6}
                      p={5}
                    >
                      <Heading as="h3" size="lg">
                        Let's build something.
                      </Heading>
                      <Text fontSize="sx" color="#888">
                        Create an empty project using the form below and deploy
                        your contracts later, or choose a ready-to-use template
                        to deploy contracts as well.
                      </Text>
                      <Box mt={10}>
                        <Box maxW="400px" mx="auto">
                          <Field name="name">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.name && form.touched.name
                                }
                              >
                                <FormLabel>Name</FormLabel>
                                <Input {...field} id="name" />
                                <FormErrorMessage color="#753CC5">
                                  {form.errors.name}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="description">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.description &&
                                  form.touched.description
                                }
                                mt={5}
                              >
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                  {...field}
                                  type="textarea"
                                  id="description"
                                />
                                <FormErrorMessage color="#753CC5">
                                  {form.errors.description}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <FieldArray name="additionalFields">
                            {(arrayHelpers) => (
                              <>
                                {props.values.additionalFields.map(
                                  (field, index) => (
                                    <Box key={index} mt={5}>
                                      <FormLabel>{field.label}</FormLabel>
                                      <Field
                                        name={`additionalFields[${index}].value`}
                                      >
                                        {({ field, form }) => (
                                          <FormControl
                                            isInvalid={
                                              form.errors.additionalFields &&
                                              form.errors.additionalFields[
                                                index
                                              ]
                                            }
                                          >
                                            <Input
                                              {...field}
                                              id={field.name}
                                              onChange={(e) =>
                                                props.setFieldValue(
                                                  `additionalFields[${index}].value`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <FormErrorMessage color="#753CC5">
                                              {form.errors.additionalFields &&
                                                form.errors.additionalFields[
                                                  index
                                                ] &&
                                                form.errors.additionalFields[
                                                  index
                                                ].name}
                                            </FormErrorMessage>
                                          </FormControl>
                                        )}
                                      </Field>
                                    </Box>
                                  )
                                )}
                              </>
                            )}
                          </FieldArray>
                          <Button
                            mt={4}
                            variant="pvWhite"
                            isLoading={props.isSubmitting}
                            type="submit"
                            w="100%"
                          >
                            Create Project
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      border="1px solid #333333"
                      borderRadius={6}
                      h={224}
                      p={5}
                    >
                      <Heading as="h4" size="md">
                        Ready-to-use Templates
                      </Heading>

                      <Button
                        mt={4}
                        variant="pvWhite"
                        onClick={() =>
                          applyTemplate(
                            "casualGame",
                            props.values,
                            props.setFieldValue
                          )
                        }
                      >
                        Casual Game
                      </Button>
                    </Box>
                  </SimpleGrid>
                </Box>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
}

export default Create;
