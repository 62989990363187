import React from "react";
import { Link as RouterLink } from "react-router-dom";
import menuData from "../../datas/menuData.json";
import solutionsData from "../../datas/solutionsData.json";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import BusinessImg from "../../static/solutions-business-min.png";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";

import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
  AspectRatio,
  Button,
} from "@chakra-ui/react";

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  return (
    <>
      <Header />

      <Box mt={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Flex flexDirection="column" alignItems="center">
              <Heading
                as="h2"
                size="3xl"
                noOfLines={1}
                bgGradient="linear(to-b, white, #888888)"
                bgClip="text"
              >
                {menuData["Solutions"][1].name}
              </Heading>

              <Heading as="h4" size="md" color={"#888888"} my={5}>
                {menuData["Solutions"][1].text}
              </Heading>

              <Box bg="#753CC5" p={2} borderRadius="full" boxSize={50}>
                <Image src={menuData["Solutions"][1].icon} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box
        mt={10}
        bgGradient="linear(to-b, #222222, #000000)"
        textAlign="center"
      >
        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 3}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
          mt={10}
        >
          {solutionsData[0].Business.features1.map((feature, index) => (
            <Box
              key={index}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              color={"white"}
            >
              <Heading as="h4" size="md">
                {feature.title}
              </Heading>
              <Text mt={2} fontSize={"md"}>
                {feature.text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>

        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 2}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
          w={isSmallerThan480 ? "100%" : "60%"}
          mx="auto"
        >
          <Box textAlign={"left"}>
            <Box>
              <Heading as="h3" size="lg">
                Draw Inspiration from Use-Case Examples
              </Heading>
              <Text mt={2} fontSize={"md"} color={"#888888"}>
                We've prepared a variety of distinct use-case examples to fuel
                your inspiration. Development these with PolyVerse products has
                never been easier!
              </Text>
            </Box>

            <Box mt={10} display="flex" flexDirection="column" alignItems="center">
              <Heading as="h3" size="lg">
                Dive into the details of our curated use-case examples!
              </Heading>
              <Button
                mt={5}
                colorScheme="teal"
                variant="pvWhite"
                as={RouterLink}
                to={"https://docs.polyverse.life/docs/Usecases/Real-life%20scenarios"}
              >
                Learn More
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Heading as="h3" size="lg">
              Effortlessly Develop Web3 Commerce Applications
            </Heading>
            <Text mt={2} fontSize={"md"} color={"#888888"}>
              Harness the power of thirdweb to integrate robust web3
              capabilities into your Shopify store, facilitating token-gated
              commerce, NFT-based customer loyalty programs, digital
              collectibles sales, and much more.
            </Text>
            <Image src={BusinessImg} mt={10} rounded={"full"} />
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 3}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
        >
          {solutionsData[0].Business.features2.map((feature, index) => (
            <Box
              key={index}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              color={"white"}
              boxShadow="0px 2px 6px rgba(255, 255, 255, 0.2)"
            >
              <Heading as="h4" size="md">
                {feature.title}
              </Heading>
              <Text mt={2} fontSize={"md"}>
                {feature.text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
