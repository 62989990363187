import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useWalletBalance } from "../../../../../contexts/WalletBalanceContext";

import * as Helpers from "../../../../../services/Helpers";

import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Text,
  Divider,
  useToast,
  Textarea,
  Switch,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";
import { Utils } from "polyverse-sdk/dist/utils";

function SettingForm({
  inputs,
  contract,
  caption,
  saveMethod,
  mt,
  sectionName,
  example,
  mb
}) {
  const { isRefreshWalletBalance, refreshWallet } = useWalletBalance();

  const toast = useToast();
  const { section } = useParams();

  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [additionalLabel, setAdditionalLabel] = useState("");

  async function fetchContractData() {
    setFormValues({});

    setLoading(true);

    const newFormValues = {};
    for (const input of inputs) {
      if (input.valueMethod) {
        const response = await Contract.execute(
          contract.network,
          contract.contract,
          input.valueMethod
        );

        let value = response.data;

        if (input.display === "ether") {
          const contractNetwork = Helpers.getNetwork(contract.network);
          value = Utils.fromWeiWithDecimal(value, contractNetwork.decimals);

          setAdditionalLabel(`(in ${contractNetwork.symbol})`);
        }

        if (input.type === "datetime") {
          if (value !== undefined && value !== 0) {
            value = new Date(value * 1000).toISOString().substring(0, 16);
          } else {
            value = "";
          }
        }

        if (input.type === "tuple") {
          if (input.solidityType === "address[]") {
            value = value.map((item) => item).join("\n");
          } else {
            value = value.map((item) => item.join(",")).join("\n");
          }
        }

        if (input.valueIndex !== undefined && input.valueIndex !== null) {
          value = value[input.valueIndex];
        }

        newFormValues[input.name] = value;
      }
    }
    setFormValues(newFormValues);
    setLoading(false);
  }

  useEffect(() => {

    fetchContractData();
  }, [inputs, contract]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type === "checkbox") {
      // Eğer tipi checkbox ise (Switch)
      const checked = e.target.checked; // isChecked değeri
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: checked, // value yerine checked'i kullan
      }));
    } else {
      // Diğer input tipleri
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    let methodParams = {};

    for (const input of inputs) {
      let value = formValues[input.name];

      if (input.convert === "wei") {
        const contractNetwork = Helpers.getNetwork(contract.network);
        value = Utils.toWeiWithDecimal(value, contractNetwork.decimals);
      }

      if (input.type === "tuple") {
        let rows = value
          .split("\n")
          .map(
            (row) =>
              `[${row
                .split(",")
                .map((item) => `${item}`)
                .join(",")}]`
          )
          .join(",");

        if (input.solidityType === "address[]") {
          rows = value
            .split("\n")
            .map(
              (row) =>
                `"${row
                  .split(",")
                  .map((item) => `${item}`)
                  .join(",")}"`
            )
            .join(",");
        }

        value = `[${rows}]`;
      }

      methodParams[input.name] = value;

      if (input.type === "toggle") {
        if (value === true) {
          saveMethod = input.toogleMethodFalse;
        } else {
          saveMethod = input.toogleMethodTrue;
        }

        methodParams = {};
      }
    }

    try {
      await Contract.execute(
        contract.network,
        contract.contract,
        saveMethod,
        methodParams
      );

      toast({
        title: "Success",
        description: "Contract settings updated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    refreshWallet();

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        w={"100%"}
        border={"1px solid #333"}
        borderRadius={6}
        bgColor={"#0a0a0a"}
        mt={mt}
        mb={mb}
      >
        <VStack spacing={4}>
          {inputs.map((input) => (
            <Box key={input.name} p={5} pb={0} w={"100%"}>
              <FormControl>
                <FormLabel fontSize={"lg"}>
                  {input.label} {additionalLabel}
                </FormLabel>
                <Text mb={2} fontSize={"sm"}>
                  {input.description}
                </Text>
                {input.type === "tuple" ? (
                  <Textarea
                    name={input.name}
                    value={formValues[input.name] || ""}
                    onChange={handleChange}
                  />
                ) : input.type === "toggle" ? (
                  <Switch
                    name={input.name}
                    isChecked={formValues[input.name] || false}
                    onChange={handleChange}
                    size={"lg"}
                  />
                ) : (
                  <Input
                    type={
                      input.type === "datetime" ? "datetime-local" : input.type
                    }
                    name={input.name}
                    value={formValues[input.name] || ""}
                    onChange={handleChange}
                  />
                )}
              </FormControl>
            </Box>
          ))}
          <Divider />
          <Box
            pb={4}
            px={5}
            w={"100%"}
            display="flex"
            justifyContent="space-between"
          >
            <Box fontSize={"sm"}>
              {example && (
                <>
                  <Text>Example</Text>
                  {example.includes("\n") ? (
                    example.split("\n").map((line, idx) => (
                      <React.Fragment key={idx}>
                        {idx !== 0}
                        <Text fontSize={"xs"}>{line}</Text>
                      </React.Fragment>
                    ))
                  ) : (
                    <Text fontSize={"xs"}>{example}</Text>
                  )}
                </>
              )}
            </Box>
            <Button type="submit" variant="pvBlackGray" isLoading={loading}>
              {caption}
            </Button>
          </Box>
        </VStack>
      </Box>
    </form>
  );
}

export default SettingForm;
