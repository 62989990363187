import React from "react";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";

import { Box, Flex, Heading } from "@chakra-ui/react";

function Index() {
  return (
    <>
      <Header />

      <Box p={10} bgColor={"#111111"}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"100%"} alignItems="left">
            <Heading as="h2" size="xl">
              Templates
            </Heading>
            <Heading as="h5" size="sm" mt={3} color={"#888"}>
              Dive into PolyVerse's curated contract templates - ready for
              seamless integration and deployment with just a click.
            </Heading>
          </Box>
        </Flex>
      </Box>

      <Footer />
    </>
  );
}

export default Index;
