"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wallet = void 0;
const apiHelper_1 = require("./utils/apiHelper");
const wallet_1 = require("../wallets/mpc/wallet");
const utils_1 = require("../utils");
const signer_1 = require("../wallets/signer");
const ethers_1 = require("ethers");
class Wallet {
    static balance(network, currency = "usd", includeTokens = true, includeNFTs = true, includePortfolio = true, address, walletType = "cw") {
        return __awaiter(this, void 0, void 0, function* () {
            const url = new URL(`/${this.CONTROLLER_NAME}/${network}/balance`, "http://api.polyverse.life/wallet/fuji/balance");
            url.searchParams.append("currency", currency);
            url.searchParams.append("includeTokens", includeTokens.toString());
            url.searchParams.append("includeNFTs", includeNFTs.toString());
            url.searchParams.append("includePortfolio", includePortfolio.toString());
            if (address) {
                url.searchParams.append("address", address);
            }
            url.searchParams.append("walletType", walletType);
            const response = yield apiHelper_1.ApiHelper.apiGet(url.pathname + url.search);
            return response.data;
        });
    }
    static transfer(network, to, amount, gasLimit, askEstimateGas, walletType = "cw") {
        return __awaiter(this, void 0, void 0, function* () {
            const formdata = new FormData();
            formdata.append("to", to);
            formdata.append("amount", amount.toString());
            if (gasLimit != null) {
                if (network === "mumbai") {
                    gasLimit = Math.ceil(gasLimit * 1.9);
                }
                formdata.append("gasLimit", gasLimit.toString());
            }
            if (askEstimateGas != null) {
                formdata.append("askEstimateGas", askEstimateGas.toString());
            }
            if (walletType === "ncw") {
                const mpcWallet = new wallet_1.Wallet(network);
                yield mpcWallet.connect();
                if (!mpcWallet.wallet) {
                    throw new Error("Wallet is not initialized");
                }
                const value = utils_1.Utils.toWei(amount, "ether");
                const rawTransaction = {
                    type: 2,
                    nonce: 0,
                    to: to,
                    maxPriorityFeePerGas: 0,
                    maxFeePerGas: 0,
                    value: value,
                    gasLimit: 0,
                    chainId: 0,
                };
                //calc gas
                if (askEstimateGas != null && askEstimateGas) {
                    const gasResult = yield signer_1.Signer.estimateGas(network, rawTransaction);
                    return gasResult.data;
                }
                //transfer
                if (gasLimit != null) {
                    rawTransaction.gasLimit = gasLimit;
                }
                const signedTx = yield signer_1.Signer.signTransaction(mpcWallet, rawTransaction);
                formdata.append("rawTransaction", signedTx);
            }
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/Transfer`, formdata);
            return response.data;
        });
    }
    static transferToken(network, contract, to, amount, gasLimit, askEstimateGas, walletType = "cw") {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("to", to);
            formData.append("amount", amount.toString());
            if (gasLimit != null) {
                formData.append("gasLimit", gasLimit.toString());
            }
            if (askEstimateGas != null) {
                formData.append("askEstimateGas", askEstimateGas.toString());
            }
            if (walletType === "ncw") {
                const mpcWallet = new wallet_1.Wallet(network);
                yield mpcWallet.connect();
                if (!mpcWallet.wallet) {
                    throw new Error("Wallet is not initialized");
                }
                const tokenContract = new ethers_1.ethers.Contract(contract, [
                    {
                        constant: true,
                        inputs: [],
                        name: "decimals",
                        outputs: [
                            {
                                name: "",
                                type: "uint8",
                            },
                        ],
                        type: "function",
                    },
                    {
                        constant: false,
                        inputs: [
                            {
                                name: "_to",
                                type: "address",
                            },
                            {
                                name: "_value",
                                type: "uint256",
                            },
                        ],
                        name: "transfer",
                        outputs: [
                            {
                                name: "",
                                type: "bool",
                            },
                        ],
                        type: "function",
                    }
                ], mpcWallet.wallet);
                const decimals = yield tokenContract.decimals();
                const decimalsNumber = Number(decimals);
                const tokenAmount = utils_1.Utils.toWeiWithDecimal(amount, decimalsNumber);
                const data = tokenContract.interface.encodeFunctionData("transfer", [to, tokenAmount]);
                const rawTransaction = {
                    type: 2,
                    nonce: 0,
                    from: mpcWallet.wallet.address,
                    to: contract,
                    maxPriorityFeePerGas: 0,
                    maxFeePerGas: 0,
                    data: data,
                    gasLimit: 0,
                    chainId: 0, // chain ID; change if using another network
                };
                //calc gas
                if (askEstimateGas != null && askEstimateGas) {
                    const gasResult = yield signer_1.Signer.estimateGas(network, rawTransaction);
                    return gasResult.data;
                }
                //transfer
                if (gasLimit != null) {
                    rawTransaction.gasLimit = gasLimit;
                }
                const signedTx = yield signer_1.Signer.signTransaction(mpcWallet, rawTransaction);
                formData.append("rawTransaction", signedTx);
            }
            const response = yield apiHelper_1.ApiHelper.apiPost(`/Wallet/${network}/${contract}/Transfer/Token`, formData);
            return response.data;
        });
    }
    static transferNFT(network, contract, to, tokenId, gasLimit, askEstimateGas, walletType = "cw") {
        return __awaiter(this, void 0, void 0, function* () {
            const formdata = new FormData();
            formdata.append("to", to);
            formdata.append("tokenId", tokenId.toString());
            if (gasLimit != null) {
                formdata.append("gasLimit", gasLimit.toString());
            }
            if (askEstimateGas != null) {
                formdata.append("askEstimateGas", askEstimateGas.toString());
            }
            if (walletType === "ncw") {
                const mpcWallet = new wallet_1.Wallet(network);
                yield mpcWallet.connect();
                if (!mpcWallet.wallet) {
                    throw new Error("Wallet is not initialized");
                }
                // ERC-1155 interface ID
                const ERC1155_INTERFACE_ID = "0xd9b67a26";
                // Create a base contract instance with supportsInterface method
                const baseContract = new ethers_1.ethers.Contract(contract, [
                    {
                        constant: true,
                        inputs: [{ name: "interfaceId", type: "bytes4" }],
                        name: "supportsInterface",
                        outputs: [{ name: "", type: "bool" }],
                        type: "function",
                    },
                ], mpcWallet.wallet);
                // Check if the contract supports the ERC-1155 interface
                const isERC1155 = yield baseContract.supportsInterface(ERC1155_INTERFACE_ID);
                let data;
                if (isERC1155) {
                    // It's an ERC-1155 transfer
                    const contractNFT = new ethers_1.ethers.Contract(contract, [
                        {
                            constant: false,
                            inputs: [
                                { name: "from", type: "address" },
                                { name: "to", type: "address" },
                                { name: "id", type: "uint256" },
                                { name: "amount", type: "uint256" },
                                { name: "data", type: "bytes" },
                            ],
                            name: "safeTransferFrom",
                            outputs: [],
                            type: "function",
                        },
                    ], mpcWallet.wallet);
                    data = contractNFT.interface.encodeFunctionData("safeTransferFrom", [mpcWallet.wallet.address, to, tokenId, "1", "0x"]);
                }
                else {
                    // Assume it's an ERC-721 transfer
                    const contractNFT = new ethers_1.ethers.Contract(contract, [
                        {
                            constant: false,
                            inputs: [
                                { name: "from", type: "address" },
                                { name: "to", type: "address" },
                                { name: "tokenId", type: "uint256" },
                            ],
                            name: "transferFrom",
                            outputs: [],
                            type: "function",
                        },
                    ], mpcWallet.wallet);
                    data = contractNFT.interface.encodeFunctionData("transferFrom", [mpcWallet.wallet.address, to, tokenId]);
                }
                // Prepare and send transaction
                const rawTransaction = {
                    type: 2,
                    nonce: 0,
                    from: mpcWallet.wallet.address,
                    to: contract,
                    maxPriorityFeePerGas: 0,
                    maxFeePerGas: 0,
                    data: data,
                    gasLimit: 0,
                    chainId: 0, // chain ID; change if using another network
                };
                //calc gas
                if (askEstimateGas != null && askEstimateGas) {
                    const gasResult = yield signer_1.Signer.estimateGas(network, rawTransaction);
                    return gasResult.data;
                }
                //transfer
                if (gasLimit != null) {
                    rawTransaction.gasLimit = gasLimit;
                }
                const signedTx = yield signer_1.Signer.signTransaction(mpcWallet, rawTransaction);
                formdata.append("rawTransaction", signedTx);
            }
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/${contract}/Transfer/NFT`, formdata);
            return response.data;
        });
    }
    static fiatBalance(projectId, projectSecret) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/Fiat/Balance`, projectId, projectSecret);
            return response.data;
        });
    }
    static transactions(network, recent = false, walletType = "cw") {
        return __awaiter(this, void 0, void 0, function* () {
            let url = `/${this.CONTROLLER_NAME}/${network}/Transactions?recent=${recent}&walletType=${walletType}`;
            const response = yield apiHelper_1.ApiHelper.apiGet(url);
            return response.data;
        });
    }
    static message(network, to, message, gasLimit, askEstimateGas, walletType = "cw") {
        return __awaiter(this, void 0, void 0, function* () {
            const formdata = new FormData();
            formdata.append("to", to);
            formdata.append("message", message);
            if (gasLimit != null) {
                formdata.append("gasLimit", gasLimit.toString());
            }
            if (askEstimateGas != null) {
                formdata.append("askEstimateGas", askEstimateGas.toString());
            }
            if (walletType === "ncw") {
                const mpcWallet = new wallet_1.Wallet(network);
                yield mpcWallet.connect();
                if (!mpcWallet.wallet) {
                    throw new Error("Wallet is not initialized");
                }
                const hexData = ethers_1.ethers.hexlify(ethers_1.ethers.toUtf8Bytes(message));
                const rawTransaction = {
                    type: 2,
                    nonce: 0,
                    from: mpcWallet.wallet.address,
                    to: to,
                    maxPriorityFeePerGas: 0,
                    maxFeePerGas: 0,
                    data: hexData,
                    gasLimit: 0,
                    chainId: 0,
                };
                //calc gas
                if (askEstimateGas != null && askEstimateGas) {
                    const gasResult = yield signer_1.Signer.estimateGas(network, rawTransaction);
                    return gasResult.data;
                }
                //transfer
                if (gasLimit != null) {
                    rawTransaction.gasLimit = gasLimit;
                }
                const signedTx = yield signer_1.Signer.signTransaction(mpcWallet, rawTransaction);
                formdata.append("rawTransaction", signedTx);
            }
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/${network}/Transfer`, formdata);
            return response.data;
        });
    }
    static history(network, address, recent = false, currency = "usd", walletType = "cw") {
        return __awaiter(this, void 0, void 0, function* () {
            let url = `/${this.CONTROLLER_NAME}/${network}/History?currency=${currency}&walletType=${walletType}&recent=${recent}`;
            if (address && address.trim() !== "") {
                url += `&address=${address}`;
            }
            const response = yield apiHelper_1.ApiHelper.apiGet(url);
            return response.data;
        });
    }
    static saveBackup(projectId, projectSecret, chipperText, backupHint) {
        return __awaiter(this, void 0, void 0, function* () {
            const formdata = new FormData();
            formdata.append("chipperText", chipperText);
            formdata.append("backupHint", backupHint);
            const response = yield apiHelper_1.ApiHelper.apiPost(`/${this.CONTROLLER_NAME}/Backup`, formdata, projectId, projectSecret);
            return response.data;
        });
    }
    static loadBackup(projectId, projectSecret) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield apiHelper_1.ApiHelper.apiGet(`/${this.CONTROLLER_NAME}/BackupLoad`, projectId, projectSecret);
            return response.data;
        });
    }
}
exports.Wallet = Wallet;
Wallet.CONTROLLER_NAME = "Wallet";
