import { System } from "polyverse-sdk/dist/api/system";

export const shortenAddress = (address) => {
  if (address && address.length > 10) {
    const shortAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
    return shortAddress;
  }
  return address;
};

export const getCurrentNetwork = () => {
  const storedCurrentNetwork = localStorage.getItem("currentNetwork");
  return JSON.parse(storedCurrentNetwork);
};

export const getBaseApiUrl = () => {
  return System.getBaseApiUrl();
};

export const getSubdomainOrDomain = (url) => {
  if (url) {
    const parsedUrl = new URL(url);
    const parts = parsedUrl.hostname.split(".");

    return parsedUrl.hostname;

    /*     if (parts.length > 2) {
      return parts[0];
    } else {
      return parsedUrl.hostname;
    } */
  }
  return "";
};

export const getNetwork = (key) => {
  const storedNetworks = localStorage.getItem("networks");
  const networks = JSON.parse(storedNetworks);
  const network = networks.find((network) => network.key === key);
  return network;
};

export const getUserAddress = () => {
  return localStorage.getItem("userAddress").toString();
};

export const formatMenuText = (menu) => {
  return menu
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
