import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { System } from "polyverse-sdk/dist/api/system";
import { Utils } from "polyverse-sdk/dist/utils";

import Header from "../layouts/Header";
import Footer from "../../../layouts/Footer";

import * as Helpers from "../../../services/Helpers";

import { useFormik } from "formik";

import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useMediaQuery,
  Checkbox,
  NumberInput,
  NumberInputField,
  Spinner,
  useToast,
  InputGroup,
  InputRightAddon,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

function ContractDeploy() {
  const navigate = useNavigate();
  const toast = useToast();

  const { projectId } = useParams();
  const { contractId } = useParams();

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  const [isDeployableContractsLoading, setIsDeployableContractsLoading] =
    useState(true);

  const [deployableContract, setIsDeployableContract] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});

  useEffect(() => {
    const fetchDeployableContract = async () => {
      setIsDeployableContractsLoading(true);

      try {
        const result = await System.deployableContract(contractId);
        setIsDeployableContract(result);
      } catch (error) {
        console.error("Failed to fetch Deployable Contracts:", error);
      }

      setIsDeployableContractsLoading(false);
    };

    fetchDeployableContract();
  }, [contractId]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      let updatedValues = { ...values };

      for (const field of deployableContract.config) {
        if (field.display === "ether" && field.convert === "wei") {
          const etherValue = Utils.toWeiWithDecimal(
            values[field.name],
            currentNetwork?.decimals
          );

          updatedValues[field.name] = etherValue;
        }
      }

      try {
        await System.deployContract(currentNetwork.key, updatedValues);
        navigate(`/dashboard/project/${projectId}/contracts`);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  useEffect(() => {
    if (!isDeployableContractsLoading) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        type: deployableContract.type,
        projectId: parseInt(projectId),
      }));
    }

    const network = Helpers.getCurrentNetwork();
    setCurrentNetwork(network);
  }, [deployableContract]);

  return (
    <>
      <Header />
      <Box my={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={isIpad ? "80%" : isIphone ? "96%" : "60%"}>
            {isDeployableContractsLoading && (
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                my={10}
              >
                <Spinner size="xl" color="#753CC5" />
              </Flex>
            )}

            {!isDeployableContractsLoading && (
              <SimpleGrid columns={isIphone ? 1 : 2} spacing={10}>
                <Box
                  bgColor="#111111"
                  border="1px solid #333333"
                  borderRadius={6}
                  p={5}
                >
                  <Heading as="h3" size="lg">
                    Deploy: {deployableContract.name}
                  </Heading>
                  <Text fontSize="sx" color="#888">
                    Your contract will be generated uniquely for you and will be
                    deployed with the parameters you have specified.
                  </Text>
                  <Box mt={10}>
                    <Box maxW="400px" mx="auto">
                      <form onSubmit={formik.handleSubmit}>
                        {deployableContract.config.map((field, index) => (
                          <FormControl key={index} mt={4}>
                            {field.type !== "checkbox" && (
                              <FormLabel htmlFor={field.name}>
                                {field.label}
                              </FormLabel>
                            )}
                            {field.type === "checkbox" ? (
                              <Flex alignItems="center">
                                <Checkbox
                                  id={field.name}
                                  name={field.name}
                                  isChecked={formik.values[field.name]}
                                  onChange={formik.handleChange}
                                >
                                  {field.label}
                                </Checkbox>
                              </Flex>
                            ) : field.type === "number" ? (
                              <>
                                {field.display === "ether" ? (
                                  <InputGroup>
                                    <NumberInput
                                      id={field.name}
                                      name={field.name}
                                      value={formik.values[field.name]}
                                      /*  onChange={(value) =>
                                        formik.setFieldValue(field.name, value)
                                      } */
                                      onChange={(value) => {
                                        /*                                         const etherValue =
                                          Utils.toWeiWithDecimal(
                                            value,
                                            currentNetwork?.decimals
                                          );
                                        formik.setFieldValue(
                                          field.name,
                                          etherValue.toString()
                                        ); */
                                        formik.setFieldValue(field.name, value);
                                      }}
                                      step={0.01}
                                      allowMouseWheel
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                    <InputRightAddon
                                      children={currentNetwork?.symbol}
                                    />
                                  </InputGroup>
                                ) : (
                                  <NumberInput
                                    id={field.name}
                                    name={field.name}
                                    value={formik.values[field.name]}
                                    onChange={(value) =>
                                      formik.setFieldValue(field.name, value)
                                    }
                                  >
                                    <NumberInputField />
                                  </NumberInput>
                                )}
                              </>
                            ) : (
                              <Input
                                id={field.name}
                                name={field.name}
                                type={field.type}
                                maxLength={field.maxlength}
                                required={field.required}
                                value={formik.values[field.name]}
                                onChange={formik.handleChange}
                              />
                            )}
                          </FormControl>
                        ))}
                        <Button
                          mt={4}
                          colorScheme="blue"
                          type="submit"
                          w="100%"
                          variant="pvWhite"
                        >
                          Deploy
                        </Button>
                      </form>
                    </Box>
                  </Box>
                </Box>
                <Box border="1px solid #333333" borderRadius={6} h={224} p={5}>
                  <Heading as="h4" size="md">
                    {deployableContract.name}
                  </Heading>
                  <Text fontSize="sx" color="#888" mt={5}>
                    {deployableContract.description}
                  </Text>
                </Box>
              </SimpleGrid>
            )}
          </Box>
        </Flex>
      </Box>
      <Footer />
    </>
  );
}

export default ContractDeploy;
