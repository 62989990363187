import Logo from "../static/logo-polyverse.png";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import menuData from "../datas/menuData.json";
import DrawerMenu from "./DrawerMenu";

import {
  Box,
  Flex,
  Link,
  Image,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverBody,
  VStack,
  LinkBox,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

function Header(props) {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate("/");
  };

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  return (
    <>
      <Box>
        <Flex
          minH={"60px"}
          pt={{ base: 5, md: 5, lg: 10, "2xl": 20 }}
          px={{ base: 5, md: 10, lg: 10, xl: "28", "2xl": 40 }}
          align={"center"}
        >
          <Flex
            flex={{ base: 3, md: 1 }}
            justify={{ base: "start", md: "start" }}
            align={"center"}
          >
            <Link onClick={handleLinkClick}>
              <Image
                src={Logo}
                h={{ base: "50px", md: "50px", "2xl": "70px" }}
                w={"auto"}
              />
            </Link>

            {/* Desktop flex */}
            <Flex
              display={{ base: "none", md: "flex" }}
              ml={{ md: 10, "2xl": 20 }}
              justifyContent={"flex-end"}
            >
              {Object.keys(menuData).map((category, index) => (
                <Popover trigger="hover" key={index}>
                  <PopoverTrigger>
                    <Button
                      m={2}
                      variant={"ghost"}
                      _hover={{
                        backgroundColor: "#212121",
                      }}
                      fontSize={"lg"}
                      fontWeight={"normal"}
                    >
                      {category}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    background="linear-gradient(180deg, rgba(0, 0, 0, 0.581) 3.45%, rgba(0, 0, 0, 0.371) 100%)"
                    border="1px solid #333333"
                    borderRadius="11px"
                    transitionDuration=".2s"
                    transitionTimingFunction="ease"
                    backdropFilter="blur(35px)"
                  >
                    <PopoverBody>
                      <VStack align="start">
                        {menuData[category].map((item, index) => (
                          <LinkBox
                            as={RouterLink}
                            to={item.path}
                            maxW="sm"
                            _hover={{ backgroundColor: "#121212" }}
                            p="3"
                            rounded="md"
                            key={index}
                          >
                            <Flex align="center">
                              {item.icon && (
                                <Image
                                  src={item.icon}
                                  boxSize={6}
                                  filter="invert(100%)"
                                />
                              )}
                              <Text fontWeight="extrabold" ml={2}>
                                {item.name}
                              </Text>
                            </Flex>
                            <Text fontSize={"sm"} mt={2}>
                              {item.text}
                            </Text>
                          </LinkBox>
                        ))}
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ))}
            </Flex>
          </Flex>

          {/* Mobile flex */}
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            justify={{ base: "end", md: "start" }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              ml={"auto"}
              mr={2}
              onClick={onDrawerOpen}
              _hover={{
                backgroundColor: "transparent",
              }}
              icon={
                isDrawerOpen ? (
                  <CloseIcon w={5} h={5} color={"white"} />
                ) : (
                  <HamburgerIcon w={7} h={7} color={"white"} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
              zIndex={11}
            />
          </Flex>
        </Flex>
      </Box>
      <DrawerMenu
        isOpen={isDrawerOpen}
        onOpen={onDrawerOpen}
        onClose={onDrawerClose}
      />
    </>
  );
}

export default Header;
