import React, { useEffect, useState } from "react";
import {
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useMediaQuery,
  List,
  ListItem,
  Link,
  Text,
  Divider,
  Box,
  useToast,
  Flex,
  Spinner,
} from "@chakra-ui/react";

import { System } from "polyverse-sdk/dist/api/system";

import { PvBoxSub } from "../../../../custom/components";

import FormGenerator from "./components/ExplorerFormGenerator";

function Explorer({ contract, project }) {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [abiNonPayable, setAbiNonPayable] = useState([]);
  const [abiPayable, setAbiPayable] = useState([]);

  const [methodName, setMethodName] = useState("");
  const [method, setMethod] = useState(null);

  const [isIpad] = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const [isIphone] = useMediaQuery("(max-width: 480px)");

  useEffect(() => {
    const fetchABI = async () => {
      setIsLoading(true);

      try {
        const abi = await System.abi(contract?.network, contract?.contract);

        const nonPayableMethods = abi.filter(
          (method) =>
            method.stateMutability !== "payable" &&
            method.stateMutability !== "nonpayable" &&
            method.type === "function"
        );
        setAbiNonPayable(nonPayableMethods);
        const payableMethods = abi.filter(
          (method) =>
            (method.stateMutability === "payable" ||
              method.stateMutability === "nonpayable") &&
            method.type === "function"
        );
        setAbiPayable(payableMethods);
      } catch (error) {
        toast({
          title: "Error",
          description: "Error fetching ABI",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }

      setIsLoading(false);
    };

    fetchABI();
  }, [contract?.network]);

  useEffect(() => {
    if (abiNonPayable.length > 0) {
      handleMethodClick("read", abiNonPayable[0]);
    }
  }, [abiNonPayable]);

  const handleMethodClick = (type, method) => {
    setMethodName(method.name);
    setMethod(method);
  };

  return (
    <>
      {isLoading && (
        <Flex w="100%" justifyContent="center" alignItems="center" my={10}>
          <Spinner size="xl" color="#753CC5" />
        </Flex>
      )}

      {!isLoading && (
        <SimpleGrid
          columns={2}
          spacing={0}
          w="100%"
          mt={5}
          templateColumns={isIphone ? "100%" : "30% 70%"}
        >
          <PvBoxSub p={0} w={isIphone ? "100%" : "98%"}>
            <Tabs colorScheme="gray" w="100%">
              <TabList>
                <Tab w="50%">Read</Tab>
                <Tab w="50%">Write</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <List variant={"pvMethodList"}>
                    {abiNonPayable.map((method, index) => (
                      <ListItem key={index}>
                        <Link
                          onClick={() => handleMethodClick("read", method)}
                          color={
                            method.name === methodName ? "#fff" : undefined
                          }
                        >
                          {method.name}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </TabPanel>
                <TabPanel>
                  <List variant={"pvMethodList"}>
                    {abiPayable.map((method, index) => (
                      <ListItem key={index}>
                        <Link
                          onClick={() => handleMethodClick("read", method)}
                          color={
                            method.name === methodName ? "#fff" : undefined
                          }
                        >
                          {method.name}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PvBoxSub>
          <PvBoxSub mt={isIphone ? 4 : 0} py={2} px={2}>
            <Box pb={2} display="flex" justifyContent="space-between">
              <Text fontWeight="bold">{methodName}</Text>
              <Text>({method?.stateMutability})</Text>
            </Box>
            <Divider />
            <Box mt={2}>
              <FormGenerator
                jsonSchema={method}
                onSubmit={(values) => console.log(values)}
                contract={contract}
              />
            </Box>
          </PvBoxSub>
        </SimpleGrid>
      )}
    </>
  );
}

export default Explorer;
