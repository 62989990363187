import React, { useEffect, useState } from "react";

import {
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Alert,
  AlertIcon,
  Skeleton,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";

import { PvBox } from "../../../../custom/components";

function Analytics({ contract, project }) {
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(true);

  const [transactions, setTransactions] = useState([]);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);

  const [countUqiueWallets, setCountUniqueWallets] = useState(0);

  const fetchEvents = async () => {
    try {
      setIsEventsLoading(true);

      const response = await Contract.events(
        contract.network,
        contract.contract
      );

      setEvents(response);

      setIsEventsLoading(false);
    } catch (error) {
      setTimeout(() => fetchEvents(), 3000);
    }
  };

  const fetchTransactions = async () => {
    try {
      setIsTransactionsLoading(true);

      const response = await Contract.transactions(
        contract.network,
        contract.contract
      );

      setTransactions(response);

      setIsTransactionsLoading(false);
    } catch (error) {
      setTimeout(() => fetchTransactions(), 3000);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchTransactions();
  }, [contract, project]);

  useEffect(() => {
    const uniqueToAddresses = new Set();
    
    transactions.forEach((item) => {
      if (item.from_address !== contract.contract) {
        uniqueToAddresses.add(item.from_address);
      }
    });

    setCountUniqueWallets(uniqueToAddresses.size);
  }, [transactions, contract.contract]);

  return (
    <PvBox>
      <StatGroup>
        <Stat>
          <StatLabel>Unique Wallets</StatLabel>
          <StatNumber>
            {isTransactionsLoading ? (
              <Skeleton height="35px" width="80px" />
            ) : (
              countUqiueWallets
            )}
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Transactions</StatLabel>
          <StatNumber>
            {isTransactionsLoading ? (
              <Skeleton height="35px" width="80px" />
            ) : (
              transactions.length
            )}
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Events</StatLabel>
          <StatNumber>
            {isEventsLoading ? (
              <Skeleton height="35px" width="80px" />
            ) : (
              events.length
            )}
          </StatNumber>
        </Stat>
      </StatGroup>

      <Alert status="info" mt={10}>
        <AlertIcon />
        More analytics coming soon!
      </Alert>
    </PvBox>
  );
}

export default Analytics;
