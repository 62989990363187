import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { NetworkBoxProvider } from "../../../contexts/NetworkBoxContext";

import Logo from "../../../static/logo.png";
import { SearchIcon } from "@chakra-ui/icons";

import NetworkBox from "../components/NetworkBox";
import AuthCheck from "../components/AuthCheck";

import {
  Box,
  Image,
  Container,
  Flex,
  ButtonGroup,
  Button,
  InputGroup,
  Input,
  InputRightElement,
  useMediaQuery,
} from "@chakra-ui/react";

function Header() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [isSmallerThan820] = useMediaQuery("(max-width: 820px)");

  const [rotate, setRotate] = useState(false);
  const location = useLocation();

  const handleMouseEnter = () => {
    setRotate(true);
  };

  const handleMouseLeave = () => {
    setRotate(false);
  };

  return (
    <>
      <AuthCheck />
      <Container
        maxW="100%"
        bgColor="#000"
        pt={5}
        pl={7}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          w="43px"
          transition="transform 1.5s ease-in-out"
          transform={rotate ? "rotate(360deg)" : ""}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          cursor="pointer"
          mx={isSmallerThan480 ? 0 : "auto"}
        >
          <Image src={Logo} />
        </Box>

        {isSmallerThan480 && (
          <NetworkBoxProvider>
            <NetworkBox />
          </NetworkBoxProvider>
        )}
      </Container>

      <Container
        maxW="100%"
        bgColor="#000"
        p={3}
        borderBottom="1px solid #575757"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center" overflowX="auto">
          <ButtonGroup variant="pvMenu">
            <Button
              as={RouterLink}
              to="/dashboard/home"
              bgColor={location.pathname === "/dashboard/home" ? "#333333" : ""}
            >
              Home
            </Button>
            <Button
              as={RouterLink}
              to="/dashboard/projects"
              bgColor={
                location.pathname === "/dashboard/projects" ? "#333333" : ""
              }
            >
              Projects
            </Button>
            <Button
              as={RouterLink}
              to="/dashboard/explore"
              bgColor={
                location.pathname === "/dashboard/explore" ? "#333333" : ""
              }
            >
              Explore
            </Button>
            <Button
              as={RouterLink}
              to="/dashboard/templates"
              bgColor={
                location.pathname === "/dashboard/templates" ? "#333333" : ""
              }
            >
              Templates
            </Button>
          </ButtonGroup>
        </Flex>

        <Flex alignItems="center">
          {!isSmallerThan820 && (
            <Box mr={5}>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="How we can help you?"
                  bgColor="transparent"
                  border="1px solid #323235"
                />
                <InputRightElement>
                  <SearchIcon />
                </InputRightElement>
              </InputGroup>
            </Box>
          )}

          {!isSmallerThan480 && (
            <NetworkBoxProvider>
                <NetworkBox />
            </NetworkBoxProvider>
          )}
        </Flex>
      </Container>
    </>
  );
}

export default Header;
