import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";

function OurExceptionalServices() {
  return (
    <>
      <Box>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          mt={10}
        >
          <Box borderTopColor={"#333333"} borderTopWidth="1px" w={"80%"} p={10}>
            <Flex direction={{ base: "column", md: "row" }}>
              <Box flex={2} mr={6} textAlign="left">
                <Heading as="h3" size="lg" mb={4}>
                  Our Exceptional Services
                </Heading>
                <Text fontSize={"md"} color={"#888888"}>
                  From providing a versatile dashboard to offering easy-to-use
                  API services, SDKs, UI components, and fast decentralized
                  storage options, our services are designed to empower and
                  facilitate your Web3 journey. Discover how we can help you
                  elevate your projects.
                </Text>
              </Box>
              <Box flex={1} mt={{ base: 4, md: 0 }}>
                <Button
                  as={"a"}
                  href="https://docs.polyverse.life/"
                  target="_blank"
                  variant={"pvWhite"}
                  mb={10}
                  w="full"
                >
                  Learn More
                </Button>
                <Button
                  colorScheme="green"
                  w="full"
                  variant={"pvOrangeGr"}
                  to={"/dashboard/login"}
                  as={RouterLink}
                >
                  Get Started
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default OurExceptionalServices;
