import React, { useEffect, useState } from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  useToast,
  Skeleton,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";
import { Utils } from "polyverse-sdk/dist/utils";
import { useTokenOverview } from "../../../../../contexts/TokenOverviewContext";
import * as Helpers from "../../../../../services/Helpers";
import { PvBox } from "../../../../../custom/components";

function TokenOverview({ contract, project }) {
  const { isRefreshTokenOverview, refreshTokenOverview } = useTokenOverview();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [tokenData, setTokenData] = useState({
    symbol: "",
    totalSupply: 0,
    decimals: 0,
    ownedByYou: 0,
  });

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const symbolResponse = await Contract.execute(
        contract.network,
        contract.contract,
        "symbol"
      );
      const decimalsResponse = await Contract.execute(
        contract.network,
        contract.contract,
        "decimals"
      );
      const totalSupplyResponse = await Contract.execute(
        contract.network,
        contract.contract,
        "totalSupply"
      );
      const ownedByYou = await Contract.execute(
        contract.network,
        contract.contract,
        "balanceOf",
        {
          account: Helpers.getUserAddress(),
        }
      );

      setTokenData({
        symbol: symbolResponse.data,
        totalSupply: Utils.fromWeiWithDecimal(
          totalSupplyResponse.data.toLocaleString("fullwide", {
            useGrouping: false,
          }),
          decimalsResponse.data
        ),
        decimals: decimalsResponse.data,
        ownedByYou: Utils.fromWeiWithDecimal(
          ownedByYou.data.toLocaleString("fullwide", {
            useGrouping: false,
          }),
          decimalsResponse.data
        ),
      });
      
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [contract.contract]);

  useEffect(() => {
    fetchData();
  }, [isRefreshTokenOverview]);

  return (
    <>
      <PvBox>
        <StatGroup>
          <Stat>
            <StatLabel>Symbol</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                tokenData.symbol
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Total Supply</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                parseFloat(tokenData.totalSupply).toFixed(2)
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Owned by you</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                parseFloat(tokenData.ownedByYou).toFixed(2)
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Decimals</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                tokenData.decimals
              )}
            </StatNumber>
          </Stat>
        </StatGroup>
      </PvBox>
    </>
  );
}

export default TokenOverview;
