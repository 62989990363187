import React, { useEffect, useState } from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  useToast,
  Skeleton,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";
import { PvBox } from "../../../../../custom/components";

function ProductStoreOverview({ contract, project }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [storeData, setStoreData] = useState({
    purchasedProducts: [],
    products: [],
  });

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const products = await Contract.execute(
        contract.network,
        contract.contract,
        "getProducts"
      );

      const purchasedProducts = await Contract.execute(
        contract.network,
        contract.contract,
        "getPurchasedProducts"
      );

      setStoreData({
        products: products.data,
        purchasedProducts: purchasedProducts.data,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [contract.contract]);

  return (
    <>
      <PvBox>
        <StatGroup>
          <Stat>
            <StatLabel>Total Products</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                storeData.products.length
              )}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Total Sold</StatLabel>
            <StatNumber>
              {isLoading ? (
                <Skeleton height="35px" width="80px" />
              ) : (
                storeData.purchasedProducts.length
              )}
            </StatNumber>
          </Stat>
        </StatGroup>
      </PvBox>
    </>
  );
}

export default ProductStoreOverview;
