import React, { useEffect, useState } from "react";
import { useFormik } from "formik"; // Formik eklenmiştir

import {
  Input,
  Button,
  Box,
  Text,
  useToast,
  Divider,
  VStack,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";

function MintPhase(props) {

  const convertDatetimeToUnixTimestamp = (time) => {
    if (time !== undefined && time !== 0) {
      return new Date(time * 1000).toISOString().substring(0, 16);
    }
    return "";
  };

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const [isBoxVisible, setIsBoxVisible] = useState(true);

  const formik = useFormik({
    initialValues: {
      startTime: convertDatetimeToUnixTimestamp(props.item[0]),
      endTime: convertDatetimeToUnixTimestamp(props.item[1]),
      maxMintsPerWallet: props.item[2],
      maxMints: props.item[3],
      option:
        props.item[4] !== undefined ? props.item[4] : props.item["option"],
      index: props.item["index"],
      saved: props.item["saved"],
    },
    onSubmit: async (values) => {
      let saved = values["saved"];

      // Convert startTime and endTime to Unix timestamps
      const startTimeDatetime = new Date(values.startTime);
      const endTimeDatetime = new Date(values.endTime);
      const startTimeUnixTimestamp = startTimeDatetime.getTime() / 1000;
      const endTimeUnixTimestamp = endTimeDatetime.getTime() / 1000;

      if (saved === 0) {
        setIsLoading(true);

        try {
          await Contract.execute(
            props.contract.network,
            props.contract.contract,
            "addMintPhase",
            {
              startTime: startTimeUnixTimestamp,
              endTime: endTimeUnixTimestamp,
              maxMintsPerWallet: values["maxMintsPerWallet"],
              maxMints: values["maxMints"],
              option: values["option"],
            }
          );

          toast({
            title: "Success",
            description: "Mint Phase added successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        setIsLoading(false);

        props.getMintPhases();
      }

      if (saved === 1) {
        setIsLoading(true);

        try {
          await Contract.execute(
            props.contract.network,
            props.contract.contract,
            "editMintPhase",
            {
              phaseIndex: values["index"],
              newStartTime: startTimeUnixTimestamp,
              newEndTime: endTimeUnixTimestamp,
              newMaxMintsPerWallet: values["maxMintsPerWallet"],
              newMaxMints: values["maxMints"],
              newOption: values["option"],
            }
          );

          toast({
            title: "Success",
            description: "Mint Phase updated successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        setIsLoading(false);
      }
    },
  });

  const handleRemovePhase = async (index) => {
    setIsLoadingRemove(true);
    try {
      await Contract.execute(
        props.contract.network,
        props.contract.contract,
        "removeMintPhase",
        {
          phaseIndex: index,
        }
      );

      toast({
        title: "Success",
        description: "Mint Phase removed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      setIsLoadingRemove(false);
    }

    setIsLoadingRemove(false);
    setIsBoxVisible(false);

    props.getMintPhases();
  };

  const handleCancelPhase = async (index) => {
    props.removeMintPhase(index);

    toast({
      title: "Success",
      description: "Mint Phase removed successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    setIsBoxVisible(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        w={"100%"}
        border={"1px solid #333"}
        borderRadius={6}
        bgColor={"#0a0a0a"}
        mt={5}
        display={isBoxVisible ? "block" : "none"}
      >
        <VStack spacing={4}>
          <Box w={"100%"} p={5}>
            <Box mb={5} w={"100%"}>
              <Text fontSize="lg" fontWeight={"bold"}>
                {props.item.option === 0
                  ? "Public"
                  : props.item.option === 1
                  ? "Allowlist Only"
                  : "Owner Only"}{" "}
                Phase
              </Text>
            </Box>

            <Box mb={5} w={"100%"}>
              <Text fontWeight="bold">Phase Start</Text>
              <Text my={2} fontSize={"sm"}>
                When will this phase start?
              </Text>
              <Input
                type="datetime-local"
                id="startTime"
                size="sm"
                required
                value={formik.values.startTime}
                {...formik.getFieldProps("startTime")}
              />
            </Box>
            <Box mb={5} w={"100%"}>
              <Text fontWeight="bold">Phase End</Text>
              <Text my={2} fontSize={"sm"}>
                When will this phase end?
              </Text>
              <Input
                type="datetime-local"
                id="endTime"
                size="sm"
                required
                value={formik.values.endTime}
                {...formik.getFieldProps("endTime")}
              />
            </Box>
            <Box mb={5} w={"100%"}>
              <Text fontWeight="bold">Max Mints Per Wallet</Text>
              <Text my={2} fontSize={"sm"}>
                How many NFTs can be minted per wallet?
              </Text>
              <Input
                type="number"
                id="maxMintsPerWallet"
                size="sm"
                required
                value={formik.values.maxMintsPerWallet}
                {...formik.getFieldProps("maxMintsPerWallet")}
              />
            </Box>
            <Box mb={5} w={"100%"}>
              <Text fontWeight="bold">Max Mints</Text>
              <Text my={2} fontSize={"sm"}>
                How many NFTs will you drop in this phase?
              </Text>
              <Input
                type="number"
                id="maxMints"
                size="sm"
                required
                value={formik.values.maxMints}
                {...formik.getFieldProps("maxMints")}
              />
            </Box>
            <Input
              type="hidden"
              id="option"
              size="sm"
              required
              {...formik.getFieldProps("option")}
              value={formik.values.option}
            />
            <Input
              type="hidden"
              id="index"
              size="sm"
              required
              {...formik.getFieldProps("index")}
              value={formik.values.index}
            />
            <Input
              type="hidden"
              id="saved"
              size="sm"
              required
              {...formik.getFieldProps("saved")}
              value={formik.values.saved}
            />
          </Box>
          <Divider />
          <Box
            pb={4}
            px={5}
            w={"100%"}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="pvBlackGray"
              isLoading={isLoadingRemove}
              flexShrink={0}
              onClick={() =>
                props.item.saved === 1
                  ? handleRemovePhase(props.item.index)
                  : handleCancelPhase(props.item.index)
              }
            >
              Remove
            </Button>
            <Button
              variant="pvBlackGray"
              isLoading={isLoading}
              flexShrink={0}
              type="submit"
            >
              {formik.values.saved === 0 ? "Add" : "Update"}
            </Button>
          </Box>
        </VStack>
      </Box>
    </form>
  );
}

export default MintPhase;
