import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link as RouterLink } from "react-router-dom";
import { Box, Text, Heading, Button, HStack } from "@chakra-ui/react";

function Index() {
  return (
    <>
      <Header />

      <Box
        py={75}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        w={"60%"}
        mx="auto"
      >
        <Heading as="h3" size="lg">
          Coming Soon,
        </Heading>

        <Text mt={5}>Content is being prepared, please check again later.</Text>

        <HStack spacing={4}>
          <Button
            mt={5}
            colorScheme="teal"
            variant="pvWhite"
            onClick={() => window.history.back()}
          >
            Back
          </Button>

          <Button
            mt={5}
            colorScheme="teal"
            variant="pvWhite"
            as={RouterLink}
            to={"/"}
          >
            Home
          </Button>
        </HStack>
      </Box>

      <Footer />
    </>
  );
}

export default Index;
