import React, { useEffect, useState } from "react";
import { Skeleton, useClipboard } from "@chakra-ui/react";

import { useWalletBalance } from "../../../../contexts/WalletBalanceContext";
import { useContracts } from "../../../../contexts/ContractsContext";

import { FaRegCopy, FaCopy } from "react-icons/fa";
import { GoStack, GoSync } from "react-icons/go";

import * as Helpers from "../../../../services/Helpers";

import { Button, Tooltip } from "@chakra-ui/react";

import { System } from "polyverse-sdk/dist/api/system";

function ContractsAddressWithDeploy({ address, contractId }) {
  const { isRefreshWalletBalance, refreshWallet } = useWalletBalance();
  const { isRefreshContractList, refreshContracts } = useContracts();

  const [addressShort, setAddressShort] = useState("");
  const [isCheckingDeployStatus, setIsCheckingDeployStatus] = useState(false);
  const { hasCopied, onCopy } = useClipboard(address);

  useEffect(() => {
    deployStatus(contractId);
  }, [address]);

  const deployStatus = async (jobId) => {
    setIsCheckingDeployStatus(true);

    if (!address) {
      setAddressShort("Checking...");

      const intervalId = setInterval(async () => {
        try {
          const response = await System.deployStatus(parseInt(jobId));

          setAddressShort(response.job.status + "...");

          if (response.job.status === "Failed") {
            setAddressShort("Failed");
            clearInterval(intervalId);

            refreshWallet();
          } else if (response.job.status === "Deployed") {
            setAddressShort(Helpers.shortenAddress(response.contract));
            clearInterval(intervalId);

            refreshContracts();
            refreshWallet();
          }
        } catch (error) {}
      }, 2000);
    } else {
      setAddressShort(Helpers.shortenAddress(address));
    }

    setIsCheckingDeployStatus(false);
  };

  const reDeploy = async (jobId) => {
    try {
      await System.reDeploy(parseInt(jobId));
      deployStatus(contractId);
    } catch (error) {}
  };

  return (
    <Skeleton isLoaded={!(addressShort === "Checking...")} maxW={157}>
      <Tooltip
        label={
          hasCopied
            ? "Copied!"
            : addressShort === "Failed"
            ? "Re-try"
            : addressShort?.indexOf("0x") === -1
            ? "Waiting for deployment"
            : "Copy address to clipboard"
        }
        isDisabled={isCheckingDeployStatus}
      >
        <Button
          variant="pvDarkGray"
          fontSize="sm"
          isDisabled={isCheckingDeployStatus}
          leftIcon={
            hasCopied ? (
              <FaCopy />
            ) : addressShort === "Failed" ? (
              <GoSync />
            ) : addressShort?.indexOf("0x") === -1 ? (
              <GoStack />
            ) : (
              <FaRegCopy />
            )
          }
          onClick={
            addressShort !== "Failed" ? onCopy : () => reDeploy(contractId)
          }
          minW={157}
          disabled={isCheckingDeployStatus}
        >
          {addressShort === "Failed" ? <>Re-try</> : addressShort}
        </Button>
      </Tooltip>
    </Skeleton>
  );
}

export default ContractsAddressWithDeploy;
