import React from "react";
import { FaCopy, FaRegCopy } from "react-icons/fa";
import { Button, useClipboard, Tooltip } from "@chakra-ui/react";
import * as Helpers from "../../../services/Helpers";

function CopyAddressButton({ address }) {
  const { hasCopied: copiedAddress, onCopy: copyAddress } =
    useClipboard(address);

  return (
    <Tooltip label={copiedAddress ? "Copied" : "Copy"}>
      <Button
        as="span"
        leftIcon={copiedAddress ? <FaCopy /> : <FaRegCopy />}
        variant="pvBlack"
        onClick={copyAddress}
        minW={160}
        cursor={"pointer"}
        fontSize={"sm"}
      >
        {Helpers.shortenAddress(address)}
      </Button>
    </Tooltip>
  );
}

export default CopyAddressButton;
