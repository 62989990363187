import React from "react";

import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
} from "@chakra-ui/react";

import CopyAddressButton from "../../components/CopyAddressButton";

import { FaExternalLinkAlt } from "react-icons/fa";

function FilesList({ files, project }) {
  return (
    <Box>
      {files.length > 0 ? (
        <Box overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
          <Table variant="unstyled" p={0} borderRadius={6}>
            <Thead bg="black" h={14}>
              <Tr>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  Hash
                </Th>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  Name
                </Th>
                <Th
                  borderTopLeftRadius={6}
                  px={4}
                  py={2}
                  color="#888"
                  fontWeight="bold"
                >
                  Size
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {files.map((file, index) => (
                <Tr key={index}>
                  <Td maxW={110}>
                    <CopyAddressButton address={file.hash} />
                  </Td>
                  <Td
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    maxW={100}
                  >
                    {file.name}
                  </Td>
                  <Td>{file.size}</Td>
                  <Td>
                    <IconButton
                      variant="outline"
                      fontSize="sm"
                      icon={<FaExternalLinkAlt />}
                      onClick={() => {
                        window.open(
                          `https://api.polyverse.life/storage/IPFS/${file.hash}`,
                          "_blank"
                        );
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Box textAlign="center" p={4}>
          <Text>
            You haven't uploaded any files yet. <br />
            You can upload files to IPFS specific to your project. 📁
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default FilesList;
