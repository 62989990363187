import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Heading,
  useMediaQuery,
  HStack,
  Button,
  Link,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import { GoFileBinary, GoFileCode, GoFileSymlinkFile } from "react-icons/go";

import ProjectControlMenu from "../../components/ProjectControlMenu";
import ProjectActionButtons from "../../components/ProjectActionButtons";
import ImportContract from "./ImportContractModal";

function ProjectHeader({ project, projectId, isLoading }) {
  const location = useLocation();
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");

  const [isContractImportModalOpen, setIsContractImportModalOpen] =
    useState(false);

  const onCloseContractImportModalOpen = () => {
    setIsContractImportModalOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isSmallerThan480 ? "center" : "space-between"}
        flexWrap="wrap"
        w={isSmallerThan480 ? "100%" : "90%"}
        mx="auto"
      >
        {isLoading ? (
          <Box p={3} flexGrow={1} maxW={380}>
            <Box display="flex" alignItems="center">
              <Skeleton>
                <Heading as="h2" size="lg" mr={2}>
                  aaa bbb
                </Heading>
              </Skeleton>
              <Skeleton height={31} ml={2}>
                aaa
              </Skeleton>
            </Box>
            <Box mt={2}>
              <Skeleton>
                <Text fontSize="xs">aaa bbb</Text>
              </Skeleton>
            </Box>
            <Skeleton>
              <Heading as="h5" size="sm" noOfLines={2} mt={4}>
                aaa bbb
              </Heading>
            </Skeleton>
          </Box>
        ) : (
          <Box p={3} flexGrow={1} maxW={380}>
            <Box display="flex" alignItems="center">
              <Heading as="h2" size="lg" mr={2}>
                {project?.name}
              </Heading>
              {project && <ProjectControlMenu project={project} />}
            </Box>
            <Box mt={2}>
              <ProjectActionButtons project={project} />
            </Box>
            <Heading as="h5" size="sm" noOfLines={2} mt={4}>
              {project?.desc}
            </Heading>
          </Box>
        )}

        <HStack
          overflowX="auto"
          overflowY="hidden"
          whiteSpace="nowrap"
          flexGrow={isSmallerThan480 ? 0 : 1}
          justifyContent={isSmallerThan480 ? "center" : "flex-end"}
        >
          <Button
            variant="pvWhite"
            leftIcon={!isSmallerThan480 && <GoFileBinary />}
            as={RouterLink}
            to={`/dashboard/explore/${projectId}`}
          >
            Deploy {!isSmallerThan480 && "Contract"}
          </Button>
          <Button
            variant="pvBlack"
            leftIcon={!isSmallerThan480 && <GoFileCode />}
            as={RouterLink}
            to={`/dashboard/project/${projectId}/customcontract`}
          >
            Custom {!isSmallerThan480 && "Contract"}
          </Button>
          <Button
            variant="pvBlack"
            leftIcon={!isSmallerThan480 && <GoFileSymlinkFile />}
            onClick={() => setIsContractImportModalOpen(true)}
          >
            Import {!isSmallerThan480 && "Contract"}
          </Button>
        </HStack>
      </Box>
      <ImportContract
        isOpen={isContractImportModalOpen}
        onClose={onCloseContractImportModalOpen}
        project={project}
      />
    </>
  );
}

export default ProjectHeader;
