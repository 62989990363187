import React, { useEffect, useState } from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Skeleton,
} from "@chakra-ui/react";

import { Contract } from "polyverse-sdk/dist/api/contract";

import { PvBox } from "../../../../../custom/components";

function GeneralOverview({ contract, project, deployableContract }) {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);

    const results = await Promise.all(
      deployableContract.overViewFunctions.map(async (item) => {
        try {
          const response = await Contract.execute(
            contract.network,
            contract.contract,
            item.methodName
          );
          return { label: item.label, data: response.data };
        } catch (error) {
          console.error(error);
          return { label: item.label, error };
        }
      })
    );

    setData(results);

    setIsLoading(false);
  };

  useEffect(() => {
    if (deployableContract !== null) {
      fetchData();
    }
  }, [deployableContract]);

  return (
    <>
      <PvBox>
        <StatGroup>
          {data.map((item, index) => (
            <Stat key={index}>
              <StatLabel>{item.label}</StatLabel>
              <StatNumber>
                {isLoading ? (
                  <Skeleton height="35px" width="80px" />
                ) : (
                  item.data
                )}
              </StatNumber>
            </Stat>
          ))}

          {contract?.config?.type === "Imported" && (
            <Stat>
              <StatLabel>Name</StatLabel>
              <StatNumber>{contract?.config?.name}</StatNumber>
            </Stat>
          )}
        </StatGroup>
      </PvBox>
    </>
  );
}

export default GeneralOverview;
