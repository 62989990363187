import React, { useState } from "react";

import menuData from "../../datas/menuData.json";
import solutionsData from "../../datas/solutionsData.json";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import OurExceptionalServices from "../../layouts/OurExceptionalServices";

import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

function Index() {
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  return (
    <>
      <Header />

      <Box mt={10}>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box w={"80%"}>
            <Flex flexDirection="column" alignItems="center">
              <Heading
                as="h2"
                size="3xl"
                noOfLines={1}
                bgGradient="linear(to-b, white, #888888)"
                bgClip="text"
              >
                {menuData["Solutions"][2].name}
              </Heading>

              <Heading as="h4" size="md" color={"#888888"} my={5}>
                {menuData["Solutions"][2].text}
              </Heading>

              <Box bg="#753CC5" p={2} borderRadius="full" boxSize={50}>
                <Image src={menuData["Solutions"][2].icon} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box
        mt={10}
        bgGradient="linear(to-b, #222222, #000000)"
        textAlign="center"
      >
        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 3}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
          mt={10}
        >
          {solutionsData[0].Minting.features1.map((feature, index) => (
            <Box
              key={index}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              color={"white"}
            >
              <Heading as="h4" size="md">
                {feature.title}
              </Heading>
              <Text mt={2} fontSize={"md"}>
                {feature.text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>

        <Box textAlign={"center"}>
          <Box>
            <Heading as="h3" size="lg">
              Effortlessly Mint and Disseminate NFTs
            </Heading>
            <Text mt={2} fontSize={"md"} color={"#888888"}>
              Empower your users to create, launch, and distribute NFTs directly
              from your application with minimal coding required.
            </Text>
          </Box>

          <SimpleGrid
            columns={isSmallerThan480 ? 1 : 3}
            spacing={10}
            px={{ base: 4, md: 8 }}
            py={{ base: 4, md: 8 }}
            w={isSmallerThan480 ? "100%" : "60%"}
            mx="auto"
          >
            <Box textAlign={"left"}>
              <Heading as="h4" size="md">
                Amplifying Chains & Platforms
              </Heading>
              <Text mt={2} fontSize={"md"} color={"#888888"}>
                Revitalize the onboarding process for brands, applications, and
                developers onto your chain. Our NFT Launchpad simplifies the
                incorporation of new smart contracts and minting processes via
                an API call, enhancing ease-of-use.
              </Text>
            </Box>
            <Box textAlign={"left"}>
              <Heading as="h4" size="md">
                Empowering Creators
              </Heading>
              <Text mt={2} fontSize={"md"} color={"#888888"}>
                Furnish your users with the ability to mint NFTs, all while
                maintaining a user experience similar to Web2. Engage users with
                quests, offering digital collectible rewards. Harness music &
                video digital collectibles with a royalty sharing mechanism, and
                create access-based digital collectibles for real-world
                ticketing & events.
              </Text>
            </Box>
            <Box textAlign={"left"}>
              <Heading as="h4" size="md">
                Game-Changing Solutions
              </Heading>
              <Text mt={2} fontSize={"md"} color={"#888888"}>
                Seamlessly link game engines with our API to minting and
                blockchain infrastructure. Develop in-game asset digital
                collectibles and digital trading cards. Create white-label
                marketplaces for in-asset game trading with ease.
              </Text>
            </Box>
          </SimpleGrid>
        </Box>

        <SimpleGrid
          columns={isSmallerThan480 ? 1 : 3}
          spacing={10}
          px={{ base: 4, md: 8 }}
          py={{ base: 4, md: 8 }}
        >
          {solutionsData[0].Minting.features2.map((feature, index) => (
            <Box
              key={index}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderColor={"#333333"}
              borderRadius={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              color={"white"}
              boxShadow="0px 2px 6px rgba(255, 255, 255, 0.2)"
            >
              <Heading as="h4" size="md">
                {feature.title}
              </Heading>
              <Text mt={2} fontSize={"md"}>
                {feature.text}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>

      <OurExceptionalServices />

      <Footer />
    </>
  );
}

export default Index;
